import clsx from 'clsx';
import React, { useMemo } from 'react';
import { LinkProps } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import Bell from '@assets/bell.svg';
import Avatar from '@assets/default-avatar.png';
import Logo from '@assets/logo.svg';
import Link from '@components/Link';
import links from '@routes/links';
import { UserRole, UserRoleLabel } from '@typings/enums';

import Drawer from './Drawer';
import defaultLinks from './links';
import styles from './styles.module.scss';

interface Props {
  links?: LinkProps[];
  notificationCount?: number;
  user: WebUser;
  logout(): void;
}

const AuthedHeader: React.FC<Props> = ({
  links: headerLinks = defaultLinks,
  notificationCount,
  user,
  logout,
}) => {
  const { firstName, lastName, roles } = user;
  const avatar = user.avatar || Avatar;

  const userRole = UserRoleLabel[roles[0]] || UserRoleLabel[UserRole.user];
  const siteLinks = useMemo(
    () =>
      headerLinks.map(({ className, children, ...link }, index) => {
        const pathName = `/${window.location.pathname.split('/')[1]}` || '/';

        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${link.to}-${index}`} className={styles['link-item']}>
            <Link
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...link}
              className={clsx(
                'link',
                className,
                styles.link,
                link.to &&
                  typeof link.to === 'string' &&
                  link.to.startsWith(pathName) &&
                  styles['active-link'],
              )}
            >
              {children}
            </Link>
          </li>
        );
      }),
    [window.location.pathname, headerLinks],
  );

  return (
    <header className={clsx('fullwidth', styles.header)}>
      <div className={styles.left}>
        <Drawer
          className={styles.drawer}
          user={user}
          links={headerLinks}
          logout={logout}
        />
        <div className={styles['logo-container']}>
          <Link to={links.home()} title="Home" className={styles['logo-link']}>
            <ReactSVG src={Logo} className={clsx('svg', styles.logo)} />
          </Link>
        </div>
      </div>
      <ul className={styles['links-container']}>
        {siteLinks}
        {/* <li key={`queue-monitor`} className={styles['link-item']}>
          <Link
            to={{ pathname: user.formQueueMonitorUrl }}
            target="_blank"
            className={clsx('link', styles['link'])}
          >
            Queue Monitor
          </Link>
        </li>
        <li key={`ecb-monitor`} className={styles['link-item']}>
          <Link
            to={{ pathname: user.ecbAuthFormUrl }}
            target="_blank"
            className={clsx('link', styles['link'])}
          >
            ECB config
          </Link>
        </li>
        <li key={`motion-config`} className={styles['link-item']}>
          <Link
            to={{ pathname: user.reopenFormUrl }}
            target="_blank"
            className={clsx('link', styles['link'])}
          >
            Motion config
          </Link>
        </li>
        <li key={`hearing-config`} className={styles['link-item']}>
          <Link
            to={{ pathname: user.hearingFormUrl }}
            target="_blank"
            className={clsx('link', styles['link'])}
          >
            Hearing config
          </Link>
        </li> */}
      </ul>
      <div className={styles['user-container']}>
        <a className={styles.notification}>
          <ReactSVG src={Bell} className={clsx('svg', styles.bell)} />
          {notificationCount ? (
            <p className={styles['notifications-count']}>{notificationCount}</p>
          ) : null}
        </a>
        <div className={styles.user}>
          <div className={styles['user-info']}>
            <p className={styles['user-name']}>{`${firstName} ${lastName}`}</p>
            <p className={styles['user-role']}>{userRole}</p>
          </div>
          <button
            title="Click to logout"
            onClick={() => logout()}
            className={styles['avatar-button']}
            type="button"
          >
            <img src={avatar} className={styles.avatar} alt="avatar" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default AuthedHeader;
