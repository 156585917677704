/* eslint-disable no-control-regex */
const numberRegex = /^\d+$/
const noSpacesRegex = /^\S*$/
// eslint-disable-next-line max-len
const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
export const validate = <T = string>(
  value: T | undefined | null,
  ...validators: Validator<T>[]
) => {
  for (const validator of validators) {
    const error = validator(value)

    if (error) {
      return error
    }
  }
}

export const isNumber = (value: string) => numberRegex.test(value)
export const isEmail = (value: string) => emailRegex.test(value)
export const hasNoSpaces = (value: string) => noSpacesRegex.test(value)

export const requiredValidate: Validator<string | boolean | number> = (value) =>
  value ? undefined : '*required'

export const emailValidate: Validator = (value) =>
  !value || (typeof value === 'string' && isEmail(value || ''))
    ? undefined
    : 'Please enter a valid email address'

export const numberValidate: Validator = (value) =>
  !value || (typeof value !== 'boolean' && isNumber(value || ''))
    ? undefined
    : 'Should be a number'

export const minLengthValidate: (len: number) => Validator = (len: number) => (
  value
) =>
  !value || (typeof value !== 'boolean' && value && value.length >= len)
    ? undefined
    : `Should have at least ${len} symbols`

export const maxLengthValidate: (len: number) => Validator = (len: number) => (
  value
) =>
  typeof value !== 'boolean' && value && value.length > len
    ? `Maximum ${len} symbols`
    : undefined

export const someCheckbox = (
  ...checkboxes: (boolean | undefined)[]
): Validator<boolean[]> => (_value) =>
  checkboxes.some(Boolean) ? undefined : 'Select at least one'

export const maxFileSize = (
  maxSize: number,
  msg?: string
): Validator<File | File[]> => (file) =>
  !file ||
  (Array.isArray(file)
    ? file.reduce((acc, file) => acc + file.size, 0)
    : file.size) < maxSize
    ? undefined
    : msg || `cannot excceed ${maxSize}`

export default {
  validate,
  isNumber,
  hasNoSpaces,
  requiredValidate,
  emailValidate,
  numberValidate,
  minLengthValidate,
  maxLengthValidate,
}
