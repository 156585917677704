import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';

import Link from '@components/Link';
import links from '@routes/links';

import styles from './styles.module.scss';

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
> & {
  response: FeedbackResponse;
  showMessage?: boolean;
};

const ResponseRow: React.FC<Props> = ({
  response,
  className,
  showMessage,
  ...props
}) => {
  const result = (() => {
    if (typeof response.result === 'boolean') {
      return response.result ? 'Yes' : 'No';
    }

    return response.result;
  })();

  return (
    <tr {...props} className={clsx('tr', styles.row, className)}>
      <td className={styles['participant-col']}>
        <Link
          className={clsx('blue-link', styles.participant)}
          to={links.userDetails(response.user_id)}
        >
          {response.user_name}
        </Link>
      </td>
      <td>
        <p className={styles.result}>{result}</p>
      </td>
      <td className={styles['message-col']}>
        {showMessage && <p>{response.message_text}</p>}
      </td>
      <td>
        <p className={styles['responded-at']}>
          {dayjs(response.created_at).format('DD MMM YYYY, hh:mm a')}
        </p>
      </td>
    </tr>
  );
};

export default ResponseRow;
