import { State, StateLabel, states } from '@typings/enums'

const getState = (stateStr: string | undefined | null): State | null => {
  if (!stateStr) {
    return null
  }

  if (Object.values(State).includes(stateStr as State)) {
    return stateStr as State
  }

  for (const state of states) {
    if (StateLabel[state] == stateStr) {
      return state
    }
  }

  return null
}

export default getState
