import clsx from 'clsx';
import React, { ReactNode, memo, useState } from 'react';

import styles from './styles.module.scss';

export interface ToggleOption {
  label: ReactNode;
  value: string | number;
}

interface Props {
  className?: string;
  options: ToggleOption[];
  selected: ToggleOption['value'];
  onChange(option: ToggleOption): void;
}

const ButtonToggle: React.FC<Props> = ({
  className,
  options,
  selected,
  onChange,
}) => {
  const [
    selectedButton,
    setSelectedButton,
  ] = useState<HTMLButtonElement | null>(null);

  return (
    <div className={clsx(styles['toggle-group'], className)}>
      <span
        className={styles['selected-bg']}
        style={{
          width: selectedButton?.clientWidth,
          left: selectedButton?.offsetLeft,
        }}
      />
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          className={clsx(
            styles.button,
            option.value === selected && styles.selected,
          )}
          onClick={() => onChange(option)}
          ref={option.value === selected ? setSelectedButton : undefined}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default memo(ButtonToggle);
