import clsx from 'clsx';
import React, { useEffect, useMemo, useRef } from 'react';
import CountUp from 'react-countup';
import { ReactSVG } from 'react-svg';

import Emoji1 from '@assets/support-emoji/emoji-1.svg';
import Emoji2 from '@assets/support-emoji/emoji-2.svg';
import Emoji3 from '@assets/support-emoji/emoji-3.svg';
import Emoji4 from '@assets/support-emoji/emoji-4.svg';
import Emoji5 from '@assets/support-emoji/emoji-5.svg';
import UsersIcon from '@assets/users.svg';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  data?: FeedbackChartResult[];
  scaleSize?: number;
}

const EMOJI = [Emoji1, Emoji2, Emoji3, Emoji4, Emoji5];

const VoteChart: React.FC<Props> = ({ className, data, scaleSize = 5 }) => {
  const distribution = useMemo(() => {
    if (!data) {
      return new Array(scaleSize + 1).fill(0);
    }

    return Array.from({ length: scaleSize + 1 }).map((_, index) => {
      const record = data.find(({ result }) => result === index);

      return record ? record.percentage : 0;
    });
  }, [data, scaleSize]);

  const barsRef = useRef<Array<HTMLDivElement | null>>([]);

  const refCallback = (index: number) => (e: HTMLDivElement | null) => {
    barsRef.current[index] = e;
  };

  // Animation
  useEffect(() => {
    const bars = barsRef.current;

    bars.forEach((bar, i) => {
      if (!bar) return;

      bar.style.transition = 'none';
      bar.style.backgroundPositionY = '0%';
      // eslint-disable-next-line no-void
      void bar.offsetWidth; // Trigger reflow
      bar.style.removeProperty('transition');
      bar.style.backgroundPositionY = `${distribution[i]}%`;
    });
  }, [distribution]);

  return (
    <div className={clsx(styles['chart-container'], className)}>
      {distribution.map((percentage, index) => {
        const count = data?.find((r) => r.result === index)?.count || 0;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={styles.col}>
            <div className={styles.bar} ref={refCallback(index)}>
              <CountUp
                className={styles.percentage}
                end={Math.round(percentage)}
                duration={0.5}
                suffix="%"
              />
              <div className={styles['count-row']}>
                <CountUp className={styles.count} end={count} duration={0.5} />
                <ReactSVG
                  src={UsersIcon}
                  className={clsx('svg', styles['users-icon'])}
                />
              </div>
            </div>
            <div className={styles.rating}>
              {scaleSize === 4 ? (
                <ReactSVG src={EMOJI[index]} className="svg" />
              ) : (
                index
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VoteChart;
