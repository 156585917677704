enum FormAuthorRoleType {
  owner = 'Owner of property/business',
  partner = 'Partner/Officer of Respondent company',
  employee = 'Employee of respondent',
  agent = 'General/Managing agent',
  attorney = 'Attorney',
  representative = 'Registered Representative',
  other = 'Other',
}

const FormAuthorRoleLabel = {
  [FormAuthorRoleType.owner]: 'Owner of property/business',
  [FormAuthorRoleType.partner]: 'Partner/Officer of Respondent company',
  [FormAuthorRoleType.employee]: 'Employee of respondent',
  [FormAuthorRoleType.agent]: 'General/Managing agent',
  [FormAuthorRoleType.attorney]: 'Attorney',
  [FormAuthorRoleType.representative]:
    'Registered Representative (Attach Completed Authorization Form)',
  [FormAuthorRoleType.other]: 'Other (friend, relative, etc)',
};

const formAuthorRoleTypes = [
  FormAuthorRoleType.owner,
  FormAuthorRoleType.partner,
  FormAuthorRoleType.employee,
  FormAuthorRoleType.agent,
  FormAuthorRoleType.attorney,
  FormAuthorRoleType.representative,
  FormAuthorRoleType.other,
];

export { FormAuthorRoleLabel, formAuthorRoleTypes };

export default FormAuthorRoleType;
