enum StrBoolean {
  true = '1',
  false = '0',
}

const StrToBoolean = {
  [StrBoolean.true]: true,
  [StrBoolean.false]: false,
};

const toStrBoolean = (b: boolean) => (b ? StrBoolean.true : StrBoolean.false);

export { StrToBoolean, toStrBoolean };

export default StrBoolean;
