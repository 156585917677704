import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import violationsActions from '@redux/violations/actions';
import { isId } from '@services/utils';
import { CaseStatusType } from '@typings/enums';

import View from './view';

const mapStateToProps = (state: ReduxState) => ({
  violationId: state.violations.violation?.id,
  defenceType: state.violations.violation?.defenceType,
  caseStatus: state.violations.violation?.caseStatus,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type NativeProps = RouteComponentProps<{ id: string }>;

type Props = NativeProps & ConnectedProps<typeof connector>;

const ViolationRequestReopen: React.FC<Props> = ({
  match: {
    params: { id },
  },
  history: { goBack },
  violationId,
  caseStatus,
  violationsActions: { loadDetails },
}) => {
  useEffect(() => {
    if (!isId(id) || caseStatus !== CaseStatusType.pendingMotion) {
      goBack();
    } else if (Number(id) !== violationId) {
      loadDetails(Number(id));
    }
  }, [id, violationId, loadDetails, caseStatus, goBack]);

  return <View goBack={goBack} />;
};

export default connector(ViolationRequestReopen) as React.FC<NativeProps>;
