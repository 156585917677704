import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

import { apiRoutes, defaultPerPage } from '@config';
import action from '@redux/action';
import api, { haveToLoadStatics } from '@services/api';
import { toFormData } from '@services/forms';
import { getState } from '@services/redux';
import { emptyFilter } from '@services/violation';
import { SortDirection, WordToBoolean } from '@typings/enums';

import ActionType from './types';

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading));

const dispatchError = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error));

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET));

const load = (
  { page, per_page, type, ...params }: ViolationsApiLoadOptions,
  reload?: boolean,
  withoutType?: boolean,
  onSuccess?: (response: ViolationApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationApiLoadData> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_BEGIN, { page, per_page, type, ...params }));

  if (!type && !withoutType) {
    type = getState().violations.params.type;
  }

  if (withoutType) {
    type = undefined;
  }

  if (!per_page) {
    per_page = getState().violations.params.per_page || defaultPerPage;
  }

  if (
    reload === undefined &&
    (type !== getState().violations.params.type || page === 1)
  ) {
    reload = true;
  }

  if (page === undefined) {
    if (reload) {
      page = 1;
    } else {
      page = Math.max((getState().violations.params.page || 0) + 1, 1);
    }
  }

  try {
    const response = await api.get<ApiSuccessResponse<ViolationApiLoadData>>(
      apiRoutes.violations,
      {
        params: {
          ...getState().violations.params,
          ...params,
          totalPages: undefined,
          reload: undefined,
          type,
          per_page,
          page,
        },
      },
    );

    dispatch(
      action(ActionType.LOAD_SUCCESS, {
        violations: response.data.data.records,
        params: {
          page,
          per_page,
          reload,
          type,
          totalPages: response.data.data.meta.lastPage,
          ...params,
        },
      }),
    );

    onSuccess?.(response.data.data);

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error));
      onError?.(error);
    }
  }
};

const sort = (
  params: ViolationsApiLoadOptions = {},
  reload?: boolean,
  onSuccess?: (response: ViolationApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationApiLoadData> => (dispatch) => {
  const prevParams = getState().violations.params;
  const prevViolations = getState().violations.violations;

  params.order_by_direction =
    params?.order_by_direction ||
    (prevParams.order_by === params.order_by &&
      prevParams.order_by_direction === SortDirection.asc)
      ? SortDirection.desc
      : SortDirection.asc;

  params.page = params.page || prevParams.page;
  params.per_page =
    params.per_page || Array.isArray(prevViolations)
      ? (prevViolations.length as number)
      : defaultPerPage;

  return load(
    params,
    reload === undefined ? true : undefined,
    false,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);
};

const filter = (
  filters: ViolationFilters & { type?: ViolationType },
  onSuccess?: (response: ViolationApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationApiLoadData> => (dispatch) =>
  load(
    { ...filters, page: 1 },
    undefined,
    !(filters.type && filters.type.length > 0),
    onSuccess,
    onError,
  )(dispatch, getState, undefined);

const resetFilters = (
  onSuccess?: (response: ViolationApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationApiLoadData> => (dispatch) =>
  load(
    { ...emptyFilter, page: 1 },
    undefined,
    true,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);

const loadDetails = (
  id: ApiID,
  onSuccess?: (response: ViolationDetails) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationDetails> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_DETAILS_BEGIN));

  try {
    const response = await api.get<ApiSuccessResponse<ViolationDetails>>(
      apiRoutes.violationDetails(id),
      { params: { type: 'for admin' } },
    );

    dispatch(action(ActionType.LOAD_DETAILS_SUCCESS, response.data.data));

    onSuccess?.(response.data.data);

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_DETAILS_ERROR, error));
      onError?.(error);
    }
  }
};

const update = (
  id: ApiID,
  updateBody: ViolationUpdateBody = {},
  onSuccess?: (response: ViolationDetails) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationDetails> => async (dispatch) => {
  dispatch(action(ActionType.UPDATE_BEGIN));

  try {
    const response = await api.put<ApiSuccessResponse<ViolationDetails>>(
      apiRoutes.violationDetails(id),
      updateBody,
    );

    dispatch(action(ActionType.UPDATE_SUCCESS, response.data.data));

    onSuccess?.(response.data.data);

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.UPDATE_ERROR, error));
      onError?.(error);
    }
  }
};

const loadInfractionCodes = (
  params: ViolationInfractionCodesLoadOptions = {},
  onSuccess?: (response: string[]) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<string[]> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_INF_CODES_BEGIN));

  try {
    const response = await api.get<
      ApiSuccessResponse<ViolationInfractionCodesLoadData>
    >(apiRoutes.violationsInfractionCodes);

    dispatch(
      action(ActionType.LOAD_INF_CODES_SUCCESS, {
        infCodes: response.data.data.records[0],
        params,
      }),
    );

    onSuccess?.(getState().violations.infCodes);

    return getState().violations.infCodes;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_INF_CODES_ERROR, error));
      onError?.(error);
    }
  }
};

const loadHearingResults = (
  onSuccess?: (response: HearingStatusType[]) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<HearingStatusType[]> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_HEARING_RESULTS_BEGIN));

  try {
    if (haveToLoadStatics(getState().violations.hearingResultsLoadedAt)) {
      const response = await api.get<
        ApiSuccessResponse<ViolationHearingResultsLoadData>
      >(apiRoutes.violationsHearingResults);

      dispatch(
        action(
          ActionType.LOAD_HEARING_RESULTS_SUCCESS,
          response.data.data.records[0],
        ),
      );
    } else {
      dispatch(
        action(
          ActionType.LOAD_HEARING_RESULTS_SUCCESS,
          getState().violations.hearingResults,
        ),
      );
    }

    onSuccess?.(getState().violations.hearingResults);

    return getState().violations.hearingResults;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_HEARING_RESULTS_ERROR, error));
      onError?.(error);
    }
  }
};

const loadCaseStatuses = (
  onSuccess?: (response: CaseStatusTypeKey[]) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<CaseStatusTypeKey[]> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_CASE_STATUSES_BEGIN));

  try {
    if (haveToLoadStatics(getState().violations.caseStatusKeysLoadedAt)) {
      const response = await api.get<
        ApiSuccessResponse<ViolationCaseStatusesLoadData>
      >(apiRoutes.violationsCaseStatuses);

      const keys = response.data.data.records.map((rec) => rec.key);

      dispatch(action(ActionType.LOAD_CASE_STATUSES_SUCCESS, keys));
    } else {
      dispatch(
        action(
          ActionType.LOAD_CASE_STATUSES_SUCCESS,
          getState().violations.caseStatusKeys,
        ),
      );
    }

    onSuccess?.(getState().violations.caseStatusKeys);

    return getState().violations.caseStatusKeys;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_CASE_STATUSES_ERROR, error));
      onError?.(error);
    }
  }
};

const loadActivity = (
  id: ApiID,
  onSuccess?: (response: ViolationActivity[]) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationActivity[]> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_ACTIVITY_BEGIN));

  try {
    const response = await api.get<
      ApiSuccessResponse<ViolationActivityLoadData>
    >(apiRoutes.violationActivity(id));

    dispatch(
      action(ActionType.LOAD_ACTIVITY_SUCCESS, response.data.data.records),
    );

    onSuccess?.(getState().violations.activity);

    return getState().violations.activity;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ACTIVITY_ERROR, error));
      onError?.(error);
    }
  }
};

const sendReopenRequest = (
  formValues: ViolationReopenRequestForm,
  onSuccess?: (response: ViolationReopenRequestApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationReopenRequestApiLoadData> => async (dispatch) => {
  let data = {
    affirmation: 'Affirmed',
    are_you_named_as_the_respondent_on_summons:
      formValues.are_you_named_as_the_respondent_on_summons,
    certification: 'Certified',
    date_respondent_learn_about_summons:
      formValues.date_respondent_learn_about_summons,
    how_did_respondent_learn_about_summons:
      formValues.how_did_respondent_learn_about_summons,
    reason_for_which_new_hearing_should_be_granted:
      formValues.reason_for_which_new_hearing_should_be_granted,
    summons_number: formValues.summons_number,
    'applicant[address]': formValues.your_address,
    'applicant[city]': formValues.your_city,
    'applicant[email]': formValues.your_email,
    'applicant[first_name]': formValues.your_first_name,
    'applicant[last_name]': formValues.your_last_name,
    'applicant[phone]': formValues.your_phone,
    'applicant[state]': formValues.your_state,
    'applicant[zip_code]': formValues.your_zip_code,
    are_you_authorized_to_represent_respondent:
      formValues.are_you_authorized_to_represent_respondent,
    explain_circumstances: formValues.explain_circumstances,
    reasonable_excuse: formValues.reasonable_excuse,
    reasonable_excuse_explain: formValues.reasonable_excuse_explain,
    relationship_to_the_named_respondent:
      formValues.persons_relationship_to_the_named_respondent,
    'respondent[address]': formValues.respondents_address,
    'respondent[city]': formValues.respondents_city,
    'respondent[email]': formValues.respondents_email,
    'respondent[first_name]': formValues.respondents_first_name,
    'respondent[last_name]': formValues.respondents_last_name,
    'respondent[phone]': formValues.respondents_phone,
    'respondent[state]': formValues.respondents_state,
    'respondent[zip_code]': formValues.respondents_zip_code,
    respondent_connection_to_the_property:
      formValues.respondent_connection_to_the_property,
    who_asked_you_to_make_request: formValues.who_asked_you_to_make_request,
    who_you_are: formValues.who_you_are,
    files: formValues.file1 ? [formValues.file1] : undefined,
  };

  if (formValues.file2) {
    data = {
      ...data,
      files:
        typeof data.files !== 'undefined'
          ? [...data.files, formValues.file2]
          : [formValues.file2],
    };
  }

  if (typeof data.files === 'undefined') {
    delete data.files;
  }

  const cleanedData = Object.entries(data).reduce((acc, [key, value]) => {
    if (value && value.length > 0) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});

  try {
    const formData = toFormData(cleanedData);

    const response = await api.post<
      ApiSuccessResponse<ViolationReopenRequestApiLoadData>
    >(apiRoutes.violationSendReopenRequest(formValues.violation_id), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch(action(ActionType.SEND_REOPEN_REQUEST_SUCCESS));
    toast('Submitted successfully');
    window.history.back();
    onSuccess?.(response.data.data);

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.data?.errors) {
        const { errors } = error.response.data;
        const keys = Object.keys(errors);

        if (keys[0] && errors[keys[0]]) {
          toast(errors[keys[0]][0]);
        }
      }

      dispatch(action(ActionType.SEND_REOPEN_REQUEST_ERROR, error));
      onError?.(error);
    }

    return undefined;
  }
};

const sendOnlineHearing = (
  data: ViolationOnlineHearingForm,
  onSuccess?: (res: string) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<ViolationOnlineHearingApiLoadData | string | unknown> => async (
  dispatch,
) => {
  dispatch(action(ActionType.SEND_ONLINE_HEARING_BEGIN));

  const {
    certification,
    file1,
    file2,
    // need to remove form the object
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    violation_id,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    file3,
    ...otherData
  } = data;

  let updatedData: ViolationOnlineHearingRequest = {
    ...otherData,
    certification: WordToBoolean[certification] ? 'Yes' : 'No',
  };

  if (WordToBoolean[data.does_name_appear_on_ticket]) {
    delete updatedData.who_you_are;
    delete updatedData.who_asked_you_to_make_request;
    delete updatedData.persons_relationship_to_the_named_respondent;
    delete updatedData.are_you_authorized_to_represent_respondent;
  }

  if (file1) {
    updatedData = {
      ...updatedData,
      files: [file1],
    };
  }

  if (file2) {
    updatedData = {
      ...updatedData,
      files:
        typeof updatedData.files !== 'undefined'
          ? [...updatedData.files, file2]
          : [file2],
    };
  }

  try {
    const formData = toFormData(updatedData);
    const response = await api.post<ApiSuccessResponse>(
      apiRoutes.violationSendOnlineHearing(data.violation_id),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    dispatch(action(ActionType.SEND_ONLINE_HEARING_SUCCESS));
    onSuccess?.(response.data.message);

    return response.data.message;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.SEND_ONLINE_HEARING_ERROR, error));
      onError?.(error);

      const errors = error.response?.data.errors as Record<string, [string]>;

      Object.values(errors).forEach(([errorMessage]) => {
        toast.error(errorMessage);
      });

      return error.message;
    }

    return error;
  }
};

const violationsActions = {
  loading,
  error: dispatchError,
  reset,

  load,
  sort,
  filter,
  resetFilters,

  loadDetails,
  update,
  loadActivity,

  loadInfractionCodes,
  loadHearingResults,
  loadCaseStatuses,

  sendReopenRequest,
  sendOnlineHearing,
};

export default violationsActions;
