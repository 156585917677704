import clsx from 'clsx';
import React, { memo } from 'react';
import { ReactSVG } from 'react-svg';

import ArrowIcon from '@assets/arrow.svg';

import styles from './styles.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: React.ReactNode;
}

const SortButton: React.FC<Props> = ({ children, className, ...props }) => (
  <button
    {...props}
    className={clsx(
      styles.button,
      props.onClick && styles['button-action'],
      className,
    )}
    type="button"
  >
    {children}
    <span className={styles.arrows}>
      <ReactSVG
        src={ArrowIcon}
        className={clsx('svg', styles.arrow, styles.top)}
      />
      <ReactSVG
        src={ArrowIcon}
        className={clsx('svg', styles.arrow, styles.bottom)}
      />
    </span>
  </button>
);

export default memo(SortButton);
