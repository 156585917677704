import { FormikErrors } from 'formik';

import {
  FormData as UserRoleFormData,
  validateForm as validateUserRole,
} from '@common/UserRoleForm';
import { ValueType, getSelectValue } from '@components/Select';
import city5 from '@constants/city5';
import {
  emailValidate,
  requiredValidate,
  validate,
} from '@services/validation';
import {
  FormAuthorRoleType,
  StateLabel,
  WordStrBoolean,
  WordToBoolean,
} from '@typings/enums';

interface FormData extends Omit<UserRoleFormData, 'who_you_are'> {
  are_you_named_as_the_respondent_on_summons: WordStrBoolean;
  who_you_are: FormAuthorRoleType;
  your_address: string;
  your_city: string;
  your_email: string;
  your_first_name: string;
  your_last_name: string;
  your_phone: string;
  your_state?: ValueType<State>;
  your_zip_code: string | number;
}

export const formInitial = (
  user: WebUser | null,
  violation: ViolationDetails | null,
): FormData => ({
  your_first_name: user?.firstName || '',
  your_last_name: user?.lastName
    ? `${user?.lastName} C/O City5 Consulting`
    : '',
  your_phone: user?.phone || city5.phone,
  your_email: user?.email || city5.email || '',
  your_address: `${city5.address} ${city5.apartmentNumber}`,
  your_city: city5.city,
  your_state: { value: city5.state, label: StateLabel[city5.state] },
  your_zip_code: city5.zipCode,
  are_you_named_as_the_respondent_on_summons: WordStrBoolean.false,
  who_you_are: FormAuthorRoleType.representative,
  who_asked_you_to_make_request: `${violation?.user?.firstName} ${violation?.user?.lastName}`,
  persons_relationship_to_the_named_respondent:
    'Authorized Representative of Respondent',
  are_you_authorized_to_represent_respondent: WordStrBoolean.true,
});

export const validateForm = ({
  your_first_name,
  your_last_name,
  your_phone,
  your_email,
  your_address,
  your_city,
  your_state,
  your_zip_code,
  are_you_named_as_the_respondent_on_summons,
  ...values
}: FormData): FormikErrors<FormData> => {
  return {
    your_first_name: validate(your_first_name, requiredValidate),
    your_last_name: validate(your_last_name, requiredValidate),
    your_phone: validate(your_phone, requiredValidate),
    your_email: validate(your_email, requiredValidate, emailValidate),
    your_address: validate(your_address, requiredValidate),
    your_city: validate(your_city, requiredValidate),
    your_state: validate(getSelectValue(your_state), requiredValidate),
    your_zip_code: validate(your_zip_code, requiredValidate),
    are_you_named_as_the_respondent_on_summons: validate(
      are_you_named_as_the_respondent_on_summons,
      requiredValidate,
    ),
    ...validateUserRole(
      WordToBoolean[are_you_named_as_the_respondent_on_summons],
    )(values),
  };
};

export const formKeys: (keyof FormData)[] = [
  'your_first_name',
  'your_last_name',
  'your_phone',
  'your_email',
  'your_address',
  'your_city',
  'your_state',
  'your_zip_code',
  'are_you_named_as_the_respondent_on_summons',
  'who_you_are',
  'who_asked_you_to_make_request',
  'persons_relationship_to_the_named_respondent',
  'are_you_authorized_to_represent_respondent',
];

export default FormData;
