import clsx from 'clsx';
import React from 'react';
import { ReactSVG } from 'react-svg';

import LocationIcon from '@assets/location.svg';
import PDFIcon from '@assets/pdf.svg';
import StonksUpIcon from '@assets/stonks-up.svg';
import ViolationInfoTable from '@common/ViolationInfoTable';
import { formatAmount } from '@services/money';

import styles from './styles.module.scss';

export interface Parts {
  balance?: boolean;
  ecbAuth?: boolean;
  hearingSummaryUrl?: boolean;
  infoTable?: boolean;
  location?: boolean;
  pdfBeforeSubmission?: boolean;
  pdfBeforeSubmissionReopen?: boolean;
  reopenRequestSummaryUrl?: boolean;
  rescheduleRequestSummaryUrl?: boolean;
  saniFee?: boolean;
}

type PartProps<K extends keyof ViolationDetails> = Pick<ViolationDetails, K>;

interface PdfSubmissionProps {
  text: string;
  url?: string;
}

const PdfSubmission: React.FC<PdfSubmissionProps> = ({ url, text }) => (
  <div className={styles.location}>
    <ReactSVG src={PDFIcon} className={clsx('svg', styles['location-icon'])} />
    <div>
      <a href={url} className={styles.title} target="_blank" rel="noreferrer">
        <u>{text}</u>
      </a>
    </div>
  </div>
);

const Location: React.FC<PartProps<'inspectionLocation'>> = ({
  inspectionLocation,
}) => (
  <div className={styles.location}>
    <ReactSVG
      src={LocationIcon}
      className={clsx('svg', styles['location-icon'])}
    />
    <div className={styles['location-info']}>
      <p className={styles.title}>Inspection Location</p>
      <p className={styles.address}>{inspectionLocation}</p>
    </div>
  </div>
);

const Balance: React.FC<PartProps<'balance'>> = ({ balance }) => (
  <div className={styles.balance}>
    <p className={styles.title}>Balance</p>
    <p className={styles['balance-value']}>
      <sup>{'$ '}</sup>
      {formatAmount(balance, undefined, '')}
    </p>
  </div>
);

const SaniFee: React.FC<PartProps<'dismissalFee'>> = ({ dismissalFee }) =>
  dismissalFee ? (
    <div className={styles.sanifee}>
      <div className={styles['fee-info']}>
        <p className={styles['fee-value']}>{formatAmount(dismissalFee)}</p>
        <p className={styles['fee-type']}>Sanifee</p>
      </div>
      <ReactSVG
        src={StonksUpIcon}
        className={clsx('svg', styles['stonks-up-icon'])}
      />
    </div>
  ) : null;

const getParts = (
  parts: Parts,
  violation: ViolationDetails,
): React.ReactNode => {
  const nodes = [];

  if (parts.location) {
    nodes.push(
      <Location
        inspectionLocation={violation.inspectionLocation}
        key={`inspectionLocation-${violation.inspectionLocation}`}
      />,
    );
  }

  if (parts.ecbAuth) {
    nodes.push(
      <PdfSubmission
        url={violation.ecbAuth}
        text="Link to submitted ECB Auth"
        key={`inspectionLocation-${violation.ecbAuth}`}
      />,
    );
  }

  if (parts.reopenRequestSummaryUrl) {
    nodes.push(
      <PdfSubmission
        url={violation.reopenRequestSummaryUrl}
        text="Motion summary"
        key={`inspectionLocation-${violation.reopenRequestSummaryUrl}`}
      />,
    );
  }

  if (parts.rescheduleRequestSummaryUrl) {
    nodes.push(
      <PdfSubmission
        url={violation.rescheduleRequestSummaryUrl}
        text="Reschedule request summary"
        key={`inspectionLocation-${violation.rescheduleRequestSummaryUrl}`}
      />,
    );
  }

  if (parts.hearingSummaryUrl) {
    nodes.push(
      <PdfSubmission
        url={violation.hearingSummaryUrl}
        text="Hearing summary"
        key={`inspectionLocation-${violation.hearingSummaryUrl}`}
      />,
    );
  }

  if (parts.balance) {
    nodes.push(
      <Balance
        balance={violation.balance}
        key={`balance-${violation.balance}`}
      />,
    );
  }

  if (parts.saniFee) {
    nodes.push(
      <SaniFee
        dismissalFee={violation.dismissalFee}
        key={`dismissalFee-${violation.dismissalFee}`}
      />,
    );
  }

  if (parts.infoTable) {
    nodes.push(
      <ViolationInfoTable
        violation={violation}
        key={`violation-${violation}`}
      />,
    );
  }

  return nodes;
};

export default getParts;
