import Buildings from '@pages/Buildings';
import DefenceQuizzes from '@pages/DefenceQuizzes';
import Defences from '@pages/Defences';
import ForgotPassword from '@pages/ForgotPassword';
import Home from '@pages/Home';
import Login from '@pages/Login';
import NoAccess from '@pages/NoAccess';
import NotFound from '@pages/NotFound';
import Payments from '@pages/Payments';
import ResetPassword from '@pages/ResetPassword';
import Responses from '@pages/Responses';
import UserDetails from '@pages/UserDetails';
import Users from '@pages/Users';
import ViolationDetails from '@pages/ViolationDetails';
import ViolationHearingForm from '@pages/ViolationHearingForm';
import ViolationRequestReopen from '@pages/ViolationRequestReopen';
import AttendedViolations from '@pages/Violations/AttendedViolations';
import ClosedViolations from '@pages/Violations/ClosedViolations';
import HiredViolations from '@pages/Violations/HiredViolations';
import UnhiredViolations from '@pages/Violations/UnhiredViolations';
import { authed, notAuthed } from '@routes/validation';

const pages = {
  Home: authed(Home),

  Login: notAuthed(Login),
  NoAccess,
  ForgotPassword: notAuthed(ForgotPassword),
  ResetPassword: notAuthed(ResetPassword),

  Defences: authed(Defences),
  DefencesQuizzes: authed(DefenceQuizzes),

  HiredViolations: authed(HiredViolations),
  ClosedViolations: authed(ClosedViolations),
  AttendedViolations: authed(AttendedViolations),
  UnhiredViolations: authed(UnhiredViolations),
  ViolationDetails: authed(ViolationDetails),
  ViolationRequestReopen: authed(ViolationRequestReopen),
  ViolationHearingForm: authed(ViolationHearingForm),

  Payments: authed(Payments),

  Buildings: authed(Buildings),

  Users: authed(Users),
  UserDetails: authed(UserDetails),

  Responses: authed(Responses),

  NotFound,
};

export default pages;
