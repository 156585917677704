enum ActionType {
  LOADING = '[buildings]: loading',
  ERROR = '[buildings]: error',
  RESET = '[buildings]: reset',

  LOAD_BEGIN = '[buildings]: get begin',
  LOAD_SUCCESS = '[buildings]: get success',
  LOAD_ERROR = '[buildings]: get error',
}

type LoadingAction = ReduxAction<ActionType.LOADING, boolean>;

type ErrorAction = ReduxAction<ActionType.ERROR, ErrorType>;

type ResetAction = ReduxAction<ActionType.RESET>;

type LoadBeginAction = ReduxAction<ActionType.LOAD_BEGIN>;

type LoadSuccessAction = ReduxAction<
  ActionType.LOAD_SUCCESS,
  {
    buildings: UserBuilding[];
    params: BuildingsParams;
  }
>;

type LoadErrorAction = ReduxAction<ActionType.LOAD_ERROR, ErrorType>;

export type Action =
  | LoadingAction
  | ErrorAction
  | ResetAction
  | LoadBeginAction
  | LoadSuccessAction
  | LoadErrorAction;

export default ActionType;
