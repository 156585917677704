/* eslint-disable no-param-reassign */
import { isAxiosError } from 'axios';

import { apiRoutes, defaultPerPage } from '@config';
import action from '@redux/action';
import api from '@services/api';
import { getState } from '@services/redux';
import { emptyFilter } from '@services/violation';

import ActionType from './types';

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading));

const dispatchError = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error));

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET));

const loadQuestions = (
  { page, per_page, type, ...params }: FeedbackQuestionsApiLoadOptions,
  reload?: boolean,
  onSuccess?: (response: FeedbackQuestionApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackQuestionApiLoadData> => async (dispatch) => {
  dispatch(
    action(ActionType.LOAD_QUESTIONS_BEGIN, {
      page,
      per_page,
      type,
      ...params,
    }),
  );

  if (!type) {
    type = getState().feedbacks.questionParams.type;
  }

  if (!per_page) {
    per_page = getState().feedbacks.questionParams.per_page || defaultPerPage;
  }

  if (
    reload === undefined &&
    (type !== getState().feedbacks.questionParams.type || page === 1)
  ) {
    reload = true;
  }

  if (page === undefined) {
    if (reload) {
      page = 1;
    } else {
      page = Math.max((getState().feedbacks.questionParams.page || 0) + 1, 1);
    }
  }

  try {
    const response = await api.get<
      ApiSuccessResponse<FeedbackQuestionApiLoadData>
    >(apiRoutes.questions, {
      params: {
        ...getState().feedbacks.questionParams,
        ...params,
        totalPages: undefined,
        reload: undefined,
        type,
        per_page,
        page,
      },
    });

    dispatch(
      action(ActionType.LOAD_QUESTIONS_SUCCESS, {
        questions: response.data.data.records,
        params: {
          page,
          per_page,
          reload,
          type,
          totalPages: response.data.data.meta.lastPage,
          ...params,
        },
      }),
    );

    onSuccess?.(response.data.data);

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_QUESTIONS_ERROR, error));
      onError?.(error);
    }

    return undefined;
  }
};

const sortQuestions = (
  params: FeedbackQuestionsApiLoadOptions,
  reload?: boolean,
  onSuccess?: (response: FeedbackQuestionApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackQuestionApiLoadData> => (dispatch) => {
  const prevParams = getState().feedbacks.questionParams;
  const prevQuestions = getState().feedbacks.questions;

  params.order_by_direction =
    params.order_by_direction || prevParams.order_by_direction;
  params.page = params.page || prevParams.page;
  params.per_page = params.per_page || prevQuestions.length;

  return loadQuestions(
    params,
    reload === undefined ? true : undefined,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);
};

const filterQuestions = (
  filters: FeedbackQuestionFilters & { type?: FeedbackQuestionType },
  onSuccess?: (response: FeedbackQuestionApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackQuestionApiLoadData> => (dispatch) =>
  loadQuestions(
    { ...filters, page: 1 },
    undefined,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);

const resetQuestionFilters = (
  onSuccess?: (response: FeedbackQuestionApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackQuestionApiLoadData> => (dispatch) =>
  loadQuestions(
    { ...emptyFilter, page: 1 },
    undefined,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);

const loadFeedbacks = (
  { page, per_page, ...params }: FeedbacksApiLoadOptions,
  reload?: boolean,
  onSuccess?: (response: FeedbackApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackApiLoadData> => async (dispatch) => {
  dispatch(
    action(ActionType.LOAD_FEEDBACKS_BEGIN, {
      page,
      per_page,
      ...params,
    }),
  );

  if (!per_page) {
    per_page = getState().feedbacks.feedbackParams.per_page || defaultPerPage;
  }

  if (reload === undefined) {
    reload = true;
  }

  if (page === undefined) {
    if (reload) {
      page = 1;
    } else {
      page = Math.max((getState().feedbacks.feedbackParams.page || 0) + 1, 1);
    }
  }

  try {
    const response = await api.get<ApiSuccessResponse<FeedbackApiLoadData>>(
      apiRoutes.feedbacks,
      {
        params: {
          ...getState().feedbacks.feedbackParams,
          ...params,
          totalPages: undefined,
          reload: undefined,
          per_page,
          page,
        },
      },
    );

    dispatch(
      action(ActionType.LOAD_FEEDBACKS_SUCCESS, {
        feedbacks: response.data.data.records,
        params: {
          page,
          per_page,
          reload,
          totalPages: response.data.data.meta.lastPage,
          ...params,
        },
      }),
    );

    onSuccess?.(response.data.data);

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_FEEDBACKS_ERROR, error));
      onError?.(error);
    }

    return undefined;
  }
};

const sortFeedbacks = (
  params: FeedbacksApiLoadOptions,
  reload?: boolean,
  onSuccess?: (response: FeedbackApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackApiLoadData> => (dispatch) => {
  const prevParams = getState().feedbacks.feedbackParams;
  const prevFeedbacks = getState().feedbacks.feedbacks;

  params.order_by_direction =
    params.order_by_direction || prevParams.order_by_direction;
  params.page = params.page || prevParams.page;
  params.per_page = params.per_page || prevFeedbacks.length;

  return loadFeedbacks(
    params,
    reload === undefined ? true : undefined,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);
};

const filterFeedbacks = (
  filters: FeedbackFilters & { type?: FeedbackQuestionType },
  onSuccess?: (response: FeedbackApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackApiLoadData> => (dispatch) =>
  loadFeedbacks(
    { ...filters, page: 1 },
    undefined,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);

const resetFeedbackFilters = (
  onSuccess?: (response: FeedbackApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackApiLoadData> => (dispatch) =>
  loadFeedbacks(
    { ...emptyFilter, page: 1 },
    undefined,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);

const loadStats = (
  {
    page,
    per_page,
    feedbackQuestionId,
    ...params
  }: FeedbackStatsApiLoadOptions,
  reload?: boolean,
  onSuccess?: (response: FeedbackStatsApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackStatsApiLoadData> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_STATS_BEGIN, params));

  if (!feedbackQuestionId) {
    feedbackQuestionId = getState().feedbacks.statsParams.feedbackQuestionId;
  }

  if (!per_page) {
    per_page = getState().feedbacks.statsParams.per_page || defaultPerPage;
  }

  if (
    reload === undefined &&
    (feedbackQuestionId !==
      getState().feedbacks.statsParams.feedbackQuestionId ||
      page === 1)
  ) {
    reload = true;
  }

  if (page === undefined) {
    if (reload) {
      page = 1;
    } else {
      page = Math.max((getState().feedbacks.statsParams.page || 0) + 1, 1);
    }
  }

  try {
    const response = await api.get<
      ApiSuccessResponse<FeedbackStatsApiLoadData>
    >(apiRoutes.feedbackStats(feedbackQuestionId), {
      params: {
        ...getState().feedbacks.statsParams,
        ...params,
        totalPages: undefined,
        reload: undefined,
        per_page,
        page,
      },
    });

    dispatch(
      action(ActionType.LOAD_STATS_SUCCESS, {
        stats: response.data.data,
        params: {
          page,
          per_page,
          reload,
          feedbackQuestionId,
          totalPages: response.data.data.responses.meta.lastPage,
          ...params,
        },
      }),
    );

    onSuccess?.(response.data.data);

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_STATS_ERROR, error));
      onError?.(error);
    }

    return undefined;
  }
};

const sortStats = (
  params: FeedbackStatsApiLoadOptions,
  onSuccess?: (response: FeedbackStatsApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<FeedbackStatsApiLoadData> => (dispatch) => {
  const prevParams = getState().feedbacks.statsParams;
  const prevFeedbacks = getState().feedbacks.feedbacks;

  params.order_by = params.order_by || prevParams.order_by;
  params.page = params.page || prevParams.page;
  params.per_page = params.per_page || prevFeedbacks.length;

  return loadStats(
    params,
    undefined,
    onSuccess,
    onError,
  )(dispatch, getState, undefined);
};

const feedbacksActions = {
  loading,
  error: dispatchError,
  reset,

  loadQuestions,
  sortQuestions,
  filterQuestions,
  resetQuestionFilters,

  loadFeedbacks,
  sortFeedbacks,
  filterFeedbacks,
  resetFeedbackFilters,

  loadStats,
  sortStats,
};

export default feedbacksActions;
