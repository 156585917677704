import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';

interface Props {
  status: string | null;
}

const SubscriptionStatus: React.FC<Props> = ({ status }) => {
  return (
    <span
      className={clsx(
        styles.subscriptionStatus,
        styles[status !== null ? 'active' : 'canceled'],
      )}
    >
      {status ?? 'Canceled'}
    </span>
  );
};

export default SubscriptionStatus;
