import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import userActions from '@redux/user/actions';
import links from '@routes/links';
import { getErrorMessage } from '@services/error';

import { FormData } from './form';
import View from './view';

const mapStateToProps = (state: ReduxState) => ({
  isLoading: state.user.isLoading,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  userActions: bindActionCreators<
    typeof userActions,
    BindedAsyncActions<typeof userActions>
  >(userActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type NativeProps = RouteComponentProps;

type Props = NativeProps & ConnectedProps<typeof connector>;

const Login: React.FC<Props> = ({
  isLoading,
  userActions: { login },
  history: { replace },
}) => {
  const [error, setError] = useState('');

  const onSubmit = useCallback(
    ({ email, password, remember }: FormData) => {
      login(
        email,
        password,
        remember,
        () => replace(links.home()),
        (err) => setError(getErrorMessage(err)),
      );
    },
    [login, replace],
  );

  const resetError = () => setError('');

  return (
    <View
      onSubmit={onSubmit}
      isLoading={isLoading}
      error={error}
      resetError={resetError}
    />
  );
};

export default connector(Login) as React.FC<NativeProps>;
