import React from 'react';

import styles from '../styles.module.scss';

interface Props {
  question: Question;
}

const QuestionItem: React.FC<Props> = ({ question }) => {
  return (
    <div className={styles['question-item']}>
      <div className={styles['question-item-block']}>
        <div className={styles.header}>
          <p>{question.question}</p>
        </div>
        <div className={styles.answers}>
          {question.answers.map((answer, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index + answer} className={styles['answer-item']}>
              <p>{`0${index}`}</p>
              <p>{answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionItem;
