import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ValueType } from 'react-select';
import { bindActionCreators } from 'redux';

import PagingFooter from '@common/PagingFooter';
import { OptionType, getSelectValue } from '@components/Select';
import infractionCodesActions from '@redux/infractionCodes/actions';
import { emptyFilter, emptySort } from '@services/user';
import { DefenseType } from '@typings/enums';

import View from './view';
import { typeOptions } from '@constants/filters-lists';

const mapStateToProps = (state: ReduxState) => ({
  infractionCodes: state.infractionCodes.infractionCodes,
  isLoading: state.infractionCodes.isLoading,
  page: state.infractionCodes.params.page,
  perPage: state.infractionCodes.params.per_page,
  totalPages: state.infractionCodes.params.totalPages,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  infractionCodesActions: bindActionCreators<
    typeof infractionCodesActions,
    BindedAsyncActions<typeof infractionCodesActions>
  >(infractionCodesActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

const Defences: React.FC<Props> = ({
  infractionCodes,
  infractionCodesActions: { load },
  isLoading,
  page,
  perPage,
  totalPages,
}) => {
  const [type, setType] = useState(typeOptions[0].value);

  useEffect(() => {
    load({ page: 1, per_page: 20, ...emptyFilter, ...emptySort }, true);
  }, [load]);

  const changeFilter = useCallback(
    (option: ValueType<OptionType<DefenseType>>) => {
      const value = getSelectValue(option);

      if (value && !Array.isArray(value)) {
        setType(value);

        if (value === DefenseType.all) {
          load({ page: 1, per_page: 20, ...emptyFilter, ...emptySort }, true);

          return;
        }

        load(
          { page: 1, per_page: 20, ...emptyFilter, ...emptySort, type: value },
          true,
        );
      }
    },
    [setType, load],
  );

  const typeValue = useMemo(
    () => typeOptions.find((option) => option.value === type),
    [type],
  );

  const onLoad = useCallback(
    (params: InfractionCodesApiLoadOptions) => load({ ...params }, true),
    [load],
  );

  return (
    <View
      infractionCodes={infractionCodes}
      isLoading={isLoading}
      loadCodes={onLoad}
      perPage={perPage}
      changeFilter={changeFilter}
      typeValue={typeValue}
      pagination={
        <PagingFooter
          availablePerPage={[20, 40, 60]}
          isLoading={isLoading}
          page={page || 0}
          perPage={perPage || 0}
          totalPages={totalPages || 0}
          onChange={onLoad}
        />
      }
    />
  );
};

export default connector(Defences);
