import React, { useCallback, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import { defaultPerPage } from '@config';
import violationsActions from '@redux/violations/actions';
import { emptyFilter, emptySort } from '@services/violation';
import { ViolationType } from '@typings/enums';

import View from './view';

const ViolationsTableWrapper = <
  Type extends Violation = Violation,
  NativeProps extends RouteComponentProps = RouteComponentProps
>(
  type: ViolationType,
) => {
  const mapStateToProps = (state: ReduxState) => ({
    violations: state.violations.violations,
    isLoading: state.violations.isViolationsLoading,
    page: state.violations.params.page,
    perPage: state.violations.params.per_page,
    totalPages: state.violations.params.totalPages,
  });

  const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
    violationsActions: bindActionCreators<
      typeof violationsActions,
      BindedAsyncActions<typeof violationsActions>
    >(violationsActions, dispatch),
  });

  const connector = connect(mapStateToProps, mapDispatchToProps);

  type Props = NativeProps & ConnectedProps<typeof connector>;

  const ViolationsTable: React.FC<Props> = ({
    violationsActions: { load: loadViolations, sort: sortViolations },
    violations = [],
    page,
    perPage,
    totalPages,
    isLoading,
  }) => {
    useEffect(() => {
      loadViolations(
        {
          ...emptySort,
          ...emptyFilter,
          page: 1,
          per_page: defaultPerPage,
          type,
          users_ids: undefined,
          user_id: undefined,
          group_by: undefined,
        },
        true,
      );
    }, [type, loadViolations]);

    const load = useCallback(
      (params: PagingParams) =>
        loadViolations(
          { ...params, user_id: undefined, group_by: undefined },
          true,
        ),
      [type, loadViolations],
    );

    const sort = useCallback(
      (order_by?: ViolationSortType) => sortViolations({ order_by }),
      [sortViolations],
    );

    return (
      <View
        type={type}
        violations={Array.isArray(violations) ? (violations as Type[]) : []}
        sort={sort}
        page={page || 0}
        perPage={perPage || 0}
        totalPages={totalPages || 0}
        load={load}
        isLoading={isLoading}
      />
    );
  };

  return compose<React.FC<NativeProps>>(connector)(ViolationsTable);
};

export default ViolationsTableWrapper;
