import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ValueType } from 'react-select';
import { ReactSVG } from 'react-svg';

import ArrowIcon from '@assets/arrow.svg';
import Select, { OptionType } from '@components/Select';
import { defaultPerPage } from '@config';

import styles from './styles.module.scss';

interface Props {
  availablePerPage: number[];
  isLoading: boolean;
  page?: number;
  perPage?: number;
  totalPages?: number;
  onChange?(params: PagingParams): void;
}

const PagingFooter: React.FC<Props> = ({
  availablePerPage,
  page = 0,
  perPage = 0,
  totalPages = 0,
  isLoading,
  onChange,
}) => {
  const perPageOptions = useMemo(
    () =>
      availablePerPage.map((value) => ({
        value: String(value),
        label: String(value),
      })),
    [availablePerPage],
  );

  const perPageValue = useMemo(
    () => perPageOptions.find((p) => p.value === String(perPage)),
    [perPage, perPageOptions],
  );

  const prevPage = () =>
    onChange?.({ per_page: +(perPageValue?.value ?? 20), page: page - 1 });

  const prevDisabled = isLoading || page === 1;

  const nextPage = () =>
    onChange?.({ per_page: +(perPageValue?.value ?? 20), page: page + 1 });

  const nextDisabled = isLoading || page === totalPages;

  const onPerPageChange = (value: ValueType<OptionType>) => {
    const newPerPage = Number((value as OptionType)?.value || defaultPerPage);
    const newPage = Math.min(
      page,
      Math.ceil((totalPages * perPage) / newPerPage),
    );

    onChange?.({ per_page: newPerPage, page: newPage });
  };

  return (
    <footer className={styles['paging-footer']}>
      <div className={styles.page}>
        <p className={styles['page-text']}>
          <span className={styles['current-page']}>{page}</span>
          {' of '}
          <span className={styles['total-pages']}>{totalPages}</span>
        </p>
        <button
          type="button"
          className={styles['control-button']}
          onClick={prevPage}
          disabled={prevDisabled}
        >
          <ReactSVG
            src={ArrowIcon}
            className={clsx(
              'svg',
              styles['arrow-icon'],
              styles['arrow-icon-left'],
            )}
          />
        </button>
        <button
          type="button"
          className={styles['control-button']}
          onClick={nextPage}
          disabled={nextDisabled}
        >
          <ReactSVG
            src={ArrowIcon}
            className={clsx(
              'svg',
              styles['arrow-icon'],
              styles['arrow-icon-right'],
            )}
          />
        </button>
      </div>
      <div className={styles['per-page']}>
        <p className={styles['per-page-title']}>{'Showing '}</p>
        <Select
          options={perPageOptions}
          small
          value={perPageValue}
          onChange={onPerPageChange}
        />
        <p className={styles['per-page-title']}>{' records '}</p>
      </div>
    </footer>
  );
};

export default PagingFooter;
