enum ActionType {
  LOADING = '[feedbacks]: loading',
  ERROR = '[feedbacks]: error',
  RESET = '[feedbacks]: reset',

  LOAD_QUESTIONS_BEGIN = '[feedbacks]: get questions begin',
  LOAD_QUESTIONS_SUCCESS = '[feedbacks]: get questions success',
  LOAD_QUESTIONS_ERROR = '[feedbacks]: get questions error',

  LOAD_FEEDBACKS_BEGIN = '[feedbacks]: get feedbacks begin',
  LOAD_FEEDBACKS_SUCCESS = '[feedbacks]: get feedbacks success',
  LOAD_FEEDBACKS_ERROR = '[feedbacks]: get feedbacks error',

  LOAD_STATS_BEGIN = '[feedbacks]: get stats begin',
  LOAD_STATS_SUCCESS = '[feedbacks]: get stats success',
  LOAD_STATS_ERROR = '[feedbacks]: get stats error',
}

type LoadingAction = ReduxAction<ActionType.LOADING, boolean>;
type ErrorAction = ReduxAction<ActionType.ERROR, ErrorType>;
type ResetAction = ReduxAction<ActionType.RESET>;

type LoadQuestionsBeginAction = ReduxAction<ActionType.LOAD_QUESTIONS_BEGIN>;
type LoadQuestionsSuccessAction = ReduxAction<
  ActionType.LOAD_QUESTIONS_SUCCESS,
  {
    questions: FeedbackQuestion[];
    params: FeedbackQuestionsParams;
  }
>;
type LoadQuestionsErrorAction = ReduxAction<
  ActionType.LOAD_QUESTIONS_ERROR,
  ErrorType
>;

type LoadFeedbacksBeginAction = ReduxAction<ActionType.LOAD_FEEDBACKS_BEGIN>;
type LoadFeedbacksSuccessAction = ReduxAction<
  ActionType.LOAD_FEEDBACKS_SUCCESS,
  {
    feedbacks: Feedback[];
    params: FeedbacksParams;
  }
>;
type LoadFeedbacksErrorAction = ReduxAction<
  ActionType.LOAD_FEEDBACKS_ERROR,
  ErrorType
>;

type LoadStatsBeginAction = ReduxAction<ActionType.LOAD_STATS_BEGIN>;
type LoadStatsSuccessAction = ReduxAction<
  ActionType.LOAD_STATS_SUCCESS,
  {
    stats: FeedbackStats;
    params: FeedbackStatsParams;
  }
>;
type LoadStatsErrorAction = ReduxAction<ActionType.LOAD_STATS_ERROR, ErrorType>;

export type Action =
  | LoadingAction
  | ErrorAction
  | ResetAction
  | LoadQuestionsBeginAction
  | LoadQuestionsSuccessAction
  | LoadQuestionsErrorAction
  | LoadFeedbacksBeginAction
  | LoadFeedbacksSuccessAction
  | LoadFeedbacksErrorAction
  | LoadStatsBeginAction
  | LoadStatsSuccessAction
  | LoadStatsErrorAction;

export default ActionType;
