import React from 'react';

import styles from './styles.module.scss';

interface Props {
  message?: string;
}

const EmptyListMessage: React.FC<Props> = ({
  message = 'There are no records',
}) => {
  return <p className={styles['empty-list-message']}>{message}</p>;
};

export default EmptyListMessage;
