import api, { haveToLoadStatics } from '@services/api'

import action from '@redux/action'
import ActionType from './types'

import { apiRoutes, defaultPerPage } from '@config'
import { emptyFilter } from '@services/payment'
import { IncomePeriodType, SortDirection } from '@typings/enums'
import { isAxiosError } from 'axios'

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading))

const error = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error))

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET))

const load = (
  { page, per_page, ...params }: PaymentsApiLoadOptions = {
    page: undefined,
    per_page: undefined,
  },
  reload?: boolean,
  onSuccess?: (response: PaymentsApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<PaymentsApiLoadData> => async (dispatch, getState) => {
  dispatch(action(ActionType.LOAD_BEGIN, { page, per_page, ...params }))

  if (!per_page) {
    per_page = getState().payments.params.per_page || defaultPerPage
  }

  if (reload === undefined && page === 1) {
    reload = true
  }

  if (page === undefined) {
    if (reload) {
      page = 1
    } else {
      page = Math.max((getState().payments.params.page || 0) + 1, 1)
    }
  }

  try {
    const response = await api.get<ApiSuccessResponse<PaymentsApiLoadData>>(
      apiRoutes.payments,
      {
        params: {
          ...getState().payments.params,
          ...params,
          per_page,
          page,
          totalPages: undefined,
          reload: undefined,
        },
      }
    )

    dispatch(
      action(ActionType.LOAD_SUCCESS, {
        payments: response.data.data.records,
        params: {
          ...getState().payments.params,
          ...params,
          page,
          per_page,
          reload,
          totalPages: response.data.data.meta.lastPage,
        },
      })
    )

    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error))
      onError?.(error)
    }
  }
}

const sort = (
  params: PaymentsApiLoadOptions = {},
  reload?: boolean,
  onSuccess?: (response: PaymentsApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<PaymentsApiLoadData> => (dispatch, getState) => {
  const prevParams = getState().payments.params

  params.order_by_direction =
    params?.order_by_direction ||
    (prevParams.order_by === params.order_by &&
      prevParams.order_by_direction === SortDirection.asc)
      ? SortDirection.desc
      : SortDirection.asc

  params.page = params.page || prevParams.page
  params.per_page = params.per_page || getState().payments.payments.length

  reload = reload === undefined ? true : undefined

  return load(params, reload, onSuccess, onError)(dispatch, getState, undefined)
}

const filter = (
  filters: PaymentFilters,
  onSuccess?: (response: PaymentsApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<PaymentsApiLoadData> => (dispatch, getState) =>
  load(
    { ...filters, page: 1 },
    undefined,
    onSuccess,
    onError
  )(dispatch, getState, undefined)

const resetFilters = (
  onSuccess?: (response: PaymentsApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<PaymentsApiLoadData> => (dispatch, getState) =>
  load(
    { ...emptyFilter, page: 1 },
    undefined,
    onSuccess,
    onError
  )(dispatch, getState, undefined)

const loadStatuses = (
  onSuccess?: (response: PaymentStatusTypeKey[]) => void,
  onError?: (error: ErrorType) => void
): AppThunk<PaymentStatusTypeKey[]> => async (dispatch, getState) => {
  dispatch(action(ActionType.LOAD_STATUSES_BEGIN))

  try {
    if (haveToLoadStatics(getState().payments.statusesLoadedAt)) {
      const response = await api.get<
        ApiSuccessResponse<PaymentsStatusesLoadData>
      >(apiRoutes.paymentsStatuses)

      const statuses = response.data.data.records.map((rec) => rec.key)

      dispatch(action(ActionType.LOAD_STATUSES_SUCCESS, statuses))
    } else {
      dispatch(
        action(ActionType.LOAD_STATUSES_SUCCESS, getState().payments.statuses)
      )
    }

    onSuccess?.(getState().payments.statuses)
    return getState().payments.statuses
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_STATUSES_ERROR, error))
      onError?.(error)
    }
  }
}

const loadIncomeStats = (
  params: IncomeStatsLoadOptions = {},
  onSuccess?: (response: IncomeStats) => void,
  onError?: (error: ErrorType) => void
): AppThunk<IncomeStats> => async (dispatch, getState) => {
  dispatch(action(ActionType.LOAD_INCOME_STATS_BEGIN))

  try {
    const response = await api.get<ApiSuccessResponse<IncomeStats>>(
      apiRoutes.incomeStats,
      { params }
    )

    dispatch(action(ActionType.LOAD_INCOME_STATS_SUCCESS, response.data.data))

    onSuccess?.(getState().payments.incomeStats)
    return getState().payments.incomeStats
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_INCOME_STATS_ERROR, error))
      onError?.(error)
    }
  }
}

const loadHalfYearIncomeStats = (
  params: IncomeStatsLoadOptions = { period: IncomePeriodType.monthly },
  onSuccess?: (response: IncomeStats) => void,
  onError?: (error: ErrorType) => void
): AppThunk<IncomeStats> => async (dispatch, getState) => {
  dispatch(action(ActionType.LOAD_HALF_YEAR_INCOME_STATS_BEGIN))

  try {
    const response = await api.get<
      ApiSuccessResponse<IncomeStats & { lastSixMonth: IncomeTotalStat }>
    >(apiRoutes.incomeStats, {
      params: {
        ...params,
        period: params.period || IncomePeriodType.monthly,
        last_6_month: 1,
      },
    })

    const { lastSixMonth: total, ...data } = response.data.data

    dispatch(
      action(ActionType.LOAD_HALF_YEAR_INCOME_STATS_SUCCESS, { total, data })
    )

    onSuccess?.(getState().payments.incomeStats)
    return getState().payments.incomeStats
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_HALF_YEAR_INCOME_STATS_ERROR, error))
      onError?.(error)
    }
  }
}

const paymentsActions = {
  loading,
  error,
  reset,

  load,
  sort,
  filter,
  resetFilters,

  loadStatuses,
  loadIncomeStats,
  loadHalfYearIncomeStats,
}

export default paymentsActions
