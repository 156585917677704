import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useCallback } from 'react';

import UserRoleForm from '@common/UserRoleForm';
import BigCheckbox from '@components/BigCheckbox';
import Input from '@components/Input';
import Select, { ValueType } from '@components/Select';
import { phoneMask } from '@constants/mask';
import statesOptions from '@constants/statesOptions';
import { WordStrBoolean, WordToBoolean } from '@typings/enums';

import sharedStyles from '../styles.module.scss';

import FormData from './form';

interface Props {
  errors: FormikErrors<FormData>;
  form: FormData;
  touched: FormikTouched<FormData>;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  setFieldValue<F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
    shouldValidate?: boolean,
  ): void;
}

const SenderInfo: React.FC<Props> = ({
  form,
  touched,
  errors,
  onChange,
  setFieldValue,
}) => {
  const onStateChange = useCallback(
    (e: ValueType<State>) => setFieldValue('your_state', e, false),
    [setFieldValue],
  );

  return (
    <div className={sharedStyles.section}>
      <div className={sharedStyles['section-head']}>
        <h2 className={sharedStyles['section-title']}>
          Name and address of person completing this form
        </h2>
      </div>

      <div
        className={clsx(
          sharedStyles.inputs,
          sharedStyles['two-columns'],
          sharedStyles['sub-section'],
        )}
      >
        <Input
          type="text"
          name="your_first_name"
          value={form.your_first_name}
          onChange={onChange}
          label="Your First Name"
          labelAsterisk
          placeholder="e.g. John"
          required
          error={touched.your_first_name && errors.your_first_name}
        />
        <Input
          type="text"
          name="your_last_name"
          value={form.your_last_name}
          onChange={onChange}
          label="Your Last Name"
          labelAsterisk
          placeholder="e.g. Millere"
          required
          error={touched.your_last_name && errors.your_last_name}
        />
        <Input
          type="tel"
          name="your_phone"
          value={form.your_phone}
          onChange={onChange}
          label="Your Phone"
          labelAsterisk
          placeholder="e.g. +1 (780) 555-1721"
          required
          mask={phoneMask}
          error={touched.your_phone && errors.your_phone}
        />
        <Input
          type="email"
          name="your_email"
          value={form.your_email}
          onChange={onChange}
          label="Your Email"
          labelAsterisk
          placeholder="example@email.com"
          required
          error={touched.your_email && errors.your_email}
        />
        <Input
          type="text"
          name="your_address"
          value={form.your_address}
          onChange={onChange}
          label="Your Address"
          labelAsterisk
          placeholder="Enter Address"
          required
          error={touched.your_address && errors.your_address}
        />
        <Input
          type="text"
          name="your_city"
          value={form.your_city}
          onChange={onChange}
          label="Your City"
          labelAsterisk
          placeholder="Enter City"
          required
          error={touched.your_city && errors.your_city}
        />
        <Select
          value={form.your_state}
          onChange={onStateChange}
          options={statesOptions}
          label="Your State"
          placeholder="Enter State"
          labelAsterisk
          required
          error={touched.your_state && errors.your_state}
        />
        <Input
          type="number"
          name="your_zip_code"
          value={form.your_zip_code}
          onChange={onChange}
          label="Your ZIP Code"
          labelAsterisk
          placeholder="0000"
          required
          error={touched.your_zip_code && errors.your_zip_code}
        />
      </div>
      <div className={sharedStyles['sub-section']}>
        <div
          className={clsx(
            sharedStyles['input-wrapper'],
            !WordToBoolean[form.are_you_named_as_the_respondent_on_summons] &&
              sharedStyles['with-margin'],
          )}
        >
          <label className="input-label">
            {'Are you the person named as the Respondent on the Summons?'}
            <span className="asterisk" />
          </label>
          <div
            className={clsx(sharedStyles.inputs, sharedStyles['big-radios'])}
          >
            <BigCheckbox
              type="radio"
              name="are_you_named_as_the_respondent_on_summons"
              value={WordStrBoolean.true}
              checked={
                WordToBoolean[form.are_you_named_as_the_respondent_on_summons]
              }
              onChange={onChange}
              containerClassName={sharedStyles['big-radio']}
            >
              Yes
            </BigCheckbox>
            <BigCheckbox
              type="radio"
              name="are_you_named_as_the_respondent_on_summons"
              value={WordStrBoolean.false}
              checked={
                !WordToBoolean[form.are_you_named_as_the_respondent_on_summons]
              }
              onChange={onChange}
              containerClassName={sharedStyles['big-radio']}
            >
              No
            </BigCheckbox>
          </div>
        </div>
        <UserRoleForm
          hide={WordToBoolean[form.are_you_named_as_the_respondent_on_summons]}
          who_you_are={form.who_you_are}
          who_asked_you_to_make_request={form.who_asked_you_to_make_request}
          persons_relationship_to_the_named_respondent={
            form.persons_relationship_to_the_named_respondent
          }
          are_you_authorized_to_represent_respondent={
            form.are_you_authorized_to_represent_respondent
          }
          onChange={onChange}
          errors={errors}
          touched={touched}
          radioLabelClassName={sharedStyles['radio-label']}
          inputWrapperClassName={sharedStyles['input-wrapper']}
          withMarginClassName={sharedStyles['with-margin']}
          inputsClassName={sharedStyles.inputs}
          bigRadiosClassName={sharedStyles['big-radios']}
          bigRadioClassName={sharedStyles['big-radio']}
        />
      </div>
    </div>
  );
};

export default SenderInfo;
