import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import View from './view';

const Responses: React.FC<RouteComponentProps> = () => {
  return <View />;
};

export default Responses;
