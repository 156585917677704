import * as Yup from 'yup';

import { AnswerError } from './types';

export const isEmptyQuestion = (questions?: Question[]) =>
  questions &&
  questions?.length <= 1 &&
  questions[0].question.length === 0 &&
  questions[0].answers.every((item) => item.length === 0);

export const isNotNewQuiz = (quiz: Quiz | NewQuiz | null): quiz is Quiz =>
  !!quiz && 'id' in quiz;

export const validateQuestions = (values: Quiz | NewQuiz) => {
  if (!isEmptyQuestion(values.questions)) {
    const questionsErrors = values.questions?.reduce(
      (acc, { question: q, answers }, index) => {
        const error: AnswerError = {
          index,
        };

        if (q.length === 0) {
          error.question = 'Question is required';
        }

        if (answers.some((answer) => answer.length === 0)) {
          error.answers = answers.map((answer) =>
            answer.length > 0 ? undefined : 'Required',
          );
        }

        if (Object.keys(error).length > 1) {
          return [...acc, error];
        }

        return acc;
      },
      [] as AnswerError[],
    );

    if (questionsErrors?.length && questionsErrors?.length > 0) {
      return {
        questions: questionsErrors,
      };
    }

    return undefined;
  }

  return undefined;
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
});
