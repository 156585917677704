import React, { useCallback, useMemo } from 'react';

import PagingFooter from '@common/PagingFooter';
import EmptyListMessage from '@components/EmptyListMessage';
import Loader from '@components/Loader';
import useLoadBuildings from '@hooks/buildings';
import BuildingFilter from '@pages/Buildings/Filters';

import styles from '../../styles.module.scss';
import BuildingHeadRow from '../BuildingHeadRow';
import BuildingRow from '../BuildingRow';

interface Props {
  filtersOpen: boolean;
  userId: string;
  onCloseFilters(): void;
  onTableUpdate(): void;
}

const BuildingsTable: React.FC<Props> = ({
  filtersOpen,
  userId,
  onCloseFilters,
  onTableUpdate,
}) => {
  const {
    isLoading: isBuildingsLoading,
    data: buildingsData,
    onChangeParams,
    onChangeFilters,
  } = useLoadBuildings({
    userNames: [{ value: userId, label: undefined }],
    buildings: [],
    subscriptionStatus: [],
  });

  const onChangeBuildingsParams = useCallback(
    (params: PagingParams) => {
      onTableUpdate();
      onChangeParams(params);
    },
    [onTableUpdate, onChangeParams],
  );

  const data = useMemo(() => {
    if (isBuildingsLoading) {
      return <Loader />;
    }

    if (buildingsData?.records.length === 0) {
      return <EmptyListMessage message="There are no buildings" />;
    }

    const tableBody = buildingsData?.records.map((building) => (
      <BuildingRow
        key={`${building.id} - ${building.id}`}
        building={building}
      />
    ));

    return (
      <>
        <table className={styles.table}>
          <thead className={styles['table-head']}>
            <BuildingHeadRow />
          </thead>
          <tbody className={styles['table-body']}>{tableBody}</tbody>
        </table>
        <PagingFooter
          availablePerPage={[20, 40, 60]}
          isLoading={isBuildingsLoading}
          page={buildingsData?.meta.currentPage || 0}
          perPage={buildingsData?.meta.perPage || 0}
          totalPages={buildingsData?.meta.lastPage || 0}
          onChange={onChangeBuildingsParams}
        />
      </>
    );
  }, [buildingsData, isBuildingsLoading, onChangeBuildingsParams]);

  return (
    <>
      <div>{data}</div>
      <BuildingFilter
        hideUserSelect
        isOpen={filtersOpen}
        isLoading={isBuildingsLoading}
        close={onCloseFilters}
        onChangeFilters={onChangeFilters}
      />
    </>
  );
};

export default BuildingsTable;
