import React, { Dispatch, SetStateAction } from 'react';
import styles from '../styles.module.scss';
import Modal from '@components/Modal';
import CloseIcon from '@assets/close-circle.svg';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import QuestionItem from './QuestionItem';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Props {
  violation: ViolationDetails | null;
  open: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
}

const QuizModal: React.FC<Props> = ({ open, violation, setModalState }) => {
  const dismissedDefencePercent = violation
    ? (violation.codeTotal / 100) * violation.codeDismissedCounter
    : 0;
  const dismissedPercent = violation
    ? (violation.codeTotal / 100) * violation.codeDismissedCounter
    : 0;
  return (
    <Modal open={open} coverScreen={false}>
      <div className={styles['quiz-modal']} />
      <div className={styles['quiz-modal-container']}>
        <div className={styles['quiz-modal-content']}>
          <div className={styles['quiz-header']}>
            <ReactSVG
              src={CloseIcon}
              onClick={() => setModalState(false)}
              className={clsx('svg', styles['close-button'])}
            />
            <div className={styles['content']}>
              <div className={styles['progress']}>
                <div className={styles['circle-progress']}>
                  <CircularProgressbarWithChildren
                    value={100}
                    strokeWidth={8}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      trailColor: 'transparent',
                      pathColor: '#ebeffc',
                    })}
                  >
                    <div style={{ width: '84%' }}>
                      <CircularProgressbar
                        value={dismissedPercent}
                        text={`${dismissedPercent}%`}
                        styles={buildStyles({
                          pathColor: '#4E68EE',
                          trailColor: 'transparent',
                          strokeLinecap: 'butt',
                          rotation: 1 / 4,
                          textColor: '#000',
                        })}
                      />
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div>Dismissed cases with this defence</div>
                <div className={styles['circle-progress']}>
                  <CircularProgressbarWithChildren
                    value={100}
                    strokeWidth={8}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      trailColor: 'transparent',
                      pathColor: '#ebeffc',
                    })}
                  >
                    <div style={{ width: '84%' }}>
                      <CircularProgressbar
                        value={dismissedDefencePercent}
                        text={`${dismissedDefencePercent}%`}
                        styles={buildStyles({
                          trailColor: 'transparent',
                          textColor: '#000',
                          pathColor: '#56BB65',
                          strokeLinecap: 'butt',
                          rotation: 1 / 4,
                        })}
                      />
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div>Total dismissed cases with this code</div>
              </div>
              <div className={styles['links']}>
                <a href={violation?.formBeforeSubmission || ''}>
                  View page before submission
                </a>
                <a href={violation?.hearingSummaryUrl || ''}>
                  View page after submission
                </a>
              </div>
            </div>
          </div>
          <div className={styles['quiz-body']}>
            {violation && violation.submittedQuiz ? (
              violation.submittedQuiz.map((question, index) => (
                <QuestionItem key={index} question={question} />
              ))
            ) : (
              <p>Quiz not available for this violation</p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuizModal;
