import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';

const View: React.FC = () => (
  <div className={styles.container}>
    <h1 className={clsx('black-text', styles.title)}>
      You’re not authorized to access this page
    </h1>
    <h2>Redirecting to the login page...</h2>
  </div>
);

export default View;
