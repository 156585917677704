import { chunkStr } from './utils'

export const getAmount = (balance: string | number | undefined | null) => {
  if (balance === undefined || balance === null) {
    return 0
  }

  if (typeof balance === 'string') {
    return Number(balance.replace(/[^\d.,]/g, ''))
  }

  return balance
}

export const formatAmount = (
  balance: string | number | undefined | null,
  digits = 2,
  prefix = '$'
) => {
  if (balance === undefined || balance === null) {
    return undefined
  }

  balance = getAmount(balance)

  if (Number.isNaN(balance)) {
    return undefined
  }

  balance = balance.toFixed(digits)

  return `${prefix}${balance}`
}

export const formatAmountK = (
  balance: string | number | undefined | null,
  prefix = '$',
  delimiter = ', '
) => {
  if (balance === undefined || balance === null) {
    return undefined
  }

  balance = getAmount(balance)

  if (Number.isNaN(balance)) {
    return undefined
  }

  balance = chunkStr(String(balance), 3, delimiter, true)

  return `${prefix}${balance}`
}

export default {
  getAmount,
  formatAmount,
  formatAmountK,
}
