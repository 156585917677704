import React from 'react';

import Link from '@components/Link';
import Paper from '@components/Paper';
import links from '@routes/links';

import styles from '../../styles.module.scss';

interface Props {
  item: InfractionCode;
}

const addQuizClassSelector = (item: InfractionCode) =>
  item.defencesNumber || item.quizzesNumber ? 'button' : 'button-clickbait';

const PaperItem: React.FC<Props> = ({ item }) => {
  return (
    <Paper>
      <div className={styles['defences-item']}>
        <div className={styles.heading}>
          <h2>{item.code}</h2>
          <div className={styles.penalty}>
            PENALTY
            <p className={styles.bold}>{item.penalty}</p>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.content}>
          <p>{item.description}</p>
          <div className={styles['management-panel']}>
            <div className={styles['management-panel-info']}>
              <p className={styles.row}>
                <span className={styles.bold}>{item.quizzesNumber} </span>
                quizes
              </p>
              {!item.defencesNumber && (
                <div className={styles['def-info-clickbait']} />
              )}
              {!!item.defencesNumber && (
                <p className={styles.row}>
                  <span className={styles['bold-active']}>
                    {item.defencesNumber}
                  </span>
                  def
                </p>
              )}
            </div>
            <Link to={links.defenceQuizzes(item.id)}>
              <button
                className={styles[addQuizClassSelector(item)]}
                type="button"
              >
                {item.defencesNumber || item.quizzesNumber
                  ? 'View Defense'
                  : 'Add Defense'}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default PaperItem;
