import clsx from 'clsx';
import { FieldArray, FormikProps, FormikProvider } from 'formik';
import React, { memo } from 'react';
import { ReactSVG } from 'react-svg';

import AddButton from '@assets/add-circle.svg';
import CloseIcon from '@assets/close-circle.svg';
import Modal from '@components/Modal';
import { question as emptyQuestion } from '@pages/DefenceQuizzes';
import QuestionItem from '@pages/DefenceQuizzes/QuizModal/QuestionItem';

import { isNotNewQuiz } from '../helpers';
import styles from '../styles.module.scss';

interface Props {
  currentQuiz: Quiz | NewQuiz | null;
  formik: FormikProps<Quiz | NewQuiz>;
  open: boolean;
  onRequestClose(): void;
}

const isFirstQuestionValid = (question: Question) => {
  const [firstAnswer, secondAnswer] = question.answers;

  return (
    question.question.length > 0 &&
    firstAnswer.length > 0 &&
    secondAnswer.length > 0
  );
};

const QuizModal: React.FC<Props> = ({
  onRequestClose,
  open,
  formik,
  currentQuiz,
}) => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  } = formik;

  return (
    <Modal open={open} onRequestClose={onRequestClose} coverScreen={false}>
      <div
        onClick={onRequestClose}
        onKeyPress={onRequestClose}
        role="button"
        className={styles['quiz-modal']}
        tabIndex={0}
      />
      <div className={styles['quiz-modal-container']}>
        <form
          className={clsx(styles['quiz-modal-content'], {
            [styles['without-questions']]:
              isNotNewQuiz(currentQuiz) && !currentQuiz.with_questions,
          })}
          onSubmit={handleSubmit}
        >
          <div className={styles['quiz-header']}>
            <ReactSVG
              onClick={onRequestClose}
              src={CloseIcon}
              className={clsx('svg', styles['close-button'])}
            />
            <div
              className={clsx(styles.content, {
                [styles.errored]: errors.name && touched.name,
              })}
            >
              <span>Defense name</span>
              <input
                name="name"
                id="quiz-name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name && (
                <span className={styles.error}>{errors.name}</span>
              )}
            </div>
          </div>
          <div className={styles['quiz-body']}>
            <FormikProvider value={formik}>
              <FieldArray
                name="questions"
                render={({ push, remove }) => (
                  <>
                    {values.questions &&
                      values.questions.length > 0 &&
                      values.questions.map((question, index) => (
                        <QuestionItem
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          questionIndex={index}
                          question={question}
                          handleChange={handleChange}
                          remove={remove}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          showRemoveButton={(values.questions?.length || 0) > 1}
                        />
                      ))}
                    {!values.questions ||
                      (values.questions.length < 5 &&
                        isFirstQuestionValid(
                          values.questions[values.questions.length - 1],
                        ) && (
                          <button
                            onClick={() => push(emptyQuestion)}
                            className={styles['add-button']}
                            type="button"
                          >
                            <ReactSVG
                              src={AddButton}
                              className={clsx('svg', styles.logo)}
                            />
                          </button>
                        ))}
                  </>
                )}
              />
            </FormikProvider>
            <button type="submit" className={styles['save-button']}>
              Save
            </button>
            <button
              type="button"
              onClick={onRequestClose}
              className={styles['cancel-button']}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default memo(QuizModal);
