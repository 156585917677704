import withUser, { WithUserUpdate } from '@hocs/withUser';
import links from '@routes/links';
import { UserRole } from '@typings/enums';

const adminRoles = [UserRole.superAdmin, UserRole.admin];

const authedUpdate: WithUserUpdate = (user, { history }) => {
  if (!user) {
    history.replace(links.login());

    return;
  }

  if (!user.roles.some((role) => adminRoles.includes(role))) {
    history.replace(links.noAccess());
  }
};

const notAuthedUpdate: WithUserUpdate = (user, { history }) => {
  if (!user) return;

  if (!user.roles.some((role) => adminRoles.includes(role))) {
    history.replace(links.noAccess());
  }

  history.replace(links.home());
};

export const authed = withUser(authedUpdate);

export const notAuthed = withUser(notAuthedUpdate);

export default {
  authed,
  notAuthed,
};
