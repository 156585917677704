enum ReasonableExcuseType {
  notReceive = 'Did not receive',
  generalTitle = 'General title',
  respondentDied = 'Respondent died',
  legallyIncompetent = 'Legally incompetent',
  formerOwner = 'Former owner',
  neverOwned = 'Never owned',
  formerAgent = 'Former agent',
  emergency = 'Emergency',
  other = 'Other',
}

export default ReasonableExcuseType;
