import { defaultPerPage } from '@config';
import { FeedbackQuestionGroup } from '@typings/enums';

import ActionType, { Action } from './types';

export interface State {
  error: ErrorType | null;
  feedbackParams: FeedbacksParams;
  feedbacks: Feedback[];
  isLoadingFeedbacks: boolean;
  isLoadingQuestions: boolean;
  isLoadingStats: boolean;
  questionParams: FeedbackQuestionsParams;
  questions: FeedbackQuestion[];
  stats: FeedbackStats | null;
  statsParams: FeedbackStatsParams;
}

const initialState: State = {
  isLoadingQuestions: false,
  questions: [],
  questionParams: {
    page: 0,
    per_page: defaultPerPage,
  },

  isLoadingFeedbacks: false,
  feedbacks: [],
  feedbackParams: {
    page: 0,
    per_page: defaultPerPage,
  },

  isLoadingStats: false,
  stats: null,
  statsParams: {
    page: 0,
    per_page: defaultPerPage,
    group: FeedbackQuestionGroup.dismissed,
    feedbackQuestionId: 0,
  },

  error: null,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.LOADING: {
      return {
        ...state,
        isLoadingQuestions: action.payload,
        isLoadingFeedbacks: action.payload,
      };
    }

    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }

    case ActionType.LOAD_QUESTIONS_BEGIN: {
      return {
        ...state,
        isLoadingQuestions: true,
      };
    }

    case ActionType.LOAD_FEEDBACKS_BEGIN: {
      return {
        ...state,
        isLoadingFeedbacks: true,
      };
    }

    case ActionType.LOAD_STATS_BEGIN: {
      return {
        ...state,
        isLoadingStats: true,
      };
    }

    case ActionType.LOAD_QUESTIONS_SUCCESS: {
      const { questions, params } = action.payload;

      return {
        ...state,
        questions,
        questionParams: {
          ...state.questionParams,
          ...params,
        },
        isLoadingQuestions: false,
      };
    }

    case ActionType.LOAD_FEEDBACKS_SUCCESS: {
      const { feedbacks, params } = action.payload;

      return {
        ...state,
        feedbacks,
        feedbackParams: {
          ...state.feedbackParams,
          ...params,
        },
        isLoadingFeedbacks: false,
      };
    }

    case ActionType.LOAD_STATS_SUCCESS: {
      const { stats, params } = action.payload;

      return {
        ...state,
        stats,
        statsParams: {
          ...state.statsParams,
          ...params,
        },
        isLoadingStats: false,
      };
    }

    case ActionType.ERROR:
    case ActionType.LOAD_QUESTIONS_ERROR:
    case ActionType.LOAD_FEEDBACKS_ERROR:
    case ActionType.LOAD_STATS_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoadingFeedbacks: false,
        isLoadingQuestions: false,
        isLoadingStats: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
