enum ViolationType {
  hired = 'hired',
  unhired = 'unhired',
  attended = 'attended',
  new = 'new',
  pending = 'pending',
  closed = 'closed',
  dismissed = 'dismissed',
  lost = 'lost',
  defaulted = 'defaulted',
  unpaid = 'unpaid',
}

const ViolationTypeLabel = {
  [ViolationType.hired]: 'Hired',
  [ViolationType.unhired]: 'Unhired',
  [ViolationType.attended]: 'Attended',
  [ViolationType.new]: 'New',
  [ViolationType.pending]: 'Pending',
  [ViolationType.closed]: 'Closed',
  [ViolationType.dismissed]: 'Dismissed',
  [ViolationType.lost]: 'Lost',
  [ViolationType.defaulted]: 'Defaulted',
  [ViolationType.unpaid]: 'Unpaid',
};

enum ViolationDefenseQuiz {
  all = 'all',
  notAutomated = 'notAutomated',
  notAnswered = 'notAnswered',
  processed = 'processed',
}

const ViolationDefenseQuizLabel = {
  [ViolationDefenseQuiz.all]: 'All',
  [ViolationDefenseQuiz.notAnswered]: 'Not answered',
  [ViolationDefenseQuiz.notAutomated]: 'Not automated',
  [ViolationDefenseQuiz.processed]: 'Processed',
};

enum ViolationSortType {
  respondent = 'respondent_name',
  hearingDate = 'hearing_date',
  balance = 'balance_due',
  status = 'paid',
}

enum ViolationGroupType {
  buildingAddress = 'building.address',
  hearingDate = 'hearing_date',
}

const violationsFilterTypes: ViolationFilterType[] = [
  'buildings_ids',
  'infraction_codes',
  'hearing_date_start',
  'hearing_date_end',
  'hearing_results',
  'case_statuses',
  'payment_status',
  'payment_type',
  'search',
  'user_id',
  'defence_quiz',
];

const violationsInfCodesFilterTypes: ViolationInfractionCodesFilterType[] = [
  'search',
];

enum ViolationDefenseType {
  defaulted = 'Request to reopen defaulted',
  ecbHearing = 'ECB online hearing form',
  defended = 'Defensed',
}

const ViolationDefenseClassName = {
  [ViolationDefenseType.defaulted]: 'defaulted',
  [ViolationDefenseType.ecbHearing]: 'ecb-hearing',
  [ViolationDefenseType.defended]: 'defended',
};

const violationDetailsKeys: (keyof ViolationDetails)[] = [
  'address',
  'balance',
  'caseStatus',
  'closed',
  'defence',
  'defenceType',
  'description',
  'dismissalFee',
  'faceAmount',
  'hearingDate',
  'hearingStatus',
  'hired',
  'hiredByOtherUser',
  'id',
  'infractionCode',
  'inspectionLocation',
  'issuingAgency',
  'notes',
  'offenseDate',
  'paid',
  'respondent',
  'respondentAddress',
  'respondentAddressBorough',
  'respondentAddressCity',
  'respondentAddressHouse',
  'respondentAddressStateName',
  'respondentAddressStreetName',
  'respondentAddressZipCode',
  'respondentEmail',
  'respondentFirstName',
  'respondentLastName',
  'respondentPhone',
  'saniSaverFee',
  'screenDetail',
  'standartDescription',
  'ticketImageLink',
  'ticketNumber',
  'violationStatus',
  'won',
];

export {
  ViolationSortType,
  ViolationGroupType,
  violationsFilterTypes,
  ViolationTypeLabel,
  violationsInfCodesFilterTypes,
  violationDetailsKeys,
  ViolationType,
  ViolationDefenseType,
  ViolationDefenseClassName,
  ViolationDefenseQuiz,
  ViolationDefenseQuizLabel,
};
