import { useMemo } from 'react';
import { FormikHelpers, useFormik } from 'formik';

import FormData from './form';

import GeneralInfoFormData, {
  formInitial as generalInfoFormInitial,
  formKeys as generalInfoFormKeys,
  validateForm as generalInfoValidate,
} from './GeneralInfo/form';
import DecisionAddressFormData, {
  formInitial as decisionAddressFormInitial,
  formKeys as decisionAddressFormKeys,
  validateForm as decisionAddressValidate,
} from './DecisionAddress/form';
import DefenseFormData, {
  formInitial as defenseFormInitial,
  formKeys as defenseFormKeys,
  validateForm as defenseValidate,
} from './Defense/form';
import FilesFormData, {
  formInitial as filesFormInitial,
  formKeys as filesFormKeys,
  validateForm as filesValidate,
} from './Files/form';

import { pick, pickValues } from '@services/utils';
import { getFormErrors } from '@services/error';
import { toast } from 'react-toastify';

interface Form extends Formik<FormData> {
  decisionAddressForm: DecisionAddressFormData;
  defenceForm: DefenseFormData;
  filesForm: FilesFormData;
  generalInfoForm: GeneralInfoFormData;
}

const useForm = (
  violation: ViolationDetails | null,
  user: WebUser | null,
  onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void,
): Form => {
  const formik = useFormik<FormData>({
    initialValues: {
      ...generalInfoFormInitial(violation, user),
      ...decisionAddressFormInitial(user),
      ...defenseFormInitial,
      ...filesFormInitial,
    },
    onSubmit: async (
      values: FormData,
      formikHelpers: FormikHelpers<FormData>,
    ) => {
      const formErrors = getFormErrors<FormData>({
        ...generalInfoValidate(values),
        ...decisionAddressValidate(values),
        ...defenseValidate(values),
        ...filesValidate(values),
      });

      if (
        formErrors &&
        Object.keys(formErrors).length !== 0 &&
        formErrors.constructor === Object
      ) {
        formikHelpers.setErrors(formErrors);

        return toast('Please fill all required fields');
      }

      onSubmit(values, formikHelpers);
    },
  });

  const { values } = formik;

  const generalInfoForm = useMemo(
    () => pick(values, generalInfoFormKeys) as GeneralInfoFormData,
    pickValues(values, generalInfoFormKeys),
  );

  const decisionAddressForm = useMemo(
    () => pick(values, decisionAddressFormKeys) as DecisionAddressFormData,
    pickValues(values, decisionAddressFormKeys),
  );

  const defenceForm = useMemo(
    () => pick(values, defenseFormKeys) as DefenseFormData,
    pickValues(values, defenseFormKeys),
  );

  const filesForm = useMemo(
    () => pick(values, filesFormKeys) as FilesFormData,
    pickValues(values, filesFormKeys),
  );

  return {
    ...formik,
    generalInfoForm,
    decisionAddressForm,
    defenceForm,
    filesForm,
  };
};

export default useForm;
