import React from 'react'
import clsx from 'clsx'

import UserHeadInfo from '@common/UserHeadInfo'

import styles from '../styles.module.scss'

interface Props {
  violation: ViolationDetails
  goBack?: () => void
}

const HeadInfo: React.FC<Props> = ({
  violation: { respondent, respondentEmail, respondentPhone },
  goBack,
}) => (
  <UserHeadInfo
    className={clsx(styles['section'], styles['head-info'])}
    goBack={goBack}
    firstName={respondent?.toLowerCase()}
    email={respondentEmail}
    phone={respondentPhone}
  />
)

export default HeadInfo
