import React from 'react';

import PaperItem from '@pages/Defences/InfractionCodesList/InfractionCodePaper';

import styles from '../styles.module.scss';

interface Props {
  items: InfractionCode[];
}

const List: React.FC<Props> = ({ items }) => {
  return (
    <div className={styles['defences-list']}>
      {items.map((item) => (
        <PaperItem key={item.id} item={item} />
      ))}
    </div>
  );
};

export default List;
