import {
  BalanceLabel,
  PaidStatusType,
  PaymentStatusType,
  paymentsFilterTypes,
} from '@typings/enums'

export const getAmountType = (payment: Payment) => {
  switch (payment.status) {
    case PaymentStatusType.successful_sanisaver: {
      return BalanceLabel.fee
    }

    case PaymentStatusType.successful_nyc:
    case PaymentStatusType.failed:

    default: {
      return BalanceLabel.fine
    }
  }
}

export const getStatusByType = (status: PaymentStatusType) => {
  switch (status) {
    case PaymentStatusType.successful_sanisaver: {
      return PaidStatusType.paid
    }

    case PaymentStatusType.successful_nyc: {
      return PaidStatusType.unpaid
    }

    case PaymentStatusType.failed:

    default: {
      return PaidStatusType.unpaidFee
    }
  }
}

export const getStatusType = (payment: Payment) =>
  getStatusByType(payment.status)

export const emptySort: PaymentSortInfo = {
  order_by: undefined,
  order_by_direction: undefined,
}

export const emptyFilter: ViolationFilters = paymentsFilterTypes.reduce(
  (acc, filter) => ({
    ...acc,
    [filter]: undefined,
  }),
  {}
)

export default {
  getAmountType,
  getStatusByType,
  getStatusType,
  emptySort,
  emptyFilter,
}
