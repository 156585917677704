/* eslint-disable no-param-reassign */
import { Action, combineReducers } from 'redux';

import buildings from '@redux/buildings';
import feedbacks from '@redux/feedbacks';
import infractionCodes from '@redux/infractionCodes';
import payments from '@redux/payments';
import user from '@redux/user';
import users from '@redux/users';
import violations from '@redux/violations';
import { isPrimitive } from '@services/utils';

import ActionType from './types';

const rootReducer = combineReducers({
  user,
  violations,
  payments,
  users,
  buildings,
  infractionCodes,
  feedbacks,
});

const reducer = (state: ReduxState | undefined, action: AnyAction) => {
  switch (action.type) {
    case ActionType.RESET: {
      state = undefined;

      break;
    }

    case ActionType.UPDATE:
      if (action.payload.path.length > 0) {
        let target: Record<string, unknown> = state as Record<string, unknown>;

        action.payload.path.slice(0, -1).forEach((key) => {
          if (!target[key] || isPrimitive(target[key])) {
            target[key] = {};
          } else {
            target = target[key] as Record<string, unknown>;
          }
        });

        target[action.payload.path[action.payload.path.length - 1]] =
          action.payload.value;
      }

      break;

    default:
  }

  return rootReducer(state, action as Action);
};

export { rootReducer };

export default reducer;
