import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import userActions from '@redux/user/actions';
import links from '@routes/links';

import View from './view';

const REDIRECT_DELAY = 4000;

const mapStateToProps = (state: ReduxState) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  userActions: bindActionCreators<
    typeof userActions,
    BindedAsyncActions<typeof userActions>
  >(userActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type NativeProps = RouteComponentProps;

type Props = NativeProps & ConnectedProps<typeof connector>;

const NoAccess: React.FC<Props> = ({
  user,
  userActions: { logout },
  history: { replace },
}) => {
  useEffect(() => {
    if (user) {
      logout();
    }

    const timeout = setTimeout(() => {
      replace(links.login());
    }, REDIRECT_DELAY);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <View />;
};

export default connector(NoAccess) as React.FC<NativeProps>;
