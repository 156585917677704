import clsx from 'clsx';
import React, { useMemo } from 'react';

import CaseStatus from '@components/CaseStatus';
import DefenseQuiz from '@components/DefenseQuiz';
import HearingStatus from '@components/HearingStatus';
import Link from '@components/Link';
import Status from '@components/Status';
import links from '@routes/links';
import { formatDate } from '@services/date';
import { formatAmount } from '@services/money';
import { getStatusType } from '@services/violation';

import styles from './styles.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  violation: Violation;
}

const ViolationRow: React.FC<Props> = ({ violation, className, ...props }) => {
  const { ticketNumber, id } = violation as HiredViolation;
  const infractionCode = useMemo(
    () =>
      'infractionCode' in violation ? violation.infractionCode : undefined,
    [violation],
  );
  const address = useMemo(() => violation.address?.toLowerCase(), [violation]);
  const respondent = useMemo(
    () =>
      'respondent' in violation
        ? violation.respondent?.toLowerCase()
        : undefined,
    [violation],
  );
  const hearingDate = useMemo(
    () =>
      'hearingDate' in violation
        ? formatDate(violation.hearingDate, '/')
        : undefined,
    [violation],
  );
  const hearingStatus = useMemo(
    () =>
      'hearingStatus' in violation ? (
        <HearingStatus
          type={violation.hearingStatus}
          className={styles['hearing-status']}
        />
      ) : (
        <div className={styles['hearing-status']} />
      ),
    [violation],
  );
  const caseStatus = useMemo(
    () =>
      'caseStatus' in violation ? (
        <CaseStatus
          type={violation.caseStatus}
          className={styles['case-status']}
        />
      ) : (
        <div className={styles['case-status']} />
      ),
    [violation],
  );
  const defenseQuiz = useMemo(
    () => (
      <DefenseQuiz
        type={violation.defenceQuiz}
        className={styles['case-status']}
      />
    ),
    [violation],
  );

  const balance = useMemo(
    () =>
      'balance' in violation ? formatAmount(violation.balance) : undefined,
    [violation],
  );

  const balanceType = useMemo(
    () => ('balanceType' in violation ? violation.balanceType : undefined),
    [violation],
  );

  const status = useMemo(
    () =>
      'paid' in violation ? (
        <Status type={getStatusType(violation)} className={styles.status} />
      ) : (
        <div className={styles.status} />
      ),
    [violation],
  );

  return (
    <tr {...props} className={clsx('tr', styles.row, className)}>
      <td>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={links.violationDetails(id)}
          className={clsx('blue-link', styles['ticket-number'])}
        >
          {ticketNumber}
        </Link>
      </td>
      <td>
        <p className={styles['infraction-code']}>{infractionCode}</p>
      </td>
      <td>
        <p className={styles.address} title={address}>
          {address}
        </p>
      </td>
      <td>
        <p className={styles.respondent}>{respondent}</p>
      </td>
      <td>
        <p className={styles['hearing-date']}>{hearingDate}</p>
      </td>
      <td>{hearingStatus}</td>
      {'caseStatus' in violation && <td>{caseStatus}</td>}
      <td>{defenseQuiz}</td>
      <td>
        <p className={styles.balance}>
          {balance}
          <span className={styles['balance-type']}>{balanceType}</span>
        </p>
      </td>
      <td>{status}</td>
    </tr>
  );
};

export default ViolationRow;
