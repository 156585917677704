import clsx from 'clsx';
import React from 'react';
import { ReactSVG } from 'react-svg';

import CalendarIcon from '@assets/calendar.svg';
import SubscriptionStatus from '@pages/Buildings/building-row/subscription-status';

import styles from './styles.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  building: Building;
}

const BuildingRow: React.FC<Props> = ({ building, className, ...props }) => {
  const { address, users } = building;
  const { subscription, violations_stats } = users[0];

  return (
    <tr {...props} className={clsx('tr', styles.row, className)}>
      <td>
        <p className={styles.address}>{address}</p>
      </td>
      <td>
        <div className={styles.status}>
          <SubscriptionStatus status={users[0].subscription.price_name} />
        </div>
      </td>
      <td>
        <div className={styles['additional-info']}>
          {subscription.subscription_ends_at && (
            <>
              <ReactSVG
                src={CalendarIcon}
                className={clsx('svg', styles.calendar)}
              />
              Active until<span>{subscription.subscription_ends_at}</span>
            </>
          )}
        </div>
      </td>
      <td>
        <p className={styles['hired-cases']}>
          <span>{violations_stats.hired} </span>/ {violations_stats.total}
        </p>
      </td>
    </tr>
  );
};

export default BuildingRow;
