import React, { useMemo } from 'react';
import clsx from 'clsx';

import SortButton from '@common/SortButton';

import { ViolationType } from '@typings/enums';

import styles from './styles.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  type: ViolationType;
  onRespondentClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onHearingDateClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onBalanceClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onStatusClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

const ViolationHeadRow: React.FC<Props> = ({
  type,
  onRespondentClick,
  onHearingDateClick,
  onBalanceClick,
  onStatusClick,
  className,
  ...props
}) => {
  const isNotAttended = useMemo(
    () =>
      [
        ViolationType.hired,
        ViolationType.closed,
        ViolationType.unhired,
      ].includes(type),
    [type],
  );

  const hearingDate = useMemo(
    () => (
      <SortButton
        className={styles['hearing-date']}
        onClick={onHearingDateClick}
      >
        {'Hearing date'}
      </SortButton>
    ),
    [onHearingDateClick],
  );

  const caseStatus = useMemo(
    () => (
      <p className={styles['case-status']}>
        {isNotAttended ? 'Case status' : ''}
      </p>
    ),
    [type],
  );

  const balance = useMemo(
    () => (
      <SortButton className={styles['balance']} onClick={onBalanceClick}>
        {'Amount/Type'}
      </SortButton>
    ),
    [type],
  );

  const status = useMemo(
    () =>
      [ViolationType.closed].includes(type) ? (
        <SortButton className={styles['status']} onClick={onStatusClick}>
          {'Status'}
        </SortButton>
      ) : (
        <div className={styles['status']} />
      ),
    [type],
  );

  return (
    <tr {...props} className={clsx('tr', styles['head-row'], className)}>
      <td>
        <p className={styles['ticket-number']}>{'Violation #'}</p>
      </td>
      <td>
        <p className={styles['infraction-code']}>{'Inf. code'}</p>
      </td>
      <td>
        <p className={styles['address']}>{'Property'}</p>
      </td>
      <td>
        <SortButton
          className={styles['respondent']}
          onClick={onRespondentClick}
        >
          {'Respondent'}
        </SortButton>
      </td>
      <td>{hearingDate}</td>
      <td>
        <p className={styles['hearing-status']}>{'Hearing status'}</p>
      </td>
      {isNotAttended && <td>{caseStatus}</td>}
      <td>
        <p className={styles['hearing-status']}>{'defense/quiz'}</p>
      </td>
      <td>{balance}</td>
      <td>{status}</td>
    </tr>
  );
};

export default ViolationHeadRow;
