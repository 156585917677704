import { FormikErrors } from 'formik';
import { ValueType, getSelectValue } from '@components/Select';
import {
  emailValidate,
  requiredValidate,
  validate,
} from '@services/validation';
import { State, StateLabel } from '@typings/enums';
import city5 from '@constants/city5';

interface FormData {
  address: string;
  apartment_number: string;
  city: string;
  state: ValueType<State> | undefined;
  zip_code: string | number;
  phone: string;
  email: string;
}

export const formInitial = (user: WebUser | null): FormData => ({
  address: city5.address,
  apartment_number: city5.apartmentNumber,
  city: city5.city,
  state: { value: city5.state, label: StateLabel[city5.state] },
  zip_code: city5.zipCode,
  phone: city5.phone,
  email: user?.email || city5.email,
});

export const validateForm = ({
  address,
  apartment_number,
  city,
  state,
  zip_code,
  phone,
  email,
}: FormData): FormikErrors<FormData> => ({
  address: validate(address, requiredValidate),
  apartment_number: validate(apartment_number, requiredValidate),
  city: validate(city, requiredValidate),
  state: validate(getSelectValue(state), requiredValidate),
  zip_code: validate(zip_code, requiredValidate),
  phone: validate(phone, requiredValidate),
  email: validate(email, requiredValidate, emailValidate),
});

export const formKeys: (keyof FormData)[] = [
  'address',
  'apartment_number',
  'city',
  'state',
  'zip_code',
  'phone',
  'email',
];

export default FormData;
