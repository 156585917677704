export enum ReasonForGranted {
  firstRequest = 'First Request',
  moreThan75Days = 'First Request - More than 75 days',
  moreThanOneYear = 'Filed more than 1 year or not first request',
}

interface RadioInfo {
  label: string;
  value: ReasonForGranted;
}

const radios: RadioInfo[] = [
  {
    value: ReasonForGranted.firstRequest,
    label: `This request is the first request AND it is being filed within 75 days from 
      the mailing date or hand delivery date of the Default Decision`,
  },
  {
    value: ReasonForGranted.moreThan75Days,
    label: `This request is the first request AND it is being filed more than 75 days 
      from the mailing date or hand delivery date of the Default Decision but within 
      1 year of the date of the Default Decision`,
  },
  {
    value: ReasonForGranted.moreThanOneYear,
    label: `This request is being filed more than 1 year from the date of the Default 
    Decision or this is not the first hearing date that the Respondent missed on this Notice/Summons number`,
  },
];

export default radios;
