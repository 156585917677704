import clsx from 'clsx';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ValueType } from 'react-select';
import { ReactSVG } from 'react-svg';

import BuildingIcon from '@assets/building.svg';
import CalendarIcon from '@assets/calendar.svg';
import ButtonToggle, { ToggleOption } from '@components/ButtonToggle';
import Select, { OptionType, getSelectValue } from '@components/Select';
import {
  ViolationGroupType,
  ViolationType,
  ViolationTypeLabel,
} from '@typings/enums';

import styles from '../styles.module.scss';

import BuildingsTable from './BuildingsTable';
import ViolationsTable from './ViolationsTable';

interface Props {
  userId: string;
}

const types: ViolationType[] = [
  ViolationType.hired,
  ViolationType.closed,
  ViolationType.attended,
  ViolationType.unhired,
];

const displayModes: ToggleOption[] = [
  { label: 'Violations', value: 'violations' },
  { label: 'Buildings', value: 'buildings' },
];

const groupByOptions: OptionType<ViolationGroupType>[] = [
  {
    value: ViolationGroupType.buildingAddress,
    label: (
      <>
        <ReactSVG
          src={BuildingIcon}
          className={clsx(
            'svg',
            styles['select-icon'],
            styles['building-icon'],
          )}
        />
        <p className={styles['option-text']}>Sort by Buildings</p>
      </>
    ),
  },
  {
    value: ViolationGroupType.hearingDate,
    label: (
      <>
        <ReactSVG
          src={CalendarIcon}
          className={clsx(
            'svg',
            styles['select-icon'],
            styles['calendar-icon'],
          )}
        />
        <p className={styles['option-text']}>Sort by Date</p>
      </>
    ),
  },
];

const TableSection: React.FC<Props> = ({ userId }) => {
  const [selectedMode, setSelectedMode] = useState<string | number>(
    'violations',
  );
  const [selectedType, setSelectedType] = useState(ViolationType.hired);
  const [groupBy, setGroupBy] = useState(ViolationGroupType.buildingAddress);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const openFilters = () => setFiltersOpen(true);
  const closeFilters = () => setFiltersOpen(false);

  const onToggleMode = (option: ToggleOption) => setSelectedMode(option.value);

  const tableSectionRef = useRef<HTMLElement | null>(null);

  const scrollToTop = () =>
    tableSectionRef.current?.scrollIntoView({ block: 'start' });

  const groupByValue = useMemo(
    () => groupByOptions.find((option) => option.value === groupBy),
    [groupBy],
  );

  const onGroupByValueChange = useCallback(
    (option: ValueType<OptionType<ViolationGroupType>>) => {
      const value = getSelectValue(option);

      if (value && !Array.isArray(value)) {
        setGroupBy(value);
      }
    },
    [setGroupBy],
  );

  const typeLinks = useMemo(
    () =>
      types.map((type) => (
        <li key={type} className={styles['link-item']}>
          <button
            type="button"
            className={clsx(
              styles.link,
              type === selectedType && styles['link-active'],
            )}
            onClick={() => setSelectedType(type)}
          >
            {ViolationTypeLabel[type]}
          </button>
        </li>
      )),
    [selectedType, setSelectedType],
  );

  return (
    <section className={styles['table-section']} ref={tableSectionRef}>
      <div className={styles.heading}>
        <div className={styles['group-by-container']}>
          <ButtonToggle
            options={displayModes}
            selected={selectedMode}
            onChange={onToggleMode}
          />
          {selectedMode === 'violations' && (
            <Select
              options={groupByOptions}
              small
              className={styles['group-by-select']}
              value={groupByValue}
              onChange={onGroupByValueChange}
            />
          )}
        </div>
        <nav className={styles.navigation}>
          {selectedMode === 'violations' && (
            <ul className={styles.links}>{typeLinks}</ul>
          )}
          <button
            type="button"
            className="primary-button"
            onClick={openFilters}
          >
            Filter
          </button>
        </nav>
      </div>
      {selectedMode === 'violations' ? (
        <ViolationsTable
          filtersOpen={filtersOpen}
          onCloseFilters={closeFilters}
          groupBy={groupBy}
          selectedType={selectedType}
          userId={userId}
        />
      ) : (
        <BuildingsTable
          filtersOpen={filtersOpen}
          onCloseFilters={closeFilters}
          userId={userId}
          onTableUpdate={scrollToTop}
        />
      )}
    </section>
  );
};

export default TableSection;
