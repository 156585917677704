// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useMemo } from 'react';

import PagingFooter from '@common/PagingFooter';
import EmptyListMessage from '@components/EmptyListMessage';
import Loader from '@components/Loader';
import { violationsAvailablePerPage } from '@config';
import { ViolationSortType, ViolationType } from '@typings/enums';

import ViolationHeadRow from '../ViolationHeadRow';
import ViolationRow from '../ViolationRow';

import styles from './styles.module.scss';

interface Props<Type extends Violation = Violation> {
  isLoading: boolean;
  page: number;
  perPage: number;
  totalPages: number;
  type: ViolationType;
  violations: Type[];
  load(params: PagingParams): void;
  sort(type: ViolationSortType | undefined): void;
}

const View = <Type extends Violation = Violation>({
  violations,
  sort,
  page,
  perPage,
  totalPages,
  type,
  load,
  isLoading,
}: Props<Type>) => {
  const tableBody = useMemo(
    () =>
      violations.map((violation) => (
        <ViolationRow
          key={`${violation.id}-${violation.ticketNumber}`}
          violation={violation}
        />
      )),
    [violations],
  );

  const renderList = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (violations.length === 0) {
      return <EmptyListMessage message="There are no violations" />;
    }

    return <tbody className={styles['table-body']}>{tableBody}</tbody>;
  };

  return (
    <div className={styles['table-container']}>
      <table className={styles.table}>
        <thead className={styles['table-head']}>
          <ViolationHeadRow
            type={type}
            onRespondentClick={() => {
              sort(ViolationSortType.respondent);
            }}
            onHearingDateClick={() => {
              sort(ViolationSortType.hearingDate);
            }}
            onBalanceClick={() => {
              sort(ViolationSortType.balance);
            }}
            onStatusClick={() => {
              sort(ViolationSortType.status);
            }}
          />
        </thead>
        {renderList()}
      </table>
      <PagingFooter
        availablePerPage={violationsAvailablePerPage}
        isLoading={isLoading}
        page={page}
        perPage={perPage}
        totalPages={totalPages}
        onChange={load}
      />
    </div>
  );
};

export default View;
