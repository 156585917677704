import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

import { getSelectValue } from '@components/Select';
import TabNav from '@components/TabNav';
import TabsFooter from '@components/TabsFooter';
import violationsActions from '@redux/violations/actions';
import links from '@routes/links';
import { toWordBoolean } from '@typings/enums';

import DecisionAddress from './DecisionAddress';
import Defense from './Defense';
import Files from './Files';
import GeneralInfo from './GeneralInfo';
import styles from './styles.module.scss';
import tabs, { TabValue, tabNavItems } from './tabs';
import useForm from './useForm';

const mapStateToProps = (state: ReduxState) => ({
  violation: state.violations.violation,
  isLoading: state.violations.isLoading,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface NativeProps {
  className?: string;
}

type Props = NativeProps & ConnectedProps<typeof connector>;

const Form: React.FC<Props> = ({
  className,
  violation,
  isLoading,
  user,
  violationsActions: { sendOnlineHearing },
}) => {
  const [tab, setTab] = useState<TabValue>(TabValue.generalInfo);

  const { replace } = useHistory();

  const {
    generalInfoForm,
    decisionAddressForm,
    defenceForm,
    filesForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
  } = useForm(
    violation,
    user,
    ({
      summons_number,
      case_type,
      first_name,
      last_name,
      does_name_appear_on_ticket,
      who_you_are,
      who_asked_you_to_make_request,
      persons_relationship_to_the_named_respondent,
      are_you_authorized_to_represent_respondent,
      phone,
      email,
      address,
      apartment_number,
      city,
      state,
      zip_code,
      why_summons_should_be_dismissed,
      files,
      certification,
    }) => {
      if (!violation || !Array.isArray(who_you_are)) {
        return;
      }

      sendOnlineHearing(
        {
          violation_id: String(violation.id),
          case_type: getSelectValue(case_type),
          summons_number,
          first_name,
          last_name,
          does_name_appear_on_ticket,
          who_you_are,
          who_asked_you_to_make_request,
          persons_relationship_to_the_named_respondent,
          are_you_authorized_to_represent_respondent,
          address,
          apartment_number,
          city,
          state: getSelectValue(state) || '',
          zip_code: String(zip_code),
          phone,
          email,
          why_summons_should_be_dismissed,
          ...files.reduce(
            (acc, file, index) => ({ ...acc, [`file${index + 1}`]: file }),
            {},
          ),
          certification: toWordBoolean(certification),
        },
        (res: string) => {
          toast.success(res, {
            autoClose: 2000,
            onClose: () => {
              replace(
                violation
                  ? links.violationDetails(violation.id)
                  : links.violations(),
              );
            },
          });
        },
      );
    },
  );

  const form = useMemo(() => {
    switch (tab) {
      case TabValue.generalInfo: {
        return (
          <GeneralInfo
            form={generalInfoForm}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            setFieldValue={setFieldValue}
          />
        );
      }

      case TabValue.addressToReceiveDecision: {
        return (
          <DecisionAddress
            form={decisionAddressForm}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            setFieldValue={setFieldValue}
          />
        );
      }

      case TabValue.defense: {
        return (
          <Defense
            form={defenceForm}
            errors={errors}
            touched={touched}
            onChange={handleChange}
          />
        );
      }

      case TabValue.files: {
        return (
          <Files
            form={filesForm}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            violation={violation}
          />
        );
      }

      default: {
        return null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tab,
    handleChange,
    setFieldValue,
    generalInfoForm,
    decisionAddressForm,
    defenceForm,
    filesForm,
    violation,
  ]);

  const cancel = useCallback(
    () => violation && replace(links.violationDetails(violation?.id)),
    [violation, replace],
  );

  return (
    <div className={clsx(styles['form-container'], className)}>
      <TabNav
        tabs={tabNavItems}
        value={tab}
        setValue={setTab}
        className={styles['tab-nav']}
      />
      <form onSubmit={handleSubmit} className={styles.form}>
        {form}
        <TabsFooter
          tabs={tabs}
          tab={tab}
          setTab={setTab}
          cancel={cancel}
          submitDisabled={isLoading}
        />
      </form>
    </div>
  );
};

export default connector(Form) as React.FC<NativeProps>;
