import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useMemo } from 'react';

import Link from '@components/Link';
import links from '@routes/links';
import { openNewHTML } from '@services/dom';
import {
  CaseStatusLabel,
  CaseStatusType,
  ViolationDefenseClassName,
  ViolationDefenseType,
} from '@typings/enums';

import styles from '../styles.module.scss';

interface Props {
  caseStatus: CaseStatusType;
  defense: string | null | undefined;
  defenseType: ViolationDefenseType | null;
  setModalState: Dispatch<SetStateAction<boolean>>;
  violationID: ApiID;
}

const TopSection: React.FC<Props> = ({
  defenseType,
  defense,
  caseStatus,
  violationID,
  setModalState,
}) => {
  const toShowReopenForm = caseStatus === CaseStatusType.pendingMotion;

  const content = useMemo(() => {
    const notPendingHearing = caseStatus !== CaseStatusType.pendingHearing;

    switch (defenseType) {
      case ViolationDefenseType.ecbHearing: {
        return (
          <>
            <div className={styles.text}>
              <p className={styles.title}>ECB online hearing</p>
              <p className={styles.subtitle}>
                Need to Send ECB Online Hearing Form
              </p>
            </div>
            <div className={styles['progress-container']}>
              <p className={styles['progress-text']}>1/2</p>
              <progress value={1} max={2} className={styles.progress} />
            </div>
            <Link
              to={links.violationHearingForm(violationID)}
              className="primary-button"
              title={
                notPendingHearing ? CaseStatusLabel[caseStatus] : undefined
              }
              disabled={notPendingHearing}
            >
              Attend Hearing
            </Link>
          </>
        );
      }

      case ViolationDefenseType.defended: {
        const showDefence = () => {
          if (defense) {
            openNewHTML(defense);
          }
        };

        return (
          <button
            className={clsx('primary-button')}
            onClick={() => setModalState(true)}
            onDoubleClick={showDefence}
            type="button"
          >
            View Defense
          </button>
        );
      }

      default: {
        return null;
      }
    }
  }, [defenseType, caseStatus, defense]);

  return (
    <section
      className={clsx(
        styles.section,
        styles['top-section'],
        defenseType && styles[ViolationDefenseClassName[defenseType]],
      )}
    >
      {content}
      {toShowReopenForm && (
        <Link
          to={links.violationRequestReopen(violationID)}
          className={clsx('primary-button', styles['reopen-button'])}
        >
          Restore Violation
        </Link>
      )}
    </section>
  );
};

export default TopSection;
