import { FormAuthorRoleLabel, formAuthorRoleTypes } from '@typings/enums';

interface RadioInfo<R extends string = string> {
  label: string;
  value: R;
}

const authorRoleRadios: RadioInfo<
  FormAuthorRoleType
>[] = formAuthorRoleTypes.map((authorRole) => ({
  value: authorRole,
  label: FormAuthorRoleLabel[authorRole],
}));

export default authorRoleRadios;
