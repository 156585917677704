import clsx from 'clsx';
import React from 'react';
import { ReactSVG } from 'react-svg';

import ArrowIcon from '@assets/calendar.svg';
import Link from '@components/Link';
import links from '@routes/links';

import styles from './styles.module.scss';
import SubscriptionStatus from './subscription-status';

interface Props {
  user: BuildingUser;
}

const InnerRow: React.FC<Props> = ({ user }) => {
  const { name, violations_stats, subscription } = user;

  return (
    <div className={clsx(styles.userRow, styles['user-info'])}>
      <div className={styles.userName}>
        <Link className="blue-link" to={links.userDetails(user.id)}>
          {name}
        </Link>
      </div>
      <div className={styles.cell}>
        <SubscriptionStatus status={subscription.price_name} />
      </div>
      <div>
        {subscription.subscription_ends_at && (
          <div className={styles['ends-at']}>
            <ReactSVG
              src={ArrowIcon}
              className={clsx('svg', styles.calendar)}
            />
            Active until<span>{subscription.subscription_ends_at}</span>
          </div>
        )}
      </div>
      <div className={styles.hiredCases}>
        <span>{violations_stats.hired} </span>/ {violations_stats.total}
      </div>
    </div>
  );
};

export default InnerRow;
