import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import infractionCodesActions from '@redux/infractionCodes/actions';
import links from '@routes/links';
import { isId } from '@services/utils';

import {
  isEmptyQuestion,
  isNotNewQuiz,
  validateQuestions,
  validationSchema,
} from './helpers';
import View from './view';

interface NativeProps {
  id: string;
  goBack(): void;
  replace(path: string): void;
}

type Props = NativeProps & RouteComponentProps<{ id: string }>;

export const question: Question = {
  question: '',
  orderNumber: 1,
  answers: ['', ''],
};

const questions: Question[] = [question];

const DefenceQuizzes: React.FC<Props> = ({
  match: {
    params: { id },
  },
  history: { replace, goBack },
}) => {
  const { infractionCodeDetails: infractionCode } = useSelector(
    (state: ReduxState) => state.infractionCodes,
  );

  const [modelOpened, setModalOpened] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState<Quiz | NewQuiz | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isId(id)) {
      dispatch(infractionCodesActions.loadDetails(Number(id)));
    } else {
      replace(links.home());
    }
  }, [dispatch, id, replace]);

  useEffect(() => {
    const quiz = infractionCode?.quizzes[0] || null;

    setCurrentQuiz(quiz);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infractionCode?.quizzes.length]);

  const handleNewQuiz = () => {
    setCurrentQuiz(null);
    setModalOpened(true);
  };

  const handleCopyQuiz = (quiz: Quiz) => {
    const quizToCopy: NewQuiz = (Object.keys(quiz) as Array<keyof Quiz>).reduce(
      (acc, key) =>
        key === 'id'
          ? acc
          : {
              ...acc,
              [key]: quiz[key],
            },
      {} as NewQuiz,
    );

    setCurrentQuiz(quizToCopy);
    setModalOpened(true);
  };

  const handleDefenceChange = (defence: string) => {
    if (currentQuiz) {
      setCurrentQuiz({ ...currentQuiz, defence });
    }
  };

  const saveQuiz = (quiz: Quiz | null) => {
    if (quiz) {
      dispatch(
        infractionCodesActions.saveQuiz(quiz, (response) => {
          toast('Defense was saved successfully');

          setCurrentQuiz(response);
        }),
      );
    }
  };

  const deleteQuiz = (quiz: Quiz) => {
    if (quiz) {
      dispatch(infractionCodesActions.deleteQuiz(quiz));
    }
  };

  const handleSaveDefence = () => {
    if (!currentQuiz?.defence) {
      toast('Enter defense text first');
    }

    if (isNotNewQuiz(currentQuiz)) {
      saveQuiz(currentQuiz);
    }
  };

  const handleSaveInfraction = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!currentQuiz?.defence) {
      e.preventDefault();
      toast.error('Create defense for it first');

      return;
    }

    if (isNotNewQuiz(currentQuiz)) {
      saveQuiz({
        ...currentQuiz,
        automated: !currentQuiz?.automated,
      });
    }
  };

  const onSubmit = (values: Quiz | NewQuiz) => {
    const onSuccess = (response: Quiz) => {
      setCurrentQuiz(response);

      setModalOpened(false);
    };

    if (!values.defence) {
      delete values.defence;
    }

    if (isNotNewQuiz(values)) {
      dispatch(infractionCodesActions.updateQuiz(values, onSuccess));
    } else {
      if (isEmptyQuestion(values.questions)) {
        delete values.questions;
      }

      dispatch(infractionCodesActions.createQuiz(values, onSuccess));
    }
  };

  const initialValues: NewQuiz = useMemo(
    () =>
      currentQuiz || {
        infraction_code_id: infractionCode?.id || 0,
        name: '',
        questions,
        automated: false,
      },
    [infractionCode?.id, currentQuiz],
  );

  const formik = useFormik<Quiz | NewQuiz>({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema,
    validate: validateQuestions,
  });

  return (
    <View
      deleteQuiz={deleteQuiz}
      infractionCode={infractionCode}
      goBack={goBack}
      formik={formik}
      currentQuiz={currentQuiz}
      handleCopyQuiz={handleCopyQuiz}
      handleDefenceChange={handleDefenceChange}
      handleNewQuiz={handleNewQuiz}
      handleSaveDefence={handleSaveDefence}
      handleSaveInfraction={handleSaveInfraction}
      modelOpened={modelOpened}
      setCurrentQuiz={setCurrentQuiz}
      setModalOpened={setModalOpened}
    />
  );
};

export default DefenceQuizzes;
