import { useLayoutEffect, useRef, useState } from 'react'

import { objectsAreEqual } from '@services/utils'

interface DimensionObject {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}

const emptyDimensions: DimensionObject = {
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  x: 0,
  y: 0,
}

const getDimensionObject = (node: HTMLElement): DimensionObject => {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    top: rect.top || rect.x,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left || rect.y,
    x: rect.x || rect.left,
    y: rect.y || rect.top,
  }
}

const useDimensions = <T extends HTMLElement = HTMLElement>(
  liveMeasure = true
): [React.RefObject<T>, DimensionObject, HTMLElement | null] => {
  const [dimensions, setDimensions] = useState(emptyDimensions)
  const ref = useRef<T>(null)

  useLayoutEffect(() => {
    if (ref.current) {
      const measure = () =>
        window.requestAnimationFrame(() => {
          if (ref.current) {
            const newDimensions = getDimensionObject(ref.current)

            if (!objectsAreEqual(dimensions, newDimensions)) {
              setDimensions(newDimensions)
            }
          }
        })

      measure()

      if (liveMeasure) {
        window.addEventListener('resize', measure)

        return () => {
          window.removeEventListener('resize', measure)
        }
      }
    }
  }, [ref.current])

  return [ref, dimensions, ref.current]
}

export default useDimensions
