import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getMultiSelectValue } from '@components/Select';
import { apiRoutes } from '@config';
import useDebounce from '@hooks/useDebounce';
import { BuildingsFilters } from '@pages/Buildings/Filters';
import api from '@services/api';

const useLoadBuildings = (
  initialFilters: BuildingsFilters = {
    buildings: [],
    userNames: [],
    subscriptionStatus: [],
  },
  skip = false,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<BuildingsApiLoadData | null>(null);
  const [search, setSearch] = useState('');

  const [pagingParams, setPagingParams] = useState<PagingParams>({
    page: 1,
    per_page: 20,
  });

  const [filters, setFilters] = useState(initialFilters);

  const loadBuildings = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await api.get<ApiSuccessResponse<BuildingsApiLoadData>>(
        apiRoutes.allBuildings,
        {
          params: {
            ...pagingParams,
            search: search || undefined,
            buildings_ids: getMultiSelectValue(filters.buildings),
            users_ids: getMultiSelectValue(filters.userNames),
            subscription_types: getMultiSelectValue(filters.subscriptionStatus),
          },
        },
      );

      setData(response.data.data);
    } catch (e) {
      toast.error('Failed to load buildings');
    } finally {
      setIsLoading(false);
    }
  }, [
    filters.buildings,
    filters.subscriptionStatus,
    filters.userNames,
    pagingParams,
    search,
  ]);

  useEffect(() => {
    if (skip) return;

    loadBuildings();
  }, [loadBuildings, filters, skip]);

  const resetPage = useCallback(() => {
    setPagingParams((prev) => ({ ...prev, page: 1 }));
  }, []);

  const onChangeParams = useCallback((params: PagingParams) => {
    setPagingParams(params);
  }, []);

  const handleChangeSearch = useDebounce((value: string) => {
    setSearch(value);
    resetPage();
  });

  const onChangeFilters = useCallback(
    (newFilters: BuildingsFilters) => {
      setFilters(newFilters);
      resetPage();
    },
    [resetPage],
  );

  return {
    isLoading,
    data,
    onChangeParams,
    handleChangeSearch,
    onChangeFilters,
  };
};

export default useLoadBuildings;
