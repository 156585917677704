import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { ReactSVG } from 'react-svg';

import DragHelper from '@assets/delete.svg';

import styles from '../../styles.module.scss';
import { AnswerError } from '../../types';

interface Props {
  errors: FormikErrors<Quiz | NewQuiz>;
  handleBlur: React.FocusEventHandler;
  question: Question;
  questionIndex: number;
  showRemoveButton: boolean;
  touched: FormikTouched<Quiz | NewQuiz>;
  handleChange(e: React.ChangeEvent): void;
  remove<T>(index: number): T | undefined;
  setFieldValue<T>(
    field: string,
    value: T,
    shouldValidate?: boolean | undefined,
  ): void;
}

const QuestionItem: React.FC<Props> = ({
  errors,
  handleBlur,
  touched,
  question,
  questionIndex,
  showRemoveButton,
  handleChange,
  remove,
  setFieldValue,
}) => {
  const questionsTouched = touched as FormikTouched<{
    questions: Question[];
  }>;

  const questionErrors = ((errors as unknown) as {
    questions: Array<AnswerError>;
  }).questions?.find((item) => item.index === questionIndex);

  const onRemove = () => remove(questionIndex);

  const onAddAnswer = () => {
    setFieldValue(`questions.${questionIndex}.answers.2`, '');
  };

  const hasQuestionError =
    questionErrors?.question &&
    questionsTouched.questions?.[questionErrors.index]?.question;

  const hasAnswerError = (answerIndex: number) =>
    questionErrors?.answers?.[answerIndex] &&
    ((questionsTouched.questions?.[questionErrors.index]
      ?.answers as unknown) as boolean[])?.[answerIndex];

  return (
    <div className={styles['question-item-edit']}>
      <div className={styles['question-item-block']}>
        <p className={styles['question-item-number']}>{`0${
          questionIndex + 1
        }`}</p>
        <div
          className={clsx(styles.header, {
            [styles.errored]: hasQuestionError,
          })}
        >
          <input
            onChange={handleChange}
            placeholder="Enter your question"
            value={question.question}
            name={`questions.${questionIndex}.question`}
            onBlur={handleBlur}
          />
          {hasQuestionError && (
            <span className={styles.error}>{questionErrors?.question}</span>
          )}
        </div>
        <div className={styles.answers}>
          {question.answers.map((answer, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={clsx(styles['answer-item'], {
                [styles.errored]: hasAnswerError(index),
              })}
            >
              <input
                onChange={handleChange}
                name={`questions.${questionIndex}.answers.${index}`}
                placeholder="Answer"
                value={answer}
                onBlur={handleBlur}
              />
              {hasAnswerError(index) && (
                <span className={styles.error}>
                  {questionErrors?.answers?.[index]}
                </span>
              )}
            </div>
          ))}
          {question.answers.length === 2 && (
            <button
              type="button"
              className={clsx(
                styles['answer-item'],
                styles['new-answer-action'],
              )}
              onClick={onAddAnswer}
            >
              <span className={styles.icon}>+</span>
              <span>Add option 3</span>
            </button>
          )}
        </div>
      </div>
      {showRemoveButton && (
        <ReactSVG
          onClick={onRemove}
          src={DragHelper}
          className={clsx('svg', styles.logo)}
        />
      )}
    </div>
  );
};

export default QuestionItem;
