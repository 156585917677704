import clsx from 'clsx';
import React from 'react';
import { ValueType } from 'react-select';
import { ReactSVG } from 'react-svg';

import SearchIcon from '@assets/search.svg';
import Header from '@common/Header';
import EmptyListMessage from '@components/EmptyListMessage';
import Input from '@components/Input';
import Loader from '@components/Loader';
import Select, { OptionType } from '@components/Select';
import { typeOptions } from '@constants/filters-lists';

import List from './InfractionCodesList';
import styles from './styles.module.scss';

interface Props {
  changeFilter: (option: ValueType<OptionType<DefenseType>>) => void;
  infractionCodes: InfractionCode[];
  isLoading: boolean;
  pagination: React.ReactNode;
  perPage?: number;
  typeValue?: OptionType<DefenseType>;
  loadCodes(
    params: InfractionCodesApiLoadOptions,
  ):
    | InfractionCodesApiLoadData
    | Promise<InfractionCodesApiLoadData | undefined>;
}

const View: React.FC<Props> = ({
  infractionCodes,
  isLoading,
  pagination,
  perPage,
  loadCodes,
  changeFilter,
  typeValue,
}) => {
  const renderList = () => {
    if (infractionCodes.length === 0 && !isLoading) {
      return <EmptyListMessage />;
    }

    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <List items={infractionCodes} />
        {pagination}
      </>
    );
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    loadCodes({
      page: 1,
      per_page: perPage || 20,
      search: event.target.value.toLowerCase(),
    });
  };

  return (
    <div className={clsx('defences', styles.defences)}>
      <Header />
      <div className={clsx('page-content', styles['page-content'])}>
        <div className={styles.heading}>
          <h1>Defenses</h1>
          <Select
            containerClassName={styles.select}
            options={typeOptions}
            small
            withLabel
            value={typeValue}
            label={<span>Filter by:</span>}
            onChange={changeFilter}
          />
        </div>
        <div className={styles['search-wrapper']}>
          <ReactSVG
            src={SearchIcon}
            className={clsx('svg', styles['search-icon'])}
          />
          <Input
            type="text"
            name="search"
            onChange={onSearch}
            inputContainerClassName={styles.search}
            placeholder="search"
            containerClassName={styles['search-container']}
            disableError
          />
        </div>
        {renderList()}
      </div>
    </div>
  );
};

export default View;
