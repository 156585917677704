import React, { useCallback } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import clsx from 'clsx';

import FormData from './form';
import Input from '@components/Input';
import InfoTooltip from '@common/InfoTooltip';

import { toArray } from '@services/utils/array';

import sharedStyles from '../styles.module.scss';
import styles from '@components/FileInput/DefaultFile/styles.module.scss';
import { ReactSVG } from 'react-svg';
import UploadIcon from '@assets/upload.svg';

interface Props {
  violation: ViolationDetails | null;
  form: FormData;
  errors: FormikErrors<FormData>;
  touched: FormikTouched<FormData>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: <F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
    shouldValidate?: boolean,
  ) => void;
}

const Files: React.FC<Props> = ({
  form,
  touched,
  errors,
  onChange,
  setFieldValue,
  violation,
}) => {
  const onChangeFile = useCallback(
    (file: File | File[] | undefined) =>
      setFieldValue('files', toArray(file), false),
    [setFieldValue],
  );

  return (
    <React.Fragment>
      <div
        className={clsx(sharedStyles['section'], sharedStyles['files-section'])}
      >
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>{'File Uploads'}</h2>
          <p className={sharedStyles['section-description']}>
            {'Use the fields below to upload any supporting documentation before ' +
              'submitting your request. Upload .jpeg, .pdf, .doc, files - Total file size cannot exceed 3Mb'}
            <InfoTooltip>
              {'If there are more than 3 documents to upload or the total size of the ' +
                'documents is more than 3 MB, submit the request by mail.'}
            </InfoTooltip>
          </p>
        </div>
        <div className={sharedStyles['inputs']}>
          <Input
            type="file"
            name="files"
            file={form.files}
            onChangeFile={onChangeFile}
            multiple={true}
            accept=".jpeg,.pdf,.doc,.docx"
            max={2}
          />
        </div>
        <div
          onClick={() => {
            // @ts-ignore
            window.open(violation.ecbAuthUrl, '_blank').focus();
          }}
          className={clsx(styles['file'])}
        >
          <ReactSVG
            src={UploadIcon}
            className={clsx('svg', styles['upload-icon'])}
          />
          <p className={styles['text']}>{`ECB AUTH`}</p>
        </div>
      </div>
      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Affirmation'}
            <span className="asterisk" />
          </h2>
        </div>
        <div className={sharedStyles['inputs']}>
          <Input
            type="checkbox"
            name="affirmation"
            checked
            disabled
            label="I affirm that I am authorized to complete and submit this application."
            error={touched.affirmation && errors.affirmation}
          />
        </div>
      </div>
      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Certification'}
            <span className="asterisk" />
          </h2>
        </div>
        <div className={sharedStyles['inputs']}>
          <Input
            type="checkbox"
            name="certification"
            checked
            label={
              'I CERTIFY UNDER PENALTY OF PERJURY THAT (A) I AM AUTHORIZED ' +
              'TO COMPLETE AND SUBMIT THIS REQUEST AND (B) THAT TO THE BEST ' +
              'OF MY KNOWLEDGE, ALL INFORMATION I INCLUDED ON THIS FORM AND ' +
              'IN THE ATTACHMENTS IS TRUE. I UNDERSTAND THAT FALSE STATEMENTS ' +
              'MADE IN THIS DOCUMENT AND/OR ANY OF ITS ATTACHMENTS ARE PUNISHABLE ' +
              'BY FINE OR IMPRISONMENT UNDER SECTION 175.30 OF THE PENAL LAW.'
            }
            error={touched.certification && errors.certification}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Files;
