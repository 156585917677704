import dayjs from 'dayjs'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getShortMonth = (date: Date) =>
  isNaN(date.getTime()) ? '' : monthNames[date.getUTCMonth()].slice(0, 3)

export const day = (date: Date) => date.getDate()
export const month = (date: Date) => date.getMonth() + 1
export const year = (date: Date) => date.getFullYear()

export const apiDate = (date: Date) =>
  `${year(date)}-${String(month(date)).padStart(2, '0')}-${String(
    day(date)
  ).padStart(2, '0')}`

export const formatDate = (
  date: Date | string | undefined,
  delimiter = '-'
) => {
  if (!date) {
    return ''
  }

  const d = new Date(`${date}T19:09:16-05:00`)

  return [
    String(month(d)).padStart(2, '0'),
    String(day(d)).padStart(2, '0'),
    year(d),
  ].join(delimiter)
}

export const addDate = (date: Date, diff: number, unit: dayjs.OpUnitType) => {
  return dayjs(date).add(diff, unit).toDate()
}

export default {
  getShortMonth,
  day,
  month,
  year,
  apiDate,
  formatDate,
  addDate,
}
