import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import ArrowIcon from '@assets/arrow.svg';
import SortButton from '@common/SortButton';
import useStateProp from '@hooks/useStateProp';

import InnerRow from '../building-row';

import styles from './styles.module.scss';

interface Props {
  buildings: Building[];
}

interface ItemProps {
  building: Building;
  isOpen: boolean;
  onClick(): void;
}

type OrderDiRection = 'asc' | 'desc';

const AccordionItem: React.FC<ItemProps> = ({ building, isOpen, onClick }) => {
  const { address, violations_amount } = building;

  const [users, setUsers] = useStateProp(building.users);
  const [orderDirection, setOrderDirection] = useState<OrderDiRection>('asc');

  const contentRef = useRef<HTMLDivElement>(null);

  const sortByUserName = useCallback(() => {
    const sortedUsers = [...users].sort((a, b) => {
      return orderDirection === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    });

    setOrderDirection((prevDirection) =>
      prevDirection === 'asc' ? 'desc' : 'asc',
    );

    setUsers(sortedUsers);
  }, [orderDirection, setUsers, users]);

  const sortBySubscriptionStatus = useCallback(() => {
    const sortedUsers = [...users].sort((a, b) => {
      const subscriptionStatusOrder = [null, 'daily', 'monthly', 'annual'];

      const aPriceName = a.subscription ? a.subscription.price_name : null;
      const bPriceName = b.subscription ? b.subscription.price_name : null;

      const comparison =
        subscriptionStatusOrder.indexOf(aPriceName) -
        subscriptionStatusOrder.indexOf(bPriceName);

      return orderDirection === 'asc' ? comparison : -comparison;
    });

    setOrderDirection((prevDirection) =>
      prevDirection === 'asc' ? 'desc' : 'asc',
    );

    setUsers(sortedUsers);
  }, [orderDirection, setUsers, users]);

  const sortByHiredCases = useCallback(() => {
    const sortedUsers = [...users].sort((a, b) => {
      return orderDirection === 'asc'
        ? a.violations_stats.hired - b.violations_stats.hired
        : b.violations_stats.hired - a.violations_stats.hired;
    });

    setOrderDirection((prevDirection) =>
      prevDirection === 'asc' ? 'desc' : 'asc',
    );

    setUsers(sortedUsers);
  }, [orderDirection, setUsers, users]);

  return (
    <div className={styles['accordion-item']}>
      <button
        className={styles['accordion-header']}
        onClick={onClick}
        type="button"
      >
        <ReactSVG
          src={ArrowIcon}
          className={clsx('svg', styles.arrow, isOpen && styles.open)}
        />
        <div>{address}</div>
        <p>
          AMOUNT: <span>{violations_amount}</span>
        </p>
      </button>
      <div
        ref={contentRef}
        className={styles.accordionContent}
        style={
          isOpen
            ? { height: contentRef.current?.scrollHeight }
            : { height: '0px' }
        }
      >
        <div className={clsx(styles['building-header'], styles.userRow)}>
          <SortButton
            className={styles['sort-button']}
            onClick={sortByUserName}
          >
            User’s name
          </SortButton>
          <SortButton
            className={clsx(styles['sort-button'], styles.cell)}
            onClick={sortBySubscriptionStatus}
          >
            Subscription status
          </SortButton>
          <SortButton className={clsx(styles['sort-button'])}>
            Additional Info
          </SortButton>
          <SortButton
            className={clsx(styles['sort-button'], styles.hiredCases)}
            onClick={sortByHiredCases}
          >
            Hired cases
          </SortButton>
        </div>
        {users.map((user) => (
          <InnerRow key={user.id} user={user} />
        ))}
      </div>
    </div>
  );
};

const Accordion: React.FC<Props> = ({ buildings }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(buildings[0].id);

  const handleOpen = useCallback((idx: number) => {
    setOpenIndex((prevIndex) => (prevIndex === idx ? null : idx));
  }, []);

  return (
    <div className={styles.accordion}>
      {buildings.map((item) => (
        <AccordionItem
          key={item.id}
          building={item}
          isOpen={openIndex === item.id}
          onClick={() => handleOpen(item.id)}
        />
      ))}
    </div>
  );
};

export default Accordion;
