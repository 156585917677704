import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ValueType } from 'react-select';
import { ReactSVG } from 'react-svg';

import SearchIcon from '@assets/search.svg';
import Header from '@common/Header';
import CaseStatusSelect from '@components/CaseStatusSelect';
import Link from '@components/Link';
import Select, { OptionType, getSelectValue } from '@components/Select';
import violationsActions from '@redux/violations/actions';
import { ViolationDefenseQuiz } from '@typings/enums';

import Filters from './Filters';
import SearchForm from './SearchForm';
import violationsLinks from './links';
import styles from './styles.module.scss';
import { defenseQuizOptions } from '@constants/filters-lists';

const ViolationsWrapper = <
  Props extends RouteComponentProps = RouteComponentProps
>(
  Component: React.ComponentType<Props>,
) => {
  const Wrapped: React.FC<Props> = (props) => {
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersActive, setFiltersActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [defenseQuizOption, setDefenseQuizOption] = useState(
      defenseQuizOptions[0].value,
    );

    const { params } = useSelector((state: ReduxState) => state.violations);
    const dispatch = useDispatch();

    const openFilters = () => setFiltersOpen(true);
    const closeFilters = () => setFiltersOpen(false);

    const defenseQuizValue = useMemo(
      () =>
        defenseQuizOptions.find((option) => option.value === defenseQuizOption),
      [defenseQuizOption],
    );

    const changeFilter = useCallback(
      (option: ValueType<OptionType<ViolationDefenseQuiz>>) => {
        const value = getSelectValue(option);

        if (value && !Array.isArray(value)) {
          setDefenseQuizOption(value);

          if (value === ViolationDefenseQuiz.all) {
            dispatch(
              violationsActions.filter({
                ...params,
                defence_quiz: undefined,
              }),
            );

            return;
          }

          dispatch(
            violationsActions.filter({
              ...params,
              defence_quiz: value,
            }),
          );
        }
      },
      [setDefenseQuizOption],
    );

    const links = useMemo(
      () =>
        violationsLinks.map(({ className, ...link }, index) => (
          <li key={`${link.to}-${index}`} className={styles['link-item']}>
            <Link
              {...link}
              className={clsx(
                'link',
                styles['link'],
                !filtersActive &&
                  !searchActive &&
                  window.location.pathname === link.to &&
                  styles['link-active'],
                className,
              )}
            />
          </li>
        )),
      [window.location.pathname, filtersActive, searchActive],
    );

    return (
      <div className={clsx('page', styles['violations'])}>
        <Header />
        <div className={clsx('page-content', styles['page-content'])}>
          <div className={styles['heading']}>
            <div className={styles['navigation-wrapper']}>
              <h1>{'Violations'}</h1>

              <nav className={styles['navigation']}>
                <ul className={styles['links']}>{links}</ul>
              </nav>
            </div>
            <div className={styles['filters-wrapper']}>
              <CaseStatusSelect />
              <Select
                containerClassName={styles.select}
                options={defenseQuizOptions}
                small
                withLabel
                value={defenseQuizValue}
                label={<span>Defense/Quiz:</span>}
                onChange={changeFilter}
              />
              {(filtersActive || searchActive) && (
                <div className={styles['filters-active']}>
                  {searchActive && !filtersActive && '↙ Search is active'}
                  {filtersActive && !searchActive && 'Filters are active ➞'}
                  {filtersActive &&
                    searchActive &&
                    '↙ Search and Filters are active ➞'}
                </div>
              )}
              <button
                type="button"
                className="primary-button"
                onClick={openFilters}
              >
                Filter
              </button>
            </div>
          </div>
          <div className={styles['search-wrapper']}>
            <ReactSVG
              src={SearchIcon}
              className={clsx('svg', styles['search-icon'])}
            />
            <SearchForm setSearchActive={setSearchActive} />
          </div>
          <Component {...props} />
        </div>
        <Filters
          open={filtersOpen}
          setFiltersActive={setFiltersActive}
          close={closeFilters}
        />
      </div>
    );
  };

  return Wrapped;
};

export default ViolationsWrapper;
