import { FormikErrors } from 'formik';

import { ValueType, getSelectValue } from '@components/Select';
import city5 from '@constants/city5';
import getState from '@services/address';
import {
  emailValidate,
  requiredValidate,
  validate,
} from '@services/validation';
import { StateLabel } from '@typings/enums';

interface FormData {
  respondents_address: string;
  respondents_city: string;
  respondents_email: string;
  respondents_first_name: string;
  respondents_last_name: string;
  respondents_phone: string;
  respondents_state: ValueType<State> | undefined;
  respondents_zip_code: string | number;
  summons_number: string;
}

export const formInitial = (violation: ViolationDetails | null): FormData => {
  const state = getState(violation?.respondentAddressStateName);

  return {
    summons_number: violation?.ticketNumber || '',
    respondents_first_name: violation?.respondentFirstName || '',
    respondents_last_name: violation?.respondentLastName || '',
    respondents_address:
      violation?.respondentAddress ||
      violation?.user?.mailing_address ||
      `${violation?.respondentAddressHouse} ${violation?.respondentAddressStreetName}`,
    respondents_city: violation?.respondentAddressCity || '',
    respondents_state: state
      ? { value: state, label: StateLabel[state] }
      : { value: city5.state, label: StateLabel[city5.state] },
    respondents_zip_code: violation?.respondentAddressZipCode || '',
    respondents_phone: '',
    respondents_email: '',
  };
};

export const validateForm = ({
  summons_number,
  respondents_first_name,
  respondents_last_name,
  respondents_address,
  respondents_city,
  respondents_state,
  respondents_zip_code,
  respondents_email,
}: FormData): FormikErrors<FormData> => ({
  summons_number: validate(summons_number, requiredValidate),
  respondents_first_name: validate(respondents_first_name, requiredValidate),
  respondents_last_name: validate(respondents_last_name, requiredValidate),
  respondents_address: validate(respondents_address, requiredValidate),
  respondents_city: validate(respondents_city, requiredValidate),
  respondents_state: validate(
    getSelectValue(respondents_state),
    requiredValidate,
  ),
  respondents_zip_code: validate<string | number>(
    respondents_zip_code,
    requiredValidate,
  ),
  respondents_email: validate(respondents_email, emailValidate),
});

export const formKeys: (keyof FormData)[] = [
  'summons_number',
  'respondents_first_name',
  'respondents_last_name',
  'respondents_address',
  'respondents_city',
  'respondents_state',
  'respondents_zip_code',
  'respondents_phone',
  'respondents_email',
];

export default FormData;
