enum WordStrBoolean {
  true = 'Yes',
  false = 'No',
}

const WordToBoolean = {
  [WordStrBoolean.true]: true,
  [WordStrBoolean.false]: false,
};

const toWordBoolean = (b: boolean) =>
  b ? WordStrBoolean.true : WordStrBoolean.false;

export { WordToBoolean, toWordBoolean };

export default WordStrBoolean;
