import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { bindActionCreators } from 'redux';

import CheckboxIcon from '@assets/checkbox.svg';
import FlagIcon from '@assets/flag.svg';
import VoteChart from '@common/VoteChart';
import YesNoChart from '@common/YesNoChart';
import Loader from '@components/Loader';
import { useAppSelector } from '@hooks/redux';
import feedbacksActions from '@redux/feedbacks/actions';
import { FeedbackQuestionGroup, FeedbackQuestionType } from '@typings/enums';

import ResponsesTable from './Table';
import styles from './styles.module.scss';

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  feedbacksActions: bindActionCreators<
    typeof feedbacksActions,
    BindedAsyncActions<typeof feedbacksActions>
  >(feedbacksActions, dispatch),
});

const connector = connect(null, mapDispatchToProps);

type NativeProps = {
  questionId?: number;
};

type Props = NativeProps & ConnectedProps<typeof connector>;

const ResponseDetails: React.FC<Props> = ({
  questionId,
  feedbacksActions: { loadStats, sortStats },
}) => {
  const {
    isLoadingStats: isLoading,
    stats,
    statsParams,
    questionParams,
  } = useAppSelector((state) => state.feedbacks);

  const isScale = !stats || stats?.question.type === FeedbackQuestionType.scale;
  const responsesCount = stats?.responses_count || 0;
  const group = questionParams.group || FeedbackQuestionGroup.dismissed;

  useEffect(() => {
    if (!questionId) return;

    loadStats({ feedbackQuestionId: questionId, group }, true);
  }, [questionId]);

  const loadResponses = useCallback(
    (params: PagingParams) => {
      if (!questionId) return;

      loadStats({
        feedbackQuestionId: questionId,
        group,
        ...params,
      });
    },
    [questionId],
  );

  const sortResponses = useCallback(
    (order_by?: FeedbackStatsSortType) => () => {
      if (!questionId) return;

      sortStats({ feedbackQuestionId: questionId, order_by, group });
    },
    [sortStats, questionId],
  );

  if (
    isLoading &&
    (!stats?.responses || statsParams.feedbackQuestionId !== questionId)
  )
    return <Loader />;

  return (
    <section className={styles.page}>
      <div
        className={clsx(
          styles['title-box'],
          isScale ? styles.opinion : styles['yes-no'],
        )}
      >
        <div className={styles['icon-box']}>
          <ReactSVG
            src={isScale ? FlagIcon : CheckboxIcon}
            className={clsx('svg', styles.icon)}
          />
        </div>
        <div className={styles.title}>
          <p className={styles['title-text']}>
            {stats?.question.question_text}
          </p>
          <div className={styles.type}>
            {isScale ? 'Opinion Scale' : 'Yes/No'}
          </div>
        </div>
        <div className={styles['stat-cell']}>
          <span className={styles.value}>{responsesCount}</span>
          <span className={styles.caption}>
            {responsesCount === 1 ? 'Response' : 'Responses'}
          </span>
        </div>
        {!!stats?.average && (
          <div className={styles['stat-cell']}>
            <span className={styles.value}>{stats.average}</span>
            <span className={styles.caption}>Average</span>
          </div>
        )}
      </div>

      {isScale ? (
        <VoteChart
          className={styles.chart}
          scaleSize={stats?.question.scale_size ?? undefined}
          data={stats?.chart}
        />
      ) : (
        <YesNoChart className={styles.chart} data={stats.chart} />
      )}

      <ResponsesTable
        className={styles.table}
        isLoading={isLoading}
        meta={stats?.responses.meta}
        responses={stats?.responses.data || []}
        showMessage={group === FeedbackQuestionGroup.support}
        load={loadResponses}
        sort={sortResponses}
      />
    </section>
  );
};

export default connector(ResponseDetails) as React.FC<NativeProps>;
