import React, { useMemo, useState } from 'react'
import clsx from 'clsx'

import Header from '@common/Header'
import HeadInfo from './HeadInfo'
import MainInfo from './MainInfo'
import Activity from './Activity'
import TopSection from './TopSection'
import BaseInfo from './BaseInfo'

import styles from './styles.module.scss'
import QuizModal from './QuizModal'

interface Props {
  violation: ViolationDetails | null
  activity: ViolationActivity[]
  goBack: () => void
  sendNote: (notes: string[], cb?: () => void) => void
}

const View: React.FC<Props> = ({ violation, activity, goBack, sendNote }) => {
  const [modalState, setModalState] = useState(false)

  const ViolationContent = useMemo(
    () =>
      violation ? (
        <>
          <HeadInfo violation={violation} />
          <BaseInfo violation={violation} />
          <TopSection
            setModalState={setModalState}
            defenseType={violation.defenceType}
            defense={violation.defence}
            caseStatus={violation.caseStatus}
            violationID={violation.id}
          />
          <MainInfo violation={violation} />
        </>
      ) : null,
    [violation]
  )

  return (
    <div className={clsx('page', styles['violation-details'])}>
      <Header />
      <div className={clsx('page-content', styles['page-content'])}>
        {ViolationContent}
        <Activity
          activity={activity}
          notes={violation?.notes}
          sendNote={sendNote}
        />
      </div>
      {modalState && (
        <QuizModal
          setModalState={setModalState}
          open={modalState}
          violation={violation}
        />
      )}
    </div>
  )
}

export default View
