import React, { useCallback, useEffect, useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loader from '@components/Loader';
import { defaultPerPage } from '@config';
import useThreshold from '@hooks/useThreshold';
import violationsActions from '@redux/violations/actions';
import { emptyFilter, emptySort } from '@services/violation';
import {
  SortDirection,
  ViolationGroupType,
  ViolationSortType,
  ViolationType,
} from '@typings/enums';

import styles from '../../styles.module.scss';
import BuildingGroup from '../BuildingGroup';
import Filters from '../Filters';
import ViolationHeadRow from '../ViolationHeadRow';
import ViolationRow from '../ViolationRow';

const mapStateToProps = (state: ReduxState) => ({
  violations: state.violations.violations,
  isLoading: state.violations.isLoading,
  isViolationsLoading: state.violations.isViolationsLoading,
  page: state.violations.params.page,
  totalPages: state.violations.params.totalPages,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface NativeProps {
  filtersOpen: boolean;
  groupBy: ViolationGroupType;
  selectedType: ViolationType;
  userId: string;
  onCloseFilters(): void;
}

type Props = NativeProps & ConnectedProps<typeof connector>;

const ViolationsTable: React.FC<Props> = ({
  filtersOpen,
  groupBy,
  selectedType,
  userId,
  violations = [],
  isLoading,
  isViolationsLoading,
  page,
  totalPages,
  violationsActions: { load: loadViolations, sort: sortViolations },
  onCloseFilters,
}) => {
  useEffect(() => {
    let params: ViolationsApiLoadOptions = {
      type: selectedType,
      page: 1,
      per_page: defaultPerPage,
      users_ids: [userId],
      ...emptyFilter,
      ...emptySort,
    };

    switch (groupBy) {
      case ViolationGroupType.buildingAddress: {
        params = {
          ...params,
          group_by: groupBy,
        };

        break;
      }

      case ViolationGroupType.hearingDate: {
        params = {
          ...params,
          group_by: undefined,
          order_by: ViolationSortType.hearingDate,
          order_by_direction: SortDirection.desc,
        };

        break;
      }

      default: {
        break;
      }
    }

    loadViolations(params, true);
  }, [selectedType, userId, loadViolations, groupBy]);

  const data = useMemo(() => {
    switch (groupBy) {
      case ViolationGroupType.buildingAddress: {
        return Array.isArray(violations) ? null : (
          <ul className={styles.groups}>
            {Object.keys(violations).map((key) => (
              <BuildingGroup
                key={`${key}-${selectedType}`}
                title={key}
                type={selectedType}
                violations={violations[key]}
              />
            ))}
          </ul>
        );
      }

      case ViolationGroupType.hearingDate: {
        const dateCompare = (a: string, b: string) =>
          new Date(a).getTime() - new Date(b).getTime();

        const viols = Array.isArray(violations)
          ? violations
          : Object.keys(violations)
              .sort(dateCompare)
              .reduce(
                (acc, key) => [
                  ...acc,
                  ...(violations as { [key: string]: Violation[] })[key],
                ],
                [] as Violation[],
              );

        const tableBody = viols.map((violation) => (
          <ViolationRow
            key={`${violation.id} - ${violation.ticketNumber}`}
            groupBy={groupBy}
            violation={violation}
          />
        ));

        return (
          <table className={styles.table}>
            <thead className={styles['table-head']}>
              <ViolationHeadRow
                type={selectedType}
                groupBy={groupBy}
                onHearingDateClick={() => {
                  sortViolations({
                    order_by: ViolationSortType.hearingDate,
                    page: 1,
                  });
                }}
                onBalanceClick={() => {
                  sortViolations({
                    order_by: ViolationSortType.balance,
                    page: 1,
                  });
                }}
                onStatusClick={() => {
                  sortViolations({
                    order_by: ViolationSortType.status,
                    page: 1,
                  });
                }}
              />
            </thead>
            <tbody className={styles['table-body']}>{tableBody}</tbody>
          </table>
        );
      }

      default: {
        return null;
      }
    }
  }, [groupBy, selectedType, sortViolations, violations]);

  const onThreshold = useCallback(
    (cb: () => void) => {
      if (!isLoading && (!page || !totalPages || page < totalPages)) {
        loadViolations({ per_page: defaultPerPage }, undefined, false, cb, cb);
      } else {
        cb();
      }
    },
    [loadViolations, page, totalPages, isLoading],
  );

  const thresholdRef = useThreshold(1, onThreshold, [data]);

  return (
    <>
      <div ref={thresholdRef}>{data}</div>
      {isViolationsLoading && <Loader />}
      <Filters open={filtersOpen} close={onCloseFilters} groupBy={groupBy} />
    </>
  );
};

export default connector(ViolationsTable) as React.FC<NativeProps>;
