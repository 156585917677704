import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';

import FilterModal from '@common/FilterModal';
import Select, { OptionType } from '@components/Select';
import buildingsActions from '@redux/buildings/actions';
import usersActions from '@redux/users/actions';
import { emptyFilter } from '@services/building';
import { emptyFilter as emptyUsersFilter } from '@services/user';
import { subscriptionTypes } from '@typings/enums/SubscriptionType';

import SubscriptionStatus from '../building-row/subscription-status';

import styles from './styles.module.scss';

interface Props {
  hideUserSelect?: boolean;
  isLoading: boolean;
  isOpen: boolean;
  close(): void;
  onChangeFilters(newFilters: BuildingsFilters): void;
}

export type BuildingsFilters = {
  buildings: ValueType<OptionType>;
  userNames: ValueType<OptionType>;
  subscriptionStatus: ValueType<OptionType<SubscriptionStatusType>>;
};

const formInitial: BuildingsFilters = {
  buildings: [],
  userNames: [],
  subscriptionStatus: [],
};

const Filters: React.FC<Props> = ({
  hideUserSelect,
  isLoading,
  isOpen,
  close,
  onChangeFilters,
}) => {
  const dispatch = useDispatch();
  const {
    buildings: {
      buildings,
      params: { search: buildingsSearch },
    },
    users: {
      users,
      params: { search: usersSearch },
    },
  } = useSelector((state: ReduxState) => state);

  const { handleSubmit, values, setFieldValue, handleReset } = useFormik({
    initialValues: formInitial,
    onSubmit: onChangeFilters,
  });

  useEffect(() => {
    dispatch(buildingsActions.load({ page: -1, ...emptyFilter }, true));
  }, [dispatch]);

  useEffect(() => {
    if (hideUserSelect) return;

    dispatch(usersActions.load({ page: -1, ...emptyUsersFilter }, true));
  }, [dispatch, hideUserSelect]);

  const buildingsOptions = useMemo(
    () =>
      buildings.map(({ address, id }) => ({
        value: String(id),
        label: address,
      })),
    [buildings],
  );

  const usersOptions: OptionType[] = useMemo(
    () =>
      users.map((user) => ({
        value: String(user.id),
        label: `${user.firstName} ${user.lastName}`,
      })),
    [users],
  );

  const onBuildingsChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('buildings', e, false),
    [setFieldValue],
  );

  const onBuildingsInputChange = useCallback(
    (newValue: string) => {
      if (!newValue && !buildingsSearch) {
        return;
      }

      if (newValue !== buildingsSearch) {
        dispatch(buildingsActions.load({ search: newValue || undefined }));
      }
    },
    [buildingsSearch, dispatch],
  );

  const onUserNamesChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('userNames', e, false),
    [setFieldValue],
  );

  const onUsersInputChange = useCallback(
    (newValue: string) => {
      if (!newValue && !usersSearch) {
        return;
      }

      if (newValue !== usersSearch) {
        dispatch(usersActions.load({ search: newValue || undefined }));
      }
    },
    [dispatch, usersSearch],
  );

  const statusTypeOptions: OptionType[] = useMemo(
    () =>
      subscriptionTypes.map((value) => ({
        value: String(value),
        label: <SubscriptionStatus status={value} />,
      })),
    [],
  );

  const onSubscriptionStatusChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('subscriptionStatus', e, false),
    [setFieldValue],
  );

  return (
    <FilterModal
      open={isOpen}
      close={close}
      formProps={{ onSubmit: handleSubmit, onReset: handleReset }}
      isLoading={isLoading}
    >
      <Select
        isMulti
        value={values.buildings}
        options={buildingsOptions}
        onChange={onBuildingsChange}
        className={clsx(styles.select, styles['building-select'])}
        placeholder="All"
        onInputChange={onBuildingsInputChange}
        label="Building"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />
      {!hideUserSelect && (
        <Select
          isMulti
          value={values.userNames}
          options={usersOptions}
          closeMenuOnSelect={false}
          onChange={onUserNamesChange}
          className={clsx(styles.select, styles['user-select'])}
          placeholder="All"
          onInputChange={onUsersInputChange}
          label="Users"
          labelClassName={styles['select-label']}
          containerClassName={styles['select-container']}
        />
      )}

      <Select
        isMulti
        value={values.subscriptionStatus}
        options={statusTypeOptions}
        onChange={onSubscriptionStatusChange}
        className={clsx(styles.select, styles['status-select'])}
        placeholder="All"
        label="Subscription status"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />
    </FilterModal>
  );
};

export default Filters;
