export const apiUrl = process.env.REACT_APP_API_URL;

export const apiRoutes = {
  login: '/login',
  logout: '/logout',
  forgotPassword: '/forgot-password',
  //* using url given from BE
  resetPassword: '',

  violations: '/violations',
  violationDetails: (id: ApiID) => `/violations/${id}`,

  // todo: replace
  violationSendReopenRequest: (id: ApiID) =>
    `/violations/${id}/submit-request-to-reopen-form`,
  violationSendOnlineHearing: (id: ApiID) =>
    `violations/${id}/submit-hearing-form`,

  violationsInfractionCodes: '/violations/infraction-codes',
  violationsHearingResults: '/violations/hearing-results',
  violationsCaseStatuses: '/violations/case-statuses',
  violationActivity: (id: ApiID) => `violations/activity/${id}`,

  payments: '/transactions',
  paymentsStatuses: '/transactions/statuses',
  incomeStats: '/transactions/income-stats',

  buildings: '/buildings',
  allBuildings: '/admin/buildings',

  infractionCodes: '/infraction-codes',
  infractionCodeDetails: (id: ApiID) => `/infraction-codes/${id}`,
  infractionCodeUpdate: (id: ApiID) => `/infraction-codes/${id}`,
  quizzes: () => `/quizzes/`,
  quizzesUpdate: (id: ApiID) => `/quizzes/${id}`,

  users: '/users',
  userDetails: (id: ApiID) => `/users/${id}`,

  questions: '/questions',
  feedbacks: '/feedbacks',
  feedbackStats: (id: ApiID) => `/questions/${id}/stats`,
};

export const defaultPerPage = 10;

export const loadStaticsInterval = 1000 * 60 * 60; //* one hour

export default {
  apiUrl,
  apiRoutes,
  defaultPerPage,
  loadStaticsInterval,
};
