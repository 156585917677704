import clsx from 'clsx';
import React from 'react';

import { ViolationDefenseQuizLabel } from '@typings/enums';

import styles from './styles.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  children?: string;
  type: ViolationDefenseQuiz;
}

const DefenseQuiz: React.FC<Props> = ({
  type,
  children = ViolationDefenseQuizLabel[type] || type,
  className,
  ...props
}) => (
  <p
    className={clsx(
      styles['case-status'],
      styles[ViolationDefenseQuizLabel[type]],
      className,
    )}
    title={type}
    {...props}
  >
    {children}
  </p>
);

export default React.memo(DefenseQuiz);
