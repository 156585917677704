import { isAxiosError } from 'axios';

import { apiRoutes, defaultPerPage } from '@config';
import action from '@redux/action';
import api from '@services/api';

import ActionType from './types';

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading));

const onError = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error));

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET));

const load = (
  { page, per_page, ...params }: InfractionCodesApiLoadOptions = {
    page: undefined,
    per_page: undefined,
  },
  reload?: boolean,
  onSuccess?: (response: InfractionCodesApiLoadData) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<InfractionCodesApiLoadData> => async (dispatch, getState) => {
  dispatch(action(ActionType.LOAD_BEGIN));

  if (!per_page) {
    per_page = getState().infractionCodes.params.per_page || defaultPerPage;
  }

  if (reload === undefined) {
    reload = true;
  }

  if (page === undefined) {
    if (reload) {
      page = 1;
    } else {
      page = Math.max((getState().infractionCodes.params.page || 0) + 1, 1);
    }
  }

  try {
    const response = await api.get(apiRoutes.infractionCodes, {
      params: { per_page, page, ...params },
    });

    dispatch(
      action(ActionType.LOAD_SUCCESS, {
        infractionCodes: response.data.data.records,
        params: {
          ...params,
          page,
          per_page,
          reload,
          totalPages: response.data.data.meta.lastPage,
        },
      }),
    );

    onSuccess?.(response.data.data);
    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error));
      onError?.(error);
    }
  }
};

const loadDetails = (
  id: ApiID,
  onSuccess?: (response: InfractionCode) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<InfractionCode> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_BEGIN));

  try {
    const response = await api.get<ApiSuccessResponse<InfractionCode>>(
      apiRoutes.infractionCodeDetails(id),
    );

    dispatch(
      action(ActionType.LOAD_DETAILS_SUCCESS, {
        infractionCodeDetails: response.data.data,
      }),
    );

    onSuccess?.(response.data.data);
    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error));
      onError?.(error);
    }
  }
};

const saveInfractionCode = (
  infractionCode: InfractionCode,
  onSuccess?: (response: Quiz) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<InfractionCode> => async (dispatch) => {
  try {
    const response = await api.put<ApiSuccessResponse<InfractionCode>>(
      apiRoutes.infractionCodeUpdate(infractionCode.id),
      { ...infractionCode },
    );
    dispatch(loadDetails(infractionCode.id));
    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error));
      onError?.(error);
    }
  }
};

const createQuiz = (
  quiz: NewQuiz,
  onSuccess?: (response: Quiz) => void,
): AppThunk<Quiz> => async (dispatch) => {
  try {
    const response = await api.post<ApiSuccessResponse<Quiz>>(
      apiRoutes.quizzes(),
      {
        ...quiz,
        infraction_code_id: quiz.infraction_code_id,
        questions: quiz.questions?.map((q) => ({
          ...q,
          answers: q.answers.filter((a) => !!a),
        })),
      },
    );

    onSuccess?.(response.data.data);

    dispatch(loadDetails(quiz.infraction_code_id || -1));

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error));
      onError?.(error);
    }

    return undefined;
  }
};

const updateQuiz = (
  quiz: Quiz,
  onSuccess?: (response: Quiz) => void,
): AppThunk<Quiz> => async (dispatch) => {
  try {
    const response = await api.put<ApiSuccessResponse<Quiz>>(
      apiRoutes.quizzesUpdate(quiz.id),
      {
        ...quiz,
        automated: quiz.automated ? 1 : 0,
        infraction_code_id: quiz.infraction_code_id,
      },
    );

    onSuccess?.(response.data.data);

    dispatch(loadDetails(quiz.infraction_code_id || -1));

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error));
      onError?.(error);
    }

    return undefined;
  }
};

const saveQuiz = (
  quiz: Quiz,
  onSuccess?: (response: Quiz) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<Quiz> => async (dispatch) => {
  try {
    const response = !quiz.id
      ? await api.post<ApiSuccessResponse<Quiz>>(apiRoutes.quizzes(), {
          ...quiz,
          infraction_code_id: quiz.infraction_code_id,
          questions: quiz.questions?.map((q) => ({
            ...q,
            answers: q.answers.filter((a) => !!a),
          })),
        })
      : await api.put<ApiSuccessResponse<Quiz>>(
          apiRoutes.quizzesUpdate(quiz.id),
          {
            ...quiz,
            automated: quiz.automated ? 1 : 0,
            infraction_code_id: quiz.infraction_code_id,
          },
        );

    onSuccess?.(response.data.data);
    dispatch(loadDetails(quiz.infraction_code_id || -1));

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error));
      onError?.(error);
    }
  }
};

const deleteQuiz = (
  quiz: Quiz,
  onSuccess?: (response: Quiz) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<Quiz> => async (dispatch) => {
  try {
    const response = await api.delete<ApiSuccessResponse<Quiz>>(
      apiRoutes.quizzesUpdate(quiz.id || -1),
    );

    onSuccess?.(quiz);
    dispatch(loadDetails(quiz.infraction_code_id || -1));

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOAD_ERROR, error));
      onError?.(error);
    }
  }
};

const infractionCodesActions = {
  loading,
  error: onError,
  reset,
  updateQuiz,
  createQuiz,
  load,
  loadDetails,
  saveInfractionCode,
  saveQuiz,
  deleteQuiz,
};

export default infractionCodesActions;
