import React, { useCallback, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { ValueType } from 'react-select';
import clsx from 'clsx';
import { useFormik } from 'formik';

import FilterModal from '@common/FilterModal';
import CalendarInput from '@components/CalendarInput';
import Select, { OptionType, getMultiSelectValue } from '@components/Select';
import CaseStatus from '@components/CaseStatus';

import violationsActions from '@redux/violations/actions';
import { CaseStatusKeyToType, HearingStatusLabel } from '@typings/enums';
import { apiDate } from '@services/date';

import styles from './styles.module.scss';
import buildingsActions from '@redux/buildings/actions';
import { emptyFilter } from '@services/building';
import { emptyInfCodesFilter } from '@services/violation';

const mapStateToProps = (state: ReduxState) => ({
  buildings: state.buildings.buildings,
  buildingsSearch: state.buildings.params.search,
  infCodes: state.violations.infCodes,
  infCodesSearch: state.violations.infCodesParams.search,
  hearingResults: state.violations.hearingResults,
  caseStatusKeys: state.violations.caseStatusKeys,
  isLoading: state.violations.isLoading,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
  buildingsActions: bindActionCreators<
    typeof buildingsActions,
    BindedAsyncActions<typeof buildingsActions>
  >(buildingsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface NativeProps {
  open: boolean;
  close: () => void;
  setFiltersActive: (active: boolean) => void;
}

type Props = NativeProps & ConnectedProps<typeof connector>;

type FormData = {
  buildings: ValueType<OptionType>;
  infractionCodes: ValueType<OptionType>;
  hearingDate: Date[] | undefined;
  hearingResults: ValueType<OptionType>;
  hiredState: ValueType<OptionType>;
};

const formInitial: FormData = {
  buildings: [],
  infractionCodes: [],
  hearingDate: undefined,
  hearingResults: [],
  hiredState: [],
};

const Filters: React.FC<Props> = ({
  open,
  close,
  setFiltersActive,
  violationsActions: {
    resetFilters,
    filter,
    loadInfractionCodes,
    loadHearingResults,
    loadCaseStatuses,
  },
  buildingsActions: { load: loadBuildings },
  buildings,
  infCodes,
  hearingResults,
  caseStatusKeys,
  buildingsSearch,
  infCodesSearch,
  isLoading,
}) => {
  const { values, handleReset, handleSubmit, setFieldValue } = useFormik<
    FormData
  >({
    initialValues: {
      ...formInitial,
    },
    onSubmit: ({
      buildings,
      infractionCodes,
      hearingDate: hDate,
      hearingResults,
      hiredState,
    }) => {
      const filters: ViolationFilters & { type?: ViolationType } = {
        buildings_ids: getMultiSelectValue(buildings),
        infraction_codes: getMultiSelectValue(infractionCodes),
        hearing_date_start: hDate && hDate[0] && apiDate(hDate[0]),
        hearing_date_end: hDate && hDate[1] && apiDate(hDate[1]),
        hearing_results: getMultiSelectValue(hearingResults),
      };

      if (hiredState) {
        filters.type = ((hiredState as unknown) as OptionType)
          .value as ViolationType;
      }

      filter(filters);
      setFiltersActive(true);
      close();
    },
    onReset: (_, helpers) => {
      resetFilters();
      helpers.setValues({ ...formInitial });
      setFiltersActive(false);
      window.location.reload();
    },
  });

  useEffect(() => {
    loadBuildings({ page: -1, ...emptyFilter }, true);
  }, [loadBuildings]);

  useEffect(() => {
    loadInfractionCodes({ ...emptyInfCodesFilter });
  }, [loadInfractionCodes]);

  useEffect(() => {
    loadHearingResults();
  }, [loadHearingResults]);

  useEffect(() => {
    loadCaseStatuses();
  }, [loadCaseStatuses]);

  const buildingsOptions = useMemo(
    () =>
      buildings.map(({ address, id }) => ({
        value: String(id),
        label: address,
      })),
    [buildings],
  );

  const infractionCodesOptions = useMemo(
    () =>
      infCodes
        .filter(
          (value) =>
            !infCodesSearch ||
            value.toLowerCase().includes(infCodesSearch.toLowerCase()),
        )
        .map((value) => ({ value: value, label: value })),
    [infCodes],
  );

  const hearingStatusOptions = useMemo(
    () =>
      hearingResults.filter(Boolean).map((value) => ({
        value,
        label: HearingStatusLabel[value],
      })),
    [hearingResults],
  );

  const onBuildingsChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('buildings', e, false),
    [buildingsOptions, setFieldValue],
  );

  const onBuildingsInputChange = useCallback(
    (newValue: string) => {
      if (!newValue && !buildingsSearch) {
        return;
      }
      if (newValue !== buildingsSearch) {
        loadBuildings({ search: newValue || undefined });
      }
    },
    [setFieldValue, loadBuildings, buildingsSearch],
  );

  const onInfractionCodesChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('infractionCodes', e, false),
    [infractionCodesOptions, setFieldValue],
  );

  const onInfractionCodesInputChange = useCallback(
    (newValue: string) => {
      if (!newValue && !infCodesSearch) {
        return;
      }
      if (newValue !== infCodesSearch) {
        loadInfractionCodes({ search: newValue || undefined });
      }
    },
    [setFieldValue, loadBuildings, infCodesSearch],
  );

  const onHearingDateChange = useCallback(
    (dates: Date[]) => setFieldValue('hearingDate', dates, false),
    [setFieldValue],
  );

  const onHearingStatusChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('hearingResults', e, false),
    [setFieldValue],
  );

  const onHiredStateChange = useCallback(
    (e: ValueType<OptionType>) => setFieldValue('hiredState', e, false),
    [setFieldValue],
  );

  return (
    <FilterModal
      open={open}
      close={close}
      formProps={{ onReset: handleReset, onSubmit: handleSubmit }}
      isLoading={isLoading}
    >
      <Select
        isMulti={true}
        value={values.buildings}
        options={buildingsOptions}
        onChange={onBuildingsChange}
        className={clsx(styles['select'], styles['building-select'])}
        placeholder="All"
        onInputChange={onBuildingsInputChange}
        label="Building"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <Select
        isMulti={true}
        value={values.infractionCodes}
        options={infractionCodesOptions}
        onChange={onInfractionCodesChange}
        placeholder="All"
        onInputChange={onInfractionCodesInputChange}
        label="Infraction code"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <CalendarInput
        options={{ mode: 'range' }}
        placeholder="All"
        value={values.hearingDate}
        onChange={onHearingDateChange}
        label="Hearing date"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <Select
        isMulti={true}
        value={values.hearingResults}
        options={hearingStatusOptions}
        onChange={onHearingStatusChange}
        placeholder="All"
        label="Hearing result"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />

      <Select
        value={values.hiredState}
        options={[
          { value: 'hired', label: 'Hired' },
          { value: 'unhired', label: 'Not hired' },
        ]}
        onChange={onHiredStateChange}
        placeholder="All"
        label="Hired / Not Hired"
        labelClassName={styles['select-label']}
        containerClassName={styles['select-container']}
      />
    </FilterModal>
  );
};

export default connector(Filters) as React.FC<NativeProps>;
