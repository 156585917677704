import clsx from 'clsx';
import { FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { ReactSVG } from 'react-svg';

import noQuestions from '@assets/no-files.svg';
import Header from '@common/Header';
import QuizItem from '@pages/DefenceQuizzes/QuizItem';

import EditableDefence from './EditableDefence';
import Head from './Head';
import QuestionItem from './QuestionItem';
import QuizModal from './QuizModal';
import { isNotNewQuiz } from './helpers';
import styles from './styles.module.scss';

interface Props {
  currentQuiz: Quiz | NewQuiz | null;
  formik: FormikProps<Quiz | NewQuiz>;
  infractionCode: InfractionCode | null;
  modelOpened: boolean;
  setCurrentQuiz: React.Dispatch<React.SetStateAction<Quiz | NewQuiz | null>>;
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  deleteQuiz(quiz: Quiz | null): void;
  goBack(): void;
  handleCopyQuiz(quiz: Quiz): void;
  handleDefenceChange(defence: string): void;
  handleNewQuiz(): void;
  handleSaveDefence(): void;
  handleSaveInfraction(e: React.MouseEvent<HTMLInputElement>): void;
}

const View: React.FC<Props> = ({
  goBack,
  infractionCode,
  setCurrentQuiz,
  modelOpened,
  deleteQuiz,
  currentQuiz,
  formik,
  handleCopyQuiz,
  setModalOpened,
  handleNewQuiz,
  handleSaveInfraction,
  handleSaveDefence,
  handleDefenceChange,
}) => {
  const onModalClose = useCallback(() => {
    setModalOpened(false);
    formik.resetForm();
  }, [formik, setModalOpened]);

  const handleDelete = useCallback(() => {
    if (isNotNewQuiz(currentQuiz)) {
      deleteQuiz(currentQuiz);
    }
  }, [currentQuiz, deleteQuiz]);

  const isSwitchDisabled =
    !currentQuiz || !currentQuiz.defence || currentQuiz.defence.length < 30;

  return (
    <div className={clsx('page', styles['defence-quizzes-details'])}>
      <Header />
      {infractionCode && (
        <>
          <div
            key={infractionCode.code}
            className={clsx(styles['page-content'])}
          >
            <Head infractionCode={infractionCode} goBack={goBack} />
            <div className={styles['defence-head-divider']} />
            <div className={styles['quizzes-list']}>
              {infractionCode.quizzes &&
                infractionCode.quizzes.map((quiz) => (
                  <QuizItem
                    key={quiz.id}
                    isSelected={
                      isNotNewQuiz(currentQuiz) && currentQuiz?.id === quiz.id
                    }
                    handleCopyQuiz={handleCopyQuiz}
                    setModalOpened={setModalOpened}
                    setCurrentQuiz={setCurrentQuiz}
                    deleteQuiz={handleDelete}
                    quiz={quiz}
                  />
                ))}
            </div>
            <button
              onClick={handleNewQuiz}
              className={styles['add-new-quiz']}
              type="button"
            >
              + Create Defense
            </button>
            <div className={styles['defence-section']}>
              <div className={styles['defence-header']}>
                <h3>Defense</h3>
                <div className={styles['automated-switch']}>
                  <label
                    htmlFor={`automated-checkbox-${currentQuiz?.name}`}
                    className={styles.switch}
                  >
                    <input
                      onClick={handleSaveInfraction}
                      checked={currentQuiz?.automated}
                      id={`automated-checkbox-${currentQuiz?.name}`}
                      disabled={isSwitchDisabled}
                      type="checkbox"
                    />
                    <span className={clsx(styles.slider, styles.round)} />
                  </label>
                  <div>Automated</div>
                </div>
              </div>
              <EditableDefence
                saveDefence={handleSaveDefence}
                handleDefenceChange={handleDefenceChange}
                defence={currentQuiz?.defence}
              />
            </div>
            <div className={styles['questions-list']}>
              {!currentQuiz?.questions ? (
                <div className={styles['no-files']}>
                  <ReactSVG src={noQuestions} />
                  <span>This defense doesn&apos;t have a quiz</span>
                </div>
              ) : (
                <>
                  <h3>Questions</h3>
                  <div className={styles.questions}>
                    {currentQuiz &&
                      isNotNewQuiz(currentQuiz) &&
                      currentQuiz.questions &&
                      currentQuiz.questions
                        .sort((a, b) => a.orderNumber - b.orderNumber)
                        .map((quiz, index) => (
                          <QuestionItem
                            // eslint-disable-next-line react/no-array-index-key
                            key={quiz.question + index}
                            question={quiz}
                          />
                        ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <QuizModal
            formik={formik}
            open={modelOpened}
            onRequestClose={onModalClose}
            currentQuiz={currentQuiz}
          />
        </>
      )}
    </div>
  );
};

export default View;
