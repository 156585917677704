enum SubscriptionStatusType {
  daily = 'Daily',
  monthly = 'Monthly',
  annual = 'Annual',
}

const SubscriptionTypeLabel = {
  [SubscriptionStatusType.daily]: 'Daily',
  [SubscriptionStatusType.monthly]: 'Monthly',
  [SubscriptionStatusType.annual]: 'Annual',
};

const subscriptionTypes = [
  SubscriptionStatusType.daily,
  SubscriptionStatusType.monthly,
  SubscriptionStatusType.annual,
];

export { SubscriptionTypeLabel, subscriptionTypes };

export default SubscriptionStatusType;
