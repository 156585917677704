import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';

import ArrowIcon from '@assets/arrow.svg';
import CheckmarkIcon from '@assets/checkmark.svg';
import CaseStatus from '@components/CaseStatus';
import violationsActions from '@redux/violations/actions';
import { CaseStatusKeyToType } from '@typings/enums';

import styles from './styles.module.scss';

const CaseStatusSelect: React.FC = () => {
  const selectRef = useRef<HTMLDivElement>(null);

  const { caseStatusKeys, params } = useSelector(
    (state: ReduxState) => state.violations,
  );
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<CaseStatusTypeKey[]>([]);

  const loadViolations = useCallback(
    (case_statuses: CaseStatusTypeKey[]) => {
      dispatch(
        violationsActions.filter({
          ...params,
          case_statuses: (case_statuses as unknown) as CaseStatusType[],
        }),
      );
    },
    [dispatch, params],
  );

  const handleClickOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        selectRef.current &&
        !(selectRef.current as Element).contains(event.target as Node)
      ) {
        handleClickOpen();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef, isOpen, handleClickOpen]);

  const caseStatusOptions = useMemo(
    () =>
      caseStatusKeys.map((value) => ({
        value,
        label: <CaseStatus type={CaseStatusKeyToType[value] || value} />,
      })),
    [caseStatusKeys],
  );

  const handleCheckOption = useCallback(
    (key: CaseStatusTypeKey) => () => {
      const updatedValue = selected.includes(key)
        ? selected.filter((item) => item !== key)
        : [...selected, key];

      loadViolations(updatedValue);
      setSelected(updatedValue);
    },
    [loadViolations, selected.length],
  );

  return (
    <div ref={selectRef} className={styles.container}>
      <button
        onClick={handleClickOpen}
        type="button"
        className={styles['select-head']}
      >
        <span>Case status</span>
        <span className={styles['selected-indicator']}>
          {selected.length > 0 ? selected.length : ''}
        </span>
        <ReactSVG
          src={ArrowIcon}
          className={clsx('svg', styles.arrow, isOpen && styles.open)}
        />
      </button>
      <div className={clsx(styles.list, isOpen && styles.open)}>
        {caseStatusOptions.map(({ label, value }) => (
          <button
            onClick={handleCheckOption(value)}
            className={styles['status-item']}
            type="button"
            key={value}
          >
            {label}
            <ReactSVG
              src={CheckmarkIcon}
              className={clsx(
                'svg',
                styles.checkmark,
                selected.includes(value) && styles.visible,
              )}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default React.memo(CaseStatusSelect);
