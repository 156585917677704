import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import { ReactSVG } from 'react-svg';

import UsersIcon from '@assets/users.svg';
import colors from '@constants/colors';

import styles from './styles.module.scss';

interface ChartColumnProps {
  count?: number;
  percentage?: number;
  state: string;
}

interface Props {
  className?: string;
  data?: FeedbackChartResult[];
}

const ChartColumn: React.FC<ChartColumnProps> = ({
  count = 0,
  percentage = 0,
  state,
}) => {
  const backgroundImage = `linear-gradient(to top, ${colors.royalBlue} 50%, ${colors.selagoDark} 50%)`;
  const stateRef = useRef<HTMLDivElement | null>(null);

  // Animation
  useEffect(() => {
    const stateBox = stateRef.current;

    if (!stateBox) return;

    stateBox.style.transition = 'none';
    stateBox.style.backgroundPositionY = '0%';
    // eslint-disable-next-line no-void
    void stateBox.offsetWidth; // Trigger reflow
    stateBox.style.removeProperty('transition');
    stateBox.style.backgroundPositionY = `${percentage}%`;
  }, [percentage]);

  return (
    <div className={styles.col}>
      <div className={styles.state} style={{ backgroundImage }} ref={stateRef}>
        {state}
      </div>
      <CountUp
        className={styles.percentage}
        end={Math.round(percentage)}
        duration={0.5}
        suffix="%"
      />
      <div className={styles['count-row']}>
        <CountUp
          className={styles.count}
          end={count}
          duration={0.5}
          suffix={count === 1 ? ' tester' : ' testers'}
        />
        <ReactSVG
          src={UsersIcon}
          className={clsx('svg', styles['users-icon'])}
        />
      </div>
    </div>
  );
};

const YesNoChart: React.FC<Props> = ({ className, data }) => {
  const yesData = data?.find((r) => r.result === true);
  const noData = data?.find((r) => r.result === false);

  return (
    <div className={clsx(styles['chart-container'], className)}>
      <ChartColumn
        state="YES"
        percentage={yesData?.percentage}
        count={yesData?.count}
      />
      <div className={clsx(styles.col, styles.slash)}>/</div>
      <ChartColumn
        state="NO"
        percentage={noData?.percentage}
        count={noData?.count}
      />
    </div>
  );
};

export default YesNoChart;
