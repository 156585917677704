import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useCallback } from 'react';

import UserRoleForm from '@common/UserRoleForm';
import BigCheckbox from '@components/BigCheckbox';
import Input from '@components/Input';
import Select, { ValueType } from '@components/Select';
import { WordStrBoolean, WordToBoolean } from '@typings/enums';

import sharedStyles from '../styles.module.scss';

import caseTypeOptions from './caseTypeOptions';
import FormData from './form';

interface Props {
  errors: FormikErrors<FormData>;
  form: FormData;
  touched: FormikTouched<FormData>;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  setFieldValue<F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
    shouldValidate?: boolean,
  ): void;
}

const GeneralInfo: React.FC<Props> = ({
  form,
  touched,
  errors,
  onChange,
  setFieldValue,
}) => {
  const onCaseTypeChange = useCallback(
    (e: ValueType<CaseType>) => setFieldValue('case_type', e, false),
    [setFieldValue],
  );

  return (
    <>
      <div className={sharedStyles.section}>
        <div className={clsx(sharedStyles.inputs, sharedStyles['two-columns'])}>
          <Select
            value={form.case_type}
            options={caseTypeOptions}
            onChange={onCaseTypeChange}
            label="Case Type"
            labelAsterisk
            error={touched.case_type && errors.case_type}
          />
          <Input
            type="text"
            name="summons_number"
            value={form.summons_number}
            onChange={onChange}
            label="Summons Number"
            labelAsterisk
            placeholder="000 000 000"
            required
            maxLength={40}
            error={touched.summons_number && errors.summons_number}
          />
        </div>
      </div>
      <div className={sharedStyles.section}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            Name of person completing this form
          </h2>
        </div>
        <div
          className={clsx(
            sharedStyles['sub-section'],
            sharedStyles.inputs,
            sharedStyles['two-columns'],
          )}
        >
          <Input
            type="text"
            name="first_name"
            value={form.first_name}
            onChange={onChange}
            label="Your First Name"
            labelAsterisk
            placeholder="e.g. John"
            maxLength={40}
            required
            error={touched.first_name && errors.first_name}
          />
          <Input
            type="text"
            name="last_name"
            value={form.last_name}
            onChange={onChange}
            label="Your Last Name"
            labelAsterisk
            placeholder="e.g. Millere"
            maxLength={40}
            required
            error={touched.last_name && errors.last_name}
          />
        </div>
        <div className={sharedStyles['sub-section']}>
          <div
            className={clsx(
              sharedStyles['input-wrapper'],
              !WordToBoolean[form.does_name_appear_on_ticket] &&
                !WordToBoolean[form.does_name_appear_on_ticket] &&
                sharedStyles['with-margin'],
            )}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="input-label">
              Does your name appear on the ticket?
              <span className="asterisk" />
            </label>
            <div
              className={clsx(sharedStyles.inputs, sharedStyles['big-radios'])}
            >
              <BigCheckbox
                type="radio"
                name="does_name_appear_on_ticket"
                value={WordStrBoolean.true}
                checked={WordToBoolean[form.does_name_appear_on_ticket]}
                onChange={onChange}
                containerClassName={sharedStyles['big-radio']}
              >
                Yes
              </BigCheckbox>
              <BigCheckbox
                type="radio"
                name="does_name_appear_on_ticket"
                value={WordStrBoolean.false}
                checked={!WordToBoolean[form.does_name_appear_on_ticket]}
                onChange={onChange}
                containerClassName={sharedStyles['big-radio']}
              >
                No
              </BigCheckbox>
            </div>
          </div>
          <UserRoleForm
            hide={WordToBoolean[form.does_name_appear_on_ticket]}
            who_you_are={form.who_you_are}
            who_asked_you_to_make_request={form.who_asked_you_to_make_request}
            persons_relationship_to_the_named_respondent={
              form.persons_relationship_to_the_named_respondent
            }
            relationship={form.relationship}
            are_you_authorized_to_represent_respondent={
              form.are_you_authorized_to_represent_respondent
            }
            onChange={onChange}
            touched={touched}
            errors={errors}
            radioLabelClassName={sharedStyles['radio-label']}
            inputWrapperClassName={sharedStyles['input-wrapper']}
            withMarginClassName={sharedStyles['with-margin']}
            inputsClassName={sharedStyles.inputs}
            bigRadiosClassName={sharedStyles['big-radios']}
            bigRadioClassName={sharedStyles['big-radio']}
          />
        </div>
      </div>
    </>
  );
};

export default GeneralInfo;
