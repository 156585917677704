enum State {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  DistrictOfColumbia = 'District of Columbia',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  NewHampshire = 'New Hampshire',
  NewJersey = 'New Jersey',
  NewMexico = 'New Mexico',
  NewYork = 'New York',
  NorthCarolina = 'North Carolina',
  NorthDakota = 'North Dakota',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Pennsylvania = 'Pennsylvania',
  RhodeIsland = 'Rhode Island',
  SouthCarolina = 'South Carolina',
  SouthDakota = 'South Dakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  Virginia = 'Virginia',
  Washington = 'Washington',
  WestVirginia = 'West Virginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming',
}

const StateLabel = {
  [State.Alabama]: 'Alabama',
  [State.Alaska]: 'Alaska',
  [State.Arizona]: 'Arizona',
  [State.Arkansas]: 'Arkansas',
  [State.California]: 'California',
  [State.Colorado]: 'Colorado',
  [State.Connecticut]: 'Connecticut',
  [State.Delaware]: 'Delaware',
  [State.DistrictOfColumbia]: 'District of Columbia',
  [State.Florida]: 'Florida',
  [State.Georgia]: 'Georgia',
  [State.Hawaii]: 'Hawaii',
  [State.Idaho]: 'Idaho',
  [State.Illinois]: 'Illinois',
  [State.Indiana]: 'Indiana',
  [State.Iowa]: 'Iowa',
  [State.Kansas]: 'Kansas',
  [State.Kentucky]: 'Kentucky',
  [State.Louisiana]: 'Louisiana',
  [State.Maine]: 'Maine',
  [State.Maryland]: 'Maryland',
  [State.Massachusetts]: 'Massachusetts',
  [State.Michigan]: 'Michigan',
  [State.Minnesota]: 'Minnesota',
  [State.Mississippi]: 'Mississippi',
  [State.Missouri]: 'Missouri',
  [State.Montana]: 'Montana',
  [State.Nebraska]: 'Nebraska',
  [State.Nevada]: 'Nevada',
  [State.NewHampshire]: 'New Hampshire',
  [State.NewJersey]: 'New Jersey',
  [State.NewMexico]: 'New Mexico',
  [State.NewYork]: 'New York',
  [State.NorthCarolina]: 'North Carolina',
  [State.NorthDakota]: 'North Dakota',
  [State.Ohio]: 'Ohio',
  [State.Oklahoma]: 'Oklahoma',
  [State.Oregon]: 'Oregon',
  [State.Pennsylvania]: 'Pennsylvania',
  [State.RhodeIsland]: 'Rhode Island',
  [State.SouthCarolina]: 'South Carolina',
  [State.SouthDakota]: 'South Dakota',
  [State.Tennessee]: 'Tennessee',
  [State.Texas]: 'Texas',
  [State.Utah]: 'Utah',
  [State.Vermont]: 'Vermont',
  [State.Virginia]: 'Virginia',
  [State.Washington]: 'Washington',
  [State.WestVirginia]: 'West Virginia',
  [State.Wisconsin]: 'Wisconsin',
  [State.Wyoming]: 'Wyoming',
};

const states = Object.values(State);

export { StateLabel, states };

export default State;
