import { format } from 'date-fns';
import { FormikErrors } from 'formik';

import { requiredValidate, someCheckbox, validate } from '@services/validation';

import { ReasonForGranted } from './radios';

interface FormData {
  date_respondent_learn_about_summons: string;
  explain_circumstances?: string;
  how_did_respondent_learn_about_summons: string;
  reason_for_which_new_hearing_should_be_granted: ReasonForGranted;
  reasonable_excuse: [
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
  ];
  reasonable_excuse_explain?: string;

  respondent_connection_to_the_property?: string;
}

export const formInitial: FormData = {
  date_respondent_learn_about_summons: format(new Date(), 'Y-MM-d'),
  how_did_respondent_learn_about_summons: 'By receipt of the default notice',
  reason_for_which_new_hearing_should_be_granted: ReasonForGranted.firstRequest,

  reasonable_excuse: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],

  respondent_connection_to_the_property: '',
  reasonable_excuse_explain: '',
  explain_circumstances: '',
};

export const validateForm = ({
  date_respondent_learn_about_summons,
  how_did_respondent_learn_about_summons,
  reason_for_which_new_hearing_should_be_granted,

  reasonable_excuse,

  explain_circumstances,
  reasonable_excuse_explain,
  respondent_connection_to_the_property,
}: FormData): FormikErrors<FormData> => ({
  date_respondent_learn_about_summons: validate(
    date_respondent_learn_about_summons,
    requiredValidate,
  ),
  how_did_respondent_learn_about_summons: validate(
    how_did_respondent_learn_about_summons,
    requiredValidate,
  ),
  reason_for_which_new_hearing_should_be_granted: validate(
    reason_for_which_new_hearing_should_be_granted,
    requiredValidate,
  ),

  reasonable_excuse:
    reason_for_which_new_hearing_should_be_granted ===
    ReasonForGranted.moreThan75Days
      ? validate<boolean[]>(
          reasonable_excuse,
          someCheckbox(...reasonable_excuse),
        )
      : undefined,

  respondent_connection_to_the_property:
    reason_for_which_new_hearing_should_be_granted ===
      ReasonForGranted.moreThan75Days && reasonable_excuse[6]
      ? validate(respondent_connection_to_the_property, requiredValidate)
      : undefined,

  reasonable_excuse_explain:
    reason_for_which_new_hearing_should_be_granted ===
      ReasonForGranted.moreThan75Days && reasonable_excuse[8]
      ? validate(reasonable_excuse_explain, requiredValidate)
      : undefined,

  explain_circumstances:
    reason_for_which_new_hearing_should_be_granted ===
    ReasonForGranted.moreThanOneYear
      ? validate(explain_circumstances, requiredValidate)
      : undefined,
});

export const formKeys: (keyof FormData)[] = [
  'date_respondent_learn_about_summons',
  'how_did_respondent_learn_about_summons',
  'reason_for_which_new_hearing_should_be_granted',
  'reasonable_excuse',
  'respondent_connection_to_the_property',
  'explain_circumstances',
  'reasonable_excuse_explain',
];

export default FormData;
