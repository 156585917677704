import { isSimple } from './utils'

interface HandleCheckboxChangeConfig<N extends string = string> {
  setFieldTouched?: (
    field: N,
    touched: boolean,
    shouldValidate?: boolean
  ) => void
}

export const handleCheckboxChange = <F extends string = string>(
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  { setFieldTouched }: HandleCheckboxChangeConfig<F> = {}
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  setFieldTouched?.(event.target.name as F, true)
  onChange(event)
}

export const toFormData = (value: unknown, key = '', form = new FormData()) => {
  if (isSimple(value)) {
    form.append(key, String(value))
  } else if (value instanceof File) {
    form.append(key, value, value.name)
  } else if (Array.isArray(value)) {
    for (const item of value) {
      toFormData(item, `${key}[]`, form)
    }
  } else if (value && typeof value === 'object') {
    key = key ? `${key}.` : key

    for (const itemKey of Object.keys(value)) {
      toFormData(
        (value as Record<string, unknown>)[itemKey],
        `${key}${itemKey}`,
        form
      )
    }
  }

  return form
}

export default {
  handleCheckboxChange,
  toFormData,
}
