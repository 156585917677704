import axios, {
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';

import { isProduction } from '@config';
import globalActions from '@redux/actions';
import userActions from '@redux/user/actions';
import api from '@services/api';
import { dispatch, getState } from '@services/redux';

export const axiosRequestOnFulfilled = (config: InternalAxiosRequestConfig) => {
  console.log(`Sending request to ${config.baseURL}${config.url}`);
  console.log('config => ', config);

  return config;
};

export const apiRequest = (config: InternalAxiosRequestConfig) => {
  const newConfigs = { ...config };
  const token = getState().user.user?.accessToken;

  if (!newConfigs.headers) {
    newConfigs.headers = {} as AxiosRequestHeaders;
  }

  if (token) {
    newConfigs.headers.Authorization = `Bearer ${token}`;
  }

  newConfigs.headers['Content-Type'] =
    newConfigs.headers['Content-Type'] || 'application/json;charset=utf-8';
  newConfigs.headers.Accept = newConfigs.headers.Accept || 'application/json';

  return newConfigs;
};

export const apiResponseOnRejected = (error: unknown) => {
  if (isAxiosError(error) && error.response?.status === 401) {
    dispatch((userActions.logout() as unknown) as AnyAction);
  }

  return Promise.reject(error);
};

export const axiosRequestOnRejected = (error: unknown) => {
  console.log('Request error => ', error);

  return Promise.reject(error);
};

export const axiosResponseOnFulfilled = <T = unknown>(
  response: AxiosResponse<T>,
) => {
  console.log(
    'Received response from ',
    `${response.config.baseURL}${response.config.url}`,
  );
  console.log('response => ', response);

  return response;
};

export const axiosResponseOnRejected = <T = unknown>(error: T) => {
  console.log('Response error => ', error);

  return Promise.reject(error);
};

export const configureAxios = () => {
  if (isProduction) return undefined;

  const request = axios.interceptors.request.use(
    axiosRequestOnFulfilled,
    axiosRequestOnRejected,
  );
  const response = axios.interceptors.response.use(
    axiosResponseOnFulfilled,
    axiosResponseOnRejected,
  );

  return () => {
    axios.interceptors.request.eject(request);
    axios.interceptors.response.eject(response);
  };
};

export const configureApi = (logs = false) => {
  const apiReq = api.interceptors.request.use(apiRequest);
  const apiRes = api.interceptors.response.use(
    undefined,
    apiResponseOnRejected,
  );

  let request: number | undefined;
  let response: number | undefined;

  if (!isProduction && logs) {
    request = api.interceptors.request.use(
      axiosRequestOnFulfilled,
      axiosRequestOnRejected,
    );
    response = api.interceptors.response.use(
      axiosResponseOnFulfilled,
      axiosResponseOnRejected,
    );
  }

  return () => {
    if (request) api.interceptors.request.eject(request);

    if (response) api.interceptors.response.eject(response);

    api.interceptors.request.eject(apiReq);
    api.interceptors.response.eject(apiRes);
  };
};

export const configureReduxConsole = () => {
  if (isProduction) return undefined;

  const reset = () => globalActions.reset()(dispatch, getState, undefined);

  const update = (path: string[], value: unknown) =>
    globalActions.update(path, value)(dispatch, getState, undefined);

  window.resetRedux = reset;
  window.updateRedux = update;
  window.dispatchRedux = dispatch;
  window.getReduxState = getState;

  return () => {
    delete window.resetRedux;
    delete window.updateRedux;
    delete window.dispatchRedux;
    delete window.getReduxState;
  };
};

export const configureScrollbarPadding = () => {
  document.body.style.paddingRight = `${
    window.innerWidth - document.documentElement.clientWidth
  }px`;
};

export default {
  configureAxios,
  configureApi,
  configureReduxConsole,
  configureScrollbarPadding,
};
