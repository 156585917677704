import { FormikErrors } from 'formik';

import { requiredValidate, validate } from '@services/validation';
import { FormAuthorRoleType, WordStrBoolean } from '@typings/enums';

interface FormData {
  are_you_authorized_to_represent_respondent?: WordStrBoolean;
  persons_relationship_to_the_named_respondent?: string;
  relationship?: string;
  who_asked_you_to_make_request?: string;
  who_you_are?: FormAuthorRoleType[] | FormAuthorRoleType;
}

export const validateForm = (namedAsRespondent = true) => ({
  who_you_are,
  who_asked_you_to_make_request,
  persons_relationship_to_the_named_respondent,
  are_you_authorized_to_represent_respondent,
  relationship,
}: FormData): FormikErrors<FormData> =>
  namedAsRespondent
    ? {}
    : {
        who_you_are:
          who_you_are?.length === 0 ? 'Select at least one' : undefined,
        who_asked_you_to_make_request: validate(
          who_asked_you_to_make_request,
          requiredValidate,
        ),
        persons_relationship_to_the_named_respondent: validate(
          persons_relationship_to_the_named_respondent,
          requiredValidate,
        ),
        are_you_authorized_to_represent_respondent: validate(
          are_you_authorized_to_represent_respondent,
          requiredValidate,
        ),
        relationship: who_you_are?.includes(FormAuthorRoleType.other)
          ? validate(relationship, requiredValidate)
          : undefined,
      };

export default FormData;
