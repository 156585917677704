import clsx from 'clsx';
import React from 'react';
import { ReactSVG } from 'react-svg';

import Copy from '@assets/copy.svg';
import Delete from '@assets/delete.svg';
import Edit from '@assets/edit.svg';

import styles from '../styles.module.scss';

interface Props {
  isSelected: boolean;
  quiz: Quiz;
  setCurrentQuiz: React.Dispatch<React.SetStateAction<Quiz | NewQuiz | null>>;
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  deleteQuiz(): void;
  handleCopyQuiz(quiz: Quiz): void;
}

const QuizItem: React.ComponentType<Props> = ({
  isSelected,
  setModalOpened,
  setCurrentQuiz,
  handleCopyQuiz,
  deleteQuiz,
  quiz,
}) => {
  const handleSetQuiz = () => {
    setCurrentQuiz(quiz);
  };

  const copyQuiz = () => {
    handleCopyQuiz(quiz);
  };

  const openEditModal = () => {
    setCurrentQuiz(quiz);
    setModalOpened(true);
  };

  return (
    <div className={styles['quiz-item']}>
      <div className={styles.header}>
        <button onClick={handleSetQuiz} className={styles.title} type="button">
          {quiz.name}
        </button>
        {isSelected && (
          <div className={styles['control-panel']}>
            <button type="button" onClick={copyQuiz}>
              <ReactSVG src={Copy} className={clsx('svg', styles.logo)} />
            </button>
            <button type="button" onClick={openEditModal}>
              <ReactSVG src={Edit} className={clsx('svg', styles.logo)} />
            </button>
            <button type="button" onClick={deleteQuiz}>
              <ReactSVG src={Delete} className={clsx('svg', styles.logo)} />
            </button>
          </div>
        )}
      </div>
      {quiz.defence && <div className={styles.subtitle}>Defense added</div>}
      {!quiz.defence && (
        <div className={styles['subtitle-active']}>Waiting for defense</div>
      )}
    </div>
  );
};

export default QuizItem;
