import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>;

const BuildingHeadRow: React.FC<Props> = ({ className, ...props }) => {
  return (
    <tr
      {...props}
      className={clsx(
        'tr',
        styles['head-row'],
        styles[`head-row-building`],
        className,
      )}
    >
      <td>
        <p className={styles.address}>Building</p>
      </td>
      <td>
        <p className={styles.status}>Subscription status</p>
      </td>
      <td>
        <p className={styles['additional-info']}>Additional info</p>
      </td>
      <td>
        <p className={styles['hired-cases']}>Hired cases</p>
      </td>
    </tr>
  );
};

export default BuildingHeadRow;
