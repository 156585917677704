import clsx from 'clsx';
import React from 'react';

import SortButton from '@common/SortButton';

import styles from './styles.module.scss';

type ClickHandler = React.MouseEventHandler<HTMLButtonElement>;

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
> & {
  onParticipantClick?: ClickHandler;
  onRespondedAtClick?: ClickHandler;
  onResponseClick?: ClickHandler;
  showMessageColumn?: boolean;
};

const ResponseHeadRow: React.FC<Props> = ({
  onParticipantClick,
  onResponseClick,
  onRespondedAtClick,
  className,
  showMessageColumn,
  ...props
}) => {
  return (
    <tr {...props} className={clsx('tr', styles['head-row'], className)}>
      <td className={styles['participant-col']}>
        <SortButton onClick={onParticipantClick} className={styles.participant}>
          Participant
        </SortButton>
      </td>
      <td>
        <SortButton onClick={onResponseClick} className={styles.result}>
          Response
        </SortButton>
      </td>
      <td className={styles['message-col']}>
        {showMessageColumn && <p>Message</p>}
      </td>
      <td>
        <SortButton
          onClick={onRespondedAtClick}
          className={styles['responded-at']}
        >
          Responded at
        </SortButton>
      </td>
    </tr>
  );
};

export default ResponseHeadRow;
