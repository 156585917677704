import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import pages from '@routes/pages';
import paths from '@routes/paths';

const Routes: React.FC = () => (
  <Router forceRefresh>
    <Switch>
      <Route exact path={paths.home} component={pages.Home} />

      <Route exact path={paths.login} component={pages.Login} />
      <Route exact path={paths.noAccess} component={pages.NoAccess} />
      <Route
        exact
        path={paths.forgotPassword}
        component={pages.ForgotPassword}
      />
      <Route exact path={paths.resetPassword} component={pages.ResetPassword} />

      <Route exact path={paths.defences} component={pages.Defences} />
      <Route
        exact
        path={paths.defenceQuizzes}
        component={pages.DefencesQuizzes}
      />

      <Route
        exact
        path={paths.hiredViolations}
        component={pages.HiredViolations}
      />
      <Route
        exact
        path={paths.closedViolations}
        component={pages.ClosedViolations}
      />
      <Route
        exact
        path={paths.attendedViolations}
        component={pages.AttendedViolations}
      />
      <Route
        exact
        path={paths.unhiredViolations}
        component={pages.UnhiredViolations}
      />

      <Route
        exact
        path={paths.violationDetails}
        component={pages.ViolationDetails}
      />

      <Route
        exact
        path={paths.violationDetails}
        component={pages.ViolationDetails}
      />

      <Route exact path={paths.buildings} component={pages.Buildings} />
      <Route
        exact
        path={paths.violationHearingForm}
        component={pages.ViolationHearingForm}
      />

      <Route exact path={paths.payments} component={pages.Payments} />

      <Route exact path={paths.users} component={pages.Users} />
      <Route exact path={paths.userDetails} component={pages.UserDetails} />

      <Route exact path={paths.responses} component={pages.Responses} />

      <Route exact path={paths.anyPath} component={pages.NotFound} />
    </Switch>
  </Router>
);

export default Routes;
