import clsx from 'clsx';
import React from 'react';

import useBoolean from '@hooks/useBoolean';

import styles from '../styles.module.scss';

const placeholder =
  'Please double click here and enter text of defence.' +
  ' When you need to place answer for quiz question in text add {Q1}' +
  ' with appropriate number after Q and it would be auto replaced later';

interface Props {
  defence: string | null | undefined;
  handleDefenceChange(defence: string): void;
  saveDefence(): void;
}

const EditableDefence: React.FC<Props> = ({
  defence,
  saveDefence,
  handleDefenceChange,
}) => {
  const { value: editing, setFalse, setTrue } = useBoolean();

  const onDefenceEdit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = event;

    handleDefenceChange(value);
  };

  const clearDefense = () => handleDefenceChange('');

  return (
    <div
      onDoubleClick={setTrue}
      onBlur={setFalse}
      className={styles['defence-section']}
    >
      {editing && (
        <textarea placeholder={placeholder} onChange={onDefenceEdit} rows={30}>
          {defence}
        </textarea>
      )}
      {!editing && <p>{defence || placeholder}</p>}
      <div className={styles['control-panel']}>
        <button
          onClick={clearDefense}
          className={styles['clear-button']}
          type="button"
        >
          Clear
        </button>
        <button
          onClick={setFalse}
          className={styles['cancel-button']}
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={saveDefence}
          className={clsx(styles['save-button'], {
            [styles.disabled]: !defence || defence.length < 30,
          })}
          type="button"
        >
          Save Defense
        </button>
      </div>
    </div>
  );
};

export default EditableDefence;
