import clsx from 'clsx';
import React, { useMemo } from 'react';

import PagingFooter from '@common/PagingFooter';
import { FeedbackStatsSortType } from '@typings/enums';

import ResponseHeadRow from './head-row';
import ResponseRow from './row';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  isLoading: boolean;
  load: (params: PagingParams) => void;
  meta?: ApiPagingMeta;
  responses: FeedbackResponse[];
  sort: (type?: FeedbackStatsSortType) => () => void;
  showMessage?: boolean;
};

const ResponsesTable: React.FC<Props> = ({
  className,
  isLoading,
  meta,
  responses,
  showMessage,
  load,
  sort,
}) => {
  const tableBody = useMemo(
    () =>
      responses.map((response, idx) => (
        <ResponseRow
          // eslint-disable-next-line react/no-array-index-key
          key={`${response.user_id}-${idx}`}
          response={response}
          showMessage={showMessage}
        />
      )),
    [responses, showMessage],
  );

  if (!responses.length && meta?.currentPage === 1) {
    return null;
  }

  return (
    <section className={clsx(styles['table-section'], className)}>
      <div className={styles.heading}>
        <h2>Responses</h2>
      </div>
      <div className={styles['table-container']}>
        <table className={styles.table}>
          <thead className={styles['table-head']}>
            <ResponseHeadRow
              onParticipantClick={sort(FeedbackStatsSortType.name)}
              onResponseClick={sort(FeedbackStatsSortType.result)}
              onRespondedAtClick={sort(FeedbackStatsSortType.created_at)}
              showMessageColumn={showMessage}
            />
          </thead>
          <tbody className={styles['table-body']}>{tableBody}</tbody>
        </table>
        <PagingFooter
          availablePerPage={[10, 20, 30]}
          isLoading={isLoading}
          page={meta?.currentPage}
          perPage={meta?.perPage}
          totalPages={meta?.lastPage}
          onChange={load}
        />
      </div>
    </section>
  );
};

export default React.memo(ResponsesTable);
