import { ReasonableExcuseType } from '@typings/enums';

interface CheckboxInfo {
  label: string;
  name: ReasonableExcuseType;
  tooltip?: string;
}

const checkboxes: CheckboxInfo[] = [
  {
    name: ReasonableExcuseType.notReceive,
    label:
      'The Respondent did not receive the summons because the issuing agency did not serve the summons correctly',
    tooltip:
      "If the summons names the property owner or owner's agent as Respondent, attach a copy of a New York City " +
      'tax bill and/or multiple dwelling registration forms (if applicable) for the building for the year during ' +
      "which the summons was issued.\nIf the summons does NOT name the property owner or owner's agent, attach " +
      "proof of respondent's mailing address at the time the summons was issued (for example, a driver's license, " +
      'permit, or an authorization to collect sales taxes).',
  },
  {
    name: ReasonableExcuseType.generalTitle,
    label:
      'The summons identifies the respondent as "Owner", "Agent", "Condo President" or another general title',
    tooltip:
      'Do not check this box if the summons names a person, business, corporation, organization or other entity.',
  },
  {
    name: ReasonableExcuseType.respondentDied,
    label: 'The Respondent died on or before the hearing date',
    tooltip:
      'This applies only when the deceased is the person who is named as Respondent on the summons. ' +
      'If so, upload a copy of the death certificate of the named Respondent.',
  },
  {
    name: ReasonableExcuseType.legallyIncompetent,
    label: 'The Respondent was legally incompetent at the time of the hearing',
    tooltip:
      'If this reason applies, upload a copy of a court order stating that the Respondent was incompetent.',
  },
  {
    name: ReasonableExcuseType.formerOwner,
    label:
      'The Respondent owned the place of occurrence at one ' +
      'time, but sold or transferred it before the date of the ' +
      'summons and the summons describes a violation that involves a building or specific property',
    tooltip:
      'This reason does NOT apply if the summons is issued for a violation that does not relate to a ' +
      'building or property; for example, illegal posting of handbills, littering, vending or tickets ' +
      'that name building managers, tenants, contractors, or other people working at a property.\n' +
      'If this reason applies, upload proof of the transfer (for example, a deed or Real Property ' +
      'Transfer Tax Form). Total file size cannot exceed 3Mb.',
  },
  {
    name: ReasonableExcuseType.neverOwned,
    label: 'Respondent never owned the place of occurrence',
    tooltip:
      'If this reason applies, upload supporting documents (for example, proof that someone ' +
      'else owned the building or property at the time the summons was issued).',
  },
  {
    name: ReasonableExcuseType.formerAgent,
    label:
      'The Respondent is a former agent, tenant or person in control ' +
      'of the property where the violation occurred, but was no longer ' +
      'an agent, tenant or person in control at the time the ticket was issued',
  },
  {
    name: ReasonableExcuseType.emergency,
    label:
      'The Respondent had an emergency requiring immediate medical or other attention',
    tooltip:
      'If this reason applies, upload a copy of any relevant documentation.',
  },
  { name: ReasonableExcuseType.other, label: 'Other' },
];

export default checkboxes;
