import { isAxiosError } from 'axios';

import { apiRoutes } from '@config';
import action from '@redux/action';
import api from '@services/api';

import ActionType from './types';

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading));

const dispatchError = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error));

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET));

const login = (
  email: string,
  password: string,
  remember?: boolean,
  onSuccess?: (user: WebUser) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<WebUser> => async (dispatch) => {
  dispatch(action(ActionType.LOGIN_BEGIN));

  // todo: add remember me functionality
  console.log('remember =>', remember);

  try {
    const response = await api.post<ApiSuccessResponse<WebUser>>(
      apiRoutes.login,
      {
        email,
        password,
        device_name: window.navigator.userAgent,
      },
    );

    onSuccess?.(response.data.data);
    dispatch(action(ActionType.LOGIN_SUCCESS, response.data.data));

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.LOGIN_ERROR, error));
      onError?.(error);
    }
  }

  return undefined;
};

const logout = (
  onSuccess?: () => void,
  onError?: (error: ErrorType) => void,
): AppThunk => async (dispatch) => {
  dispatch(action(ActionType.LOGOUT_BEGIN));

  const completeLogout = () => {
    localStorage.clear();
    dispatch(action(ActionType.LOGOUT_SUCCESS));
    onSuccess?.();
  };

  try {
    await api.delete<ApiSuccessResponse<null>>(apiRoutes.logout);
    completeLogout();
  } catch (error) {
    if (!isAxiosError(error)) return;

    if (error.response?.status === 401) {
      completeLogout();
    } else {
      dispatch(action(ActionType.LOGOUT_ERROR, error));
      onError?.(error);
    }
  }
};

const forgotPassword = (
  email: string,
  onSuccess?: (email: string) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<string> => async (dispatch) => {
  dispatch(action(ActionType.FORGOT_PASSWORD_BEGIN));

  try {
    await api.post<ApiSuccessResponse>(apiRoutes.forgotPassword, {
      email,
    });

    dispatch(action(ActionType.FORGOT_PASSWORD_SUCCESS, email));
    onSuccess?.(email);

    return email;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.FORGOT_PASSWORD_ERROR, error));
      onError?.(error);
    }
  }

  return undefined;
};

const resetPassword = (
  password: string,
  url: string,
  onSuccess?: (email: WebUser) => void,
  onError?: (error: ErrorType) => void,
): AppThunk<WebUser> => async (dispatch) => {
  dispatch(action(ActionType.RESET_PASSWORD_BEGIN));

  try {
    const response = await api.post<ApiSuccessResponse<WebUser>>(
      apiRoutes.resetPassword,
      { password },
      {
        baseURL: url,
      },
    );

    dispatch(action(ActionType.RESET_PASSWORD_SUCCESS, response.data.data));
    onSuccess?.(response.data.data);

    return response.data.data;
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(action(ActionType.RESET_PASSWORD_ERROR, error));
      onError?.(error);
    }
  }

  return undefined;
};

const userActions = {
  loading,
  error: dispatchError,
  reset,
  login,
  logout,
  forgotPassword,
  resetPassword,
};

export default userActions;
