import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';

import PDFIcon from '@assets/pdf.svg';

import getParts, { Parts } from './parts';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  parts?: Parts;
  violation: ViolationDetails;
}

const ViolationBaseInfo: React.FC<Props> = ({
  violation,
  className,
  parts = {
    location: true,
    pdfBeforeSubmissionReopen: !!violation.formBeforeSubmissionReopen,
    reopenRequestSummaryUrl: !!violation.reopenRequestSummaryUrl,
    pdfBeforeSubmission: !!violation.formBeforeSubmission,
    rescheduleRequestSummaryUrl: !!violation.rescheduleRequestSummaryUrl,
    hearingSummaryUrl: !!violation.hearingSummaryUrl,
    ecbAuth: !!violation.ecbAuth,
    balance: true,
    saniFee: true,
  },
}) => {
  const { ticketImageLink, ticketNumber, ecbAuthUrl } = violation;

  const partsNodes = useMemo(() => getParts(parts, violation), [
    violation,
    parts,
  ]);

  return (
    <section className={clsx(styles['base-info'], className)}>
      <div className={styles.heading}>
        <a
          href={ticketImageLink || ''}
          className={clsx(
            styles['pdf-download'],
            !ticketImageLink && styles['pdf-download-disabled'],
          )}
          rel="noopener noreferrer"
          target="_blank"
        >
          <ReactSVG src={PDFIcon} className={clsx('svg', styles['pdf-icon'])} />
        </a>
        <div className={styles['ticket-number-container']}>
          <p className={styles.title}>Violation#</p>
          <h2 className={styles.number}>{ticketNumber}</h2>
        </div>
        {ecbAuthUrl && (
          <div className={styles['ticket-number-container']}>
            <a
              className={styles.title}
              target="_blank"
              rel="noreferrer"
              href={ecbAuthUrl}
            >
              ECB Auth Pdf
            </a>
          </div>
        )}
      </div>
      <div className={styles.content}>{partsNodes}</div>
    </section>
  );
};

export default ViolationBaseInfo;
