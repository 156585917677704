import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getSelectValue } from '@components/Select';
import TabNav from '@components/TabNav';
import TabsFooter from '@components/TabsFooter';
import violationsActions from '@redux/violations/actions';
import links from '@routes/links';
import { openNewHTML } from '@services/dom';
import { State, toStrBoolean } from '@typings/enums';

import Files from './Files';
import ReasonableExcuse from './ReasonableExcuse';
import reasonableExcuseCheckboxes from './ReasonableExcuse/checkboxes';
import { ReasonForGranted } from './ReasonableExcuse/radios';
import RespondentInfo from './RespondentInfo';
import SenderInfo from './SenderInfo';
import styles from './styles.module.scss';
import tabs, { TabValue, tabNavItems } from './tabs';
import useForm from './useForm';

const mapStateToProps = (state: ReduxState) => ({
  violation: state.violations.violation,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface NativeProps {
  className?: string;
}

type Props = NativeProps & ConnectedProps<typeof connector>;

const Form: React.FC<Props> = ({
  className,
  violation,
  user,
  violationsActions: { sendReopenRequest },
}) => {
  const [tab, setTab] = useState<TabValue>(TabValue.respondentInfo);

  const { replace } = useHistory();

  const {
    respondentInfoForm,
    senderInfoForm,
    reasonableExcuseForm,
    filesForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    errors,
  } = useForm(
    violation,
    user,
    ({
      summons_number,
      respondents_first_name,
      respondents_last_name,
      respondents_address,
      respondents_city,
      respondents_state,
      respondents_zip_code,
      respondents_phone,
      respondents_email,
      your_first_name,
      your_last_name,
      your_phone,
      your_email,
      your_address,
      your_city,
      your_state,
      your_zip_code,
      are_you_named_as_the_respondent_on_summons,
      who_you_are,
      who_asked_you_to_make_request,
      persons_relationship_to_the_named_respondent,
      are_you_authorized_to_represent_respondent,
      date_respondent_learn_about_summons,
      how_did_respondent_learn_about_summons,
      reason_for_which_new_hearing_should_be_granted,
      reasonable_excuse,
      respondent_connection_to_the_property,
      explain_circumstances,
      reasonable_excuse_explain,
      files,
      affirmation,
      certification,
    }) =>
      violation &&
      sendReopenRequest(
        {
          violation_id: String(violation.id),
          summons_number,
          respondents_first_name,
          respondents_last_name,
          respondents_address,
          respondents_city,
          respondents_state: getSelectValue(respondents_state) || State.NewYork,
          respondents_zip_code: String(respondents_zip_code),
          respondents_phone,
          respondents_email,
          your_first_name,
          your_last_name,
          your_phone,
          your_email,
          your_address,
          your_city,
          your_state: getSelectValue(your_state) || State.NewYork,
          your_zip_code: String(your_zip_code),
          are_you_named_as_the_respondent_on_summons,
          who_you_are,
          who_asked_you_to_make_request,
          persons_relationship_to_the_named_respondent,
          are_you_authorized_to_represent_respondent,
          date_respondent_learn_about_summons,
          how_did_respondent_learn_about_summons,
          reason_for_which_new_hearing_should_be_granted,

          reasonable_excuse: reasonable_excuse
            .map(
              (value, index) => value && reasonableExcuseCheckboxes[index].name,
            )
            .filter(Boolean) as string[],

          ...(reason_for_which_new_hearing_should_be_granted ===
            ReasonForGranted.moreThan75Days && reasonable_excuse[6]
            ? { respondent_connection_to_the_property }
            : {}),

          ...(reason_for_which_new_hearing_should_be_granted ===
            ReasonForGranted.moreThan75Days && reasonable_excuse[8]
            ? { reasonable_excuse_explain }
            : {}),

          ...(reason_for_which_new_hearing_should_be_granted ===
          ReasonForGranted.moreThanOneYear
            ? {
                explain_circumstances,
              }
            : {}),

          ...files.reduce(
            (acc, file, index) => ({ ...acc, [`file${index + 1}`]: file }),
            {},
          ),
          affirmation: toStrBoolean(affirmation),
          certification: toStrBoolean(certification),
        },
        (res) => {
          openNewHTML(res.defence);
          replace(
            violation
              ? links.violationDetails(violation.id)
              : links.violations(),
          );
        },
      ),
  );

  const form = useMemo(() => {
    switch (tab) {
      case TabValue.respondentInfo: {
        return (
          <RespondentInfo
            form={respondentInfoForm}
            touched={touched}
            errors={errors}
            onChange={handleChange}
            setFieldValue={setFieldValue}
          />
        );
      }

      case TabValue.senderInfo: {
        return (
          <SenderInfo
            form={senderInfoForm}
            touched={touched}
            errors={errors}
            onChange={handleChange}
            setFieldValue={setFieldValue}
          />
        );
      }

      case TabValue.reasonableExcuse: {
        return (
          <ReasonableExcuse
            form={reasonableExcuseForm}
            touched={touched}
            errors={errors}
            onChange={handleChange}
            setFieldValue={setFieldValue}
          />
        );
      }

      case TabValue.files: {
        return (
          <Files
            violation={violation}
            form={filesForm}
            touched={touched}
            errors={errors}
            onChange={handleChange}
            setFieldValue={setFieldValue}
          />
        );
      }

      default: {
        return null;
      }
    }
  }, [
    tab,
    handleChange,
    setFieldValue,
    respondentInfoForm,
    senderInfoForm,
    reasonableExcuseForm,
    filesForm,
    errors,
    touched,
  ]);

  const cancel = useCallback(
    () => violation && replace(links.violationDetails(violation?.id)),
    [violation, replace],
  );

  return (
    <div className={clsx(styles['form-container'], className)}>
      <TabNav
        tabs={tabNavItems}
        value={tab}
        setValue={setTab}
        className={styles['tab-nav']}
      />
      <form onSubmit={handleSubmit} className={styles.form}>
        {form}
        <TabsFooter tabs={tabs} tab={tab} setTab={setTab} cancel={cancel} />
      </form>
    </div>
  );
};

export default connector(Form) as React.FC<NativeProps>;
