import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import ButtonToggle, { ToggleOption } from '@components/ButtonToggle';
import Loader from '@components/Loader';
import { defaultPerPage } from '@config';
import { useAppSelector } from '@hooks/redux';
import useThreshold from '@hooks/useThreshold';
import feedbacksActions from '@redux/feedbacks/actions';
import { FeedbackQuestionGroup, FeedbackQuestionType } from '@typings/enums';

import ResponseListItem from '../ResponseListItem';

import styles from './styles.module.scss';

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  feedbacksActions: bindActionCreators<
    typeof feedbacksActions,
    BindedAsyncActions<typeof feedbacksActions>
  >(feedbacksActions, dispatch),
});

const connector = connect(null, mapDispatchToProps);

type NativeProps = {
  className?: string;
  selectedId?: number;
  onSelect(id: number | null): void;
};

type Props = NativeProps & ConnectedProps<typeof connector>;

const toggleOptions: ToggleOption[] = [
  { label: 'Dismissed', value: FeedbackQuestionGroup.dismissed },
  { label: 'Lost', value: FeedbackQuestionGroup.lost },
  { label: 'Support', value: FeedbackQuestionGroup.support },
];

const QuestionsList: React.FC<Props> = ({
  className,
  feedbacksActions: { loadQuestions },
  selectedId,
  onSelect,
}) => {
  const {
    isLoadingQuestions: isLoading,
    questions,
    questionParams,
  } = useAppSelector((state) => state.feedbacks);

  // TODO: Remove filter and update code for text type when BE is ready
  const filteredQuestions = useMemo(
    () => questions.filter(({ type }) => type !== FeedbackQuestionType.text),
    [questions],
  );

  const { page, totalPages } = questionParams;

  const [selectedOption, setSelectedOption] = useState(toggleOptions[0].value);

  const onChangeOption = ({ value }: ToggleOption) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    loadQuestions({ group: selectedOption as FeedbackQuestionGroup }, true);
  }, [selectedOption]);

  useEffect(() => {
    if (filteredQuestions[0]) onSelect(filteredQuestions[0].id);
  }, [filteredQuestions]);

  const onThreshold = useCallback(
    (cb: () => void) => {
      if (!isLoading && (!page || !totalPages || page < totalPages)) {
        loadQuestions({ per_page: defaultPerPage }, undefined, cb, cb);
      } else {
        cb();
      }
    },
    [loadQuestions, page, totalPages, isLoading],
  );

  const thresholdRef = useThreshold(1, onThreshold, [questions]);

  return (
    <div className={clsx(styles['response-list'], className)}>
      <ButtonToggle
        className={styles['button-toggle']}
        options={toggleOptions}
        selected={selectedOption}
        onChange={onChangeOption}
      />
      <div className={styles.list} ref={thresholdRef}>
        {filteredQuestions.map((question) => (
          <ResponseListItem
            key={question.id}
            type={question.type}
            title={question.question_text}
            selected={selectedId === question.id}
            onClick={() => onSelect(question.id)}
          />
        ))}
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default connector(QuestionsList) as React.FC<NativeProps>;
