enum FeedbackQuestionType {
  scale = 'scale',
  yesNo = 'yes-no',
  text = 'text',
}

enum FeedbackQuestionGroup {
  dismissed = 'dismissed_violations',
  lost = 'lost_violations',
  support = 'support',
}

enum FeedbackStatsSortType {
  name = 'name',
  result = 'result',
  created_at = 'created_at',
}

export { FeedbackQuestionType, FeedbackQuestionGroup, FeedbackStatsSortType };
