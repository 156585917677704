import { MutableRefObject, RefObject, useCallback, useState } from 'react'

interface RefState<T> {
  (ref: T): void
}

function useStateRef<T>(initialValue: T): RefState<T> & MutableRefObject<T>
function useStateRef<T>(initialValue: T | null): RefState<T> & RefObject<T>

function useStateRef<T extends unknown>(initialValue: T) {
  const [ref, setRef] = useState(initialValue)
  const set = useCallback(
    (newRef: T) => {
      if (newRef !== ref) {
        setRef(newRef)
      }
    },
    [setRef]
  )

  ;((set as unknown) as MutableRefObject<T>).current = ref

  return set
}

export default useStateRef
