enum ActionType {
  LOADING = '[infractionCodes]: loading',
  ERROR = '[infractionCodes]: error',
  RESET = '[infractionCodes]: reset',

  LOAD_BEGIN = '[infractionCodes]: get begin',
  LOAD_SUCCESS = '[infractionCodes]: get success',
  LOAD_ERROR = '[infractionCodes]: get error',
  LOAD_DETAILS_SUCCESS = '[infractionCodeDetails]: get details success',
}

type LoadingAction = ReduxAction<ActionType.LOADING, boolean>;

type ErrorAction = ReduxAction<ActionType.ERROR, ErrorType>;

type ResetAction = ReduxAction<ActionType.RESET>;

type LoadBeginAction = ReduxAction<ActionType.LOAD_BEGIN>;

type LoadSuccessAction = ReduxAction<
  ActionType.LOAD_SUCCESS,
  {
    infractionCodes: InfractionCode[];
    params: InfractionCodesParams;
  }
>;

type LoadDetailsSuccessAction = ReduxAction<
  ActionType.LOAD_DETAILS_SUCCESS,
  {
    infractionCodeDetails: InfractionCode;
  }
>;

type LoadErrorAction = ReduxAction<ActionType.LOAD_ERROR, ErrorType>;

export type Action =
  | LoadingAction
  | ErrorAction
  | ResetAction
  | LoadBeginAction
  | LoadSuccessAction
  | LoadDetailsSuccessAction
  | LoadErrorAction;

export default ActionType;
