import { defaultPerPage } from '@config';

import ActionType, { Action } from './types';

export interface State {
  error: ErrorType | null;
  infractionCodeDetails: InfractionCode | null;
  infractionCodes: InfractionCode[];
  isLoading: boolean;
  params: InfractionCodesParams;
}

const initialState: State = {
  isLoading: false,
  infractionCodes: [],
  infractionCodeDetails: null,
  params: {
    page: 0,
    per_page: defaultPerPage,
    totalPages: 0,
    search: '',
  },

  error: null,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }

    case ActionType.LOAD_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionType.LOAD_SUCCESS: {
      const { infractionCodes, params } = action.payload;

      return {
        ...state,
        infractionCodes,
        params: {
          ...state.params,
          ...params,
        },
        isLoading: false,
      };
    }

    case ActionType.LOAD_DETAILS_SUCCESS: {
      const { infractionCodeDetails } = action.payload;

      const quizzes = infractionCodeDetails.quizzes.sort((a, b) => b.id - a.id);

      return {
        ...state,
        infractionCodeDetails: {
          ...infractionCodeDetails,
          quizzes,
        },
        isLoading: false,
      };
    }

    case ActionType.ERROR:
    case ActionType.LOAD_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
