enum DefenseType {
  all = 'all',
  automatedWithQuizzes = 'automatedWithQuizzes',
  automatedWithoutQuizzes = 'automatedWithoutQuizzes',
  withoutDefenses = 'withoutDefenses',
  notAutomated = 'notAutomated',
}

export { DefenseType };

export default DefenseType;
