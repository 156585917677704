enum ReasonOfNewHearingType {
  firstRequest = 'First Request',
  moreThan75Days = 'First Request - More than 75 days',
  moreThanOneYear = 'Filed more than 1 year or not first request',
}

export const formReasonOfNewHearingTypes = [
  ReasonOfNewHearingType.firstRequest,
  ReasonOfNewHearingType.moreThan75Days,
  ReasonOfNewHearingType.moreThanOneYear,
];

export default ReasonOfNewHearingType;
