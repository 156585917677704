import { useCallback, useEffect, useState } from 'react'

import { defaultFontSize } from '@config'

const useRem = () => {
  const [fontSize, setFontSize] = useState(
    Number.parseFloat(
      window.getComputedStyle(document.documentElement).fontSize
    )
  )

  useEffect(() => {
    const update = () =>
      setFontSize(
        Number.parseInt(
          window.getComputedStyle(document.documentElement).fontSize
        )
      )

    window.addEventListener('resize', update)

    return () => window.removeEventListener('resize', update)
  }, [])

  const rem = useCallback((num: number) => (num * fontSize) / defaultFontSize, [
    fontSize,
  ])

  return rem
}

export default useRem
