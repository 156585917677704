import React, { useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import violationsActions from '@redux/violations/actions';
import { isId } from '@services/utils';

import View from './view';

const mapStateToProps = (state: ReduxState) => ({
  violation: state.violations.violation,
  activity: state.violations.activity,
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type NativeProps = RouteComponentProps<{ id: string }>;

type Props = NativeProps & ConnectedProps<typeof connector>;

const ViolationDetails: React.FC<Props> = ({
  violation,
  activity = [],
  violationsActions: { loadDetails, loadActivity, update },
  match: {
    params: { id },
  },
  history: { goBack },
}) => {
  useEffect(() => {
    if (!isId(id)) {
      goBack();
    } else {
      loadDetails(Number(id), undefined, goBack);
    }
  }, [id, goBack, loadDetails]);

  useEffect(() => {
    loadActivity(Number(id));
  }, [loadActivity, id]);

  const sendNote = useCallback(
    (notes: string[], cb?: () => void) => update(Number(id), { notes }, cb),
    [update],
  );

  return (
    <View
      violation={violation}
      activity={activity}
      goBack={goBack}
      sendNote={sendNote}
    />
  );
};

export default connector(ViolationDetails) as React.FC<NativeProps>;
