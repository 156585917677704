import { OptionType } from '@components/Select';
import {
  DefenseType,
  ViolationDefenseQuiz,
  ViolationDefenseQuizLabel,
} from '@typings/enums';

export const defenseQuizOptions: OptionType<ViolationDefenseQuiz>[] = [
  {
    label: ViolationDefenseQuizLabel.all,
    value: ViolationDefenseQuiz.all,
  },
  {
    label: ViolationDefenseQuizLabel.notAutomated,
    value: ViolationDefenseQuiz.notAutomated,
  },
  {
    label: ViolationDefenseQuizLabel.notAnswered,
    value: ViolationDefenseQuiz.notAnswered,
  },
  {
    label: ViolationDefenseQuizLabel.processed,
    value: ViolationDefenseQuiz.processed,
  },
];

export const typeOptions: OptionType<DefenseType>[] = [
  {
    label: 'All',
    value: DefenseType.all,
  },
  {
    label: 'Automated with quiz',
    value: DefenseType.automatedWithQuizzes,
  },
  {
    label: 'Automated without quiz',
    value: DefenseType.automatedWithoutQuizzes,
  },
  {
    label: 'No defense',
    value: DefenseType.withoutDefenses,
  },
  {
    label: 'Not automated',
    value: DefenseType.notAutomated,
  },
];
