import { FormikErrors } from 'formik';

import {
  FormData as UserRoleFormData,
  validateForm as validateUserRoleForm,
} from '@common/UserRoleForm';
import { ValueType, getSelectValue } from '@components/Select';
import caseTypeOptions from '@pages/ViolationHearingForm/Form/GeneralInfo/caseTypeOptions';
import { requiredValidate, validate } from '@services/validation';
import {
  CaseType,
  FormAuthorRoleType,
  WordStrBoolean,
  WordToBoolean,
} from '@typings/enums';

interface FormData extends UserRoleFormData {
  case_type: ValueType<CaseType> | undefined;
  does_name_appear_on_ticket: WordStrBoolean;
  first_name: string;
  last_name: string;
  summons_number: string;
}

export const formInitial = (
  violation: ViolationDetails | null,
  user: WebUser | null,
): FormData => ({
  case_type: violation
    ? caseTypeOptions.find(
        (option) => option.value === violation.infraction?.caseType,
      )
    : null,
  summons_number: violation?.ticketNumber || '',
  first_name: user?.firstName || '',
  last_name: user?.lastName ? `${user.lastName}, Attn: City5 Consulting` : '',
  does_name_appear_on_ticket: WordStrBoolean.false,
  who_you_are: [FormAuthorRoleType.representative],
  relationship: '',
  who_asked_you_to_make_request: `${violation?.user?.firstName} ${violation?.user?.lastName}`,
  persons_relationship_to_the_named_respondent: violation?.userRelation || '',
  are_you_authorized_to_represent_respondent: WordStrBoolean.true,
});

export const validateForm = ({
  case_type,
  summons_number,
  first_name,
  last_name,
  does_name_appear_on_ticket,
  ...values
}: FormData): FormikErrors<FormData> => ({
  case_type: validate(getSelectValue(case_type), requiredValidate),
  summons_number: validate(summons_number, requiredValidate),
  first_name: validate(first_name, requiredValidate),
  last_name: validate(last_name, requiredValidate),
  does_name_appear_on_ticket: validate(
    does_name_appear_on_ticket,
    requiredValidate,
  ),
  ...validateUserRoleForm(WordToBoolean[does_name_appear_on_ticket])(values),
});

export const formKeys: (keyof FormData)[] = [
  'case_type',
  'summons_number',
  'first_name',
  'last_name',
  'does_name_appear_on_ticket',
  'who_you_are',
  'who_asked_you_to_make_request',
  'persons_relationship_to_the_named_respondent',
  'are_you_authorized_to_represent_respondent',
];

export default FormData;
