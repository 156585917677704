import clsx from 'clsx';
import React from 'react';
import { ReactSVG } from 'react-svg';

import CheckboxIcon from '@assets/checkbox.svg';
import FlagIcon from '@assets/flag.svg';
import { FeedbackQuestionType } from '@typings/enums';

import styles from './styles.module.scss';

type Props = {
  selected?: boolean;
  title: string;
  type: FeedbackQuestionType;
  onClick(): void;
};

const ResponseListItem: React.FC<Props> = ({
  selected,
  title,
  type,
  onClick,
}) => {
  return (
    <button
      type="button"
      className={clsx(
        styles['list-item'],
        styles[type],
        selected && styles.selected,
      )}
      onClick={onClick}
    >
      <div className={styles['icon-box']}>
        <ReactSVG
          src={type === FeedbackQuestionType.scale ? FlagIcon : CheckboxIcon}
          className={clsx('svg', styles.icon)}
        />
      </div>
      <div>
        <p>{title}</p>
        <div className={styles.type}>
          {type === FeedbackQuestionType.scale ? 'Opinion Scale' : 'Yes/No'}
        </div>
      </div>
    </button>
  );
};

export default React.memo(ResponseListItem);
