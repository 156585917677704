import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useMemo } from 'react';

import BigCheckbox from '@components/BigCheckbox';
import Input from '@components/Input';
import { FormAuthorRoleType, WordStrBoolean } from '@typings/enums';

import authorRoleRadios from './authorRoleRadios';
import UserRoleFormData from './form';

interface Props {
  are_you_authorized_to_represent_respondent?: WordStrBoolean;
  bigRadioClassName?: string;
  bigRadiosClassName?: string;
  errors?: FormikErrors<UserRoleFormData>;
  hide?: boolean;
  inputWrapperClassName?: string;
  inputsClassName?: string;
  persons_relationship_to_the_named_respondent?: string;
  radioLabelClassName?: string;
  relationship?: string;
  touched?: FormikTouched<UserRoleFormData>;
  who_asked_you_to_make_request?: string;
  who_you_are?: FormAuthorRoleType[] | FormAuthorRoleType;
  withMarginClassName?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

const UserRoleForm = ({
  who_you_are,
  who_asked_you_to_make_request,
  persons_relationship_to_the_named_respondent,
  are_you_authorized_to_represent_respondent,
  onChange,
  relationship,

  errors,
  touched,

  hide = false,

  radioLabelClassName,
  inputWrapperClassName,
  withMarginClassName,
  inputsClassName,
  bigRadiosClassName,
  bigRadioClassName,
}: Props) => {
  const isSingleValue = typeof who_you_are === 'string';

  const checked = (value: FormAuthorRoleType) =>
    isSingleValue ? who_you_are === value : who_you_are?.includes(value);

  const defaultChecked = (value: FormAuthorRoleType) =>
    isSingleValue ? who_you_are === value : who_you_are?.[0] === value;
  const type = isSingleValue ? 'radio' : 'checkbox';

  const authorRoleRadiosNodes = useMemo(
    () =>
      authorRoleRadios.map(({ value, label }) => (
        <Input
          key={value}
          type={type}
          name="who_you_are"
          value={value}
          checked={checked(value)}
          onChange={onChange}
          defaultChecked={defaultChecked(value)}
          label={label}
          labelClassName={radioLabelClassName}
          error={touched?.who_you_are && errors?.who_you_are}
        />
      )),
    [onChange, who_you_are, touched, errors],
  );

  if (hide) {
    return null;
  }

  return (
    <>
      <div className={clsx(inputWrapperClassName, withMarginClassName)}>
        <label className="input-label">
          {'Check the box that best describes who you are'}
          <span className="asterisk" />
        </label>
        {authorRoleRadiosNodes}
      </div>

      {who_you_are?.includes(FormAuthorRoleType.other) &&
        typeof who_you_are !== 'string' && (
          <Input
            type="text"
            name="relationship"
            value={relationship}
            onChange={onChange}
            label="Describe your Relationship"
            placeholder="Relationship"
            labelAsterisk
            maxLength={40}
            required
            error={
              touched?.who_asked_you_to_make_request &&
              errors?.who_asked_you_to_make_request
            }
          />
        )}
      <Input
        type="text"
        name="who_asked_you_to_make_request"
        value={who_asked_you_to_make_request}
        onChange={onChange}
        label="What is the name of the person who asked you to make this request?"
        labelAsterisk
        maxLength={40}
        error={
          touched?.who_asked_you_to_make_request &&
          errors?.who_asked_you_to_make_request
        }
      />
      <Input
        type="text"
        name="persons_relationship_to_the_named_respondent"
        value={persons_relationship_to_the_named_respondent}
        onChange={onChange}
        label="What is that person's relationship to the named Respondent?"
        labelAsterisk
        maxLength={40}
        error={
          touched?.persons_relationship_to_the_named_respondent &&
          errors?.persons_relationship_to_the_named_respondent
        }
      />

      <div className={clsx(inputWrapperClassName, withMarginClassName)}>
        <label className="input-label">
          {'Are you authorized to represent the Respondent?'}
          <span className="asterisk" />
        </label>
        <div className={clsx(inputsClassName, bigRadiosClassName)}>
          <BigCheckbox
            type="radio"
            name="are_you_authorized_to_represent_respondent"
            value={WordStrBoolean.true}
            checked={
              are_you_authorized_to_represent_respondent === WordStrBoolean.true
            }
            onChange={onChange}
            containerClassName={bigRadioClassName}
          >
            Yes
          </BigCheckbox>
          <BigCheckbox
            type="radio"
            name="are_you_authorized_to_represent_respondent"
            value={WordStrBoolean.false}
            checked={
              are_you_authorized_to_represent_respondent ===
              WordStrBoolean.false
            }
            onChange={onChange}
            containerClassName={bigRadioClassName}
          >
            No
          </BigCheckbox>
        </div>
      </div>
    </>
  );
};

export * from './form';

export type FormData = UserRoleFormData;

export default UserRoleForm;
