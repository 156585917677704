import apiConfigs from './api'
import appConfigs from './app'
import paymentsConfigs from './payments'
import usersConfigs from './users'
import validationConfigs from './validation'
import violationsConfigs from './violations'

export const isProduction = process.env.NODE_ENV === 'production'

export const defaultFontSize = 16

export * from './api'
export * from './app'
export * from './validation'
export * from './violations'
export * from './payments'
export * from './users'

export default {
  ...apiConfigs,
  ...appConfigs,
  ...validationConfigs,
  ...violationsConfigs,
  ...paymentsConfigs,
  ...usersConfigs,
  isProduction,
  defaultFontSize,
}
