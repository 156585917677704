import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useCallback, useMemo } from 'react';

import InfoTooltip from '@common/InfoTooltip';
import CalendarInput from '@components/CalendarInput';
import Input from '@components/Input';
import Textarea from '@components/Textarea';

import sharedStyles from '../styles.module.scss';

import checkboxes from './checkboxes';
import FormData from './form';
import radios, { ReasonForGranted } from './radios';
import styles from './styles.module.scss';

interface Props {
  errors: FormikErrors<FormData>;
  form: FormData;
  touched: FormikTouched<FormData>;
  onChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void;
  setFieldValue<F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
  ): void;
}

const ReasonableExcuse: React.FC<Props> = ({
  form,
  touched,
  errors,
  setFieldValue,
  onChange,
}) => {
  const onDateChange = useCallback(
    (_dates: Date[], _dateStr: string, _self: unknown, _data: unknown) => {
      setFieldValue('date_respondent_learn_about_summons', _dateStr);
    },
    [setFieldValue],
  );

  const radiosNodes = useMemo(
    () =>
      radios.map(({ value, label }) => (
        <Input
          key={value}
          name="reason_for_which_new_hearing_should_be_granted"
          type="radio"
          value={value}
          checked={
            form.reason_for_which_new_hearing_should_be_granted === value
          }
          onChange={onChange}
          label={label}
          labelClassName={sharedStyles['radio-label']}
        />
      )),
    [onChange, form.reason_for_which_new_hearing_should_be_granted],
  );

  const checkboxesNodes = useMemo(
    () =>
      checkboxes.map(({ label, tooltip, name }, index) => (
        <Input
          key={`${label}${name}`}
          name={`reasonable_excuse[${index}]`}
          type="checkbox"
          checked={form.reasonable_excuse[index] as boolean}
          onChange={onChange}
          label={
            tooltip ? (
              <>
                {label}
                <InfoTooltip>{tooltip}</InfoTooltip>
              </>
            ) : (
              label
            )
          }
          labelClassName={sharedStyles['checkbox-label']}
        />
      )),
    [onChange, form.reasonable_excuse],
  );

  const moreThan60DaysNode = useMemo(
    () => (
      <>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            Reasonable Excuse
            <span className="asterisk" />
          </h2>
        </div>
        <div
          className={clsx(
            sharedStyles.inputs,
            sharedStyles.checkboxes,
            styles.checkboxes,
          )}
        >
          {checkboxesNodes}
          {touched.reasonable_excuse && errors.reasonable_excuse ? (
            <p className="error-text">{errors.reasonable_excuse}</p>
          ) : (
            <p className="error-placeholder" />
          )}
        </div>
        <div className={clsx(sharedStyles.inputs)}>
          {form.reasonable_excuse[6] ? (
            <Input
              type="text"
              name="respondent_connection_to_the_property"
              value={form.respondent_connection_to_the_property}
              onChange={onChange}
              label="Respondent's connection to the property and when that connection ended"
              labelAsterisk
              required
              error={
                touched.respondent_connection_to_the_property &&
                errors.respondent_connection_to_the_property
              }
            />
          ) : null}
          {form.reasonable_excuse[8] ? (
            <Textarea
              name="reasonable_excuse_explain"
              value={form.reasonable_excuse_explain}
              onChange={onChange}
              numberOfLines={3}
              label="Explain"
              labelAsterisk
              required
              error={
                touched.reasonable_excuse_explain &&
                errors.reasonable_excuse_explain
              }
            />
          ) : null}
        </div>
      </>
    ),
    [
      checkboxesNodes,
      touched.reasonable_excuse,
      errors.reasonable_excuse,
      form.respondent_connection_to_the_property,
      touched.respondent_connection_to_the_property,
      errors.respondent_connection_to_the_property,
      form.reasonable_excuse_explain,
      touched.reasonable_excuse_explain,
      errors.reasonable_excuse_explain,
    ],
  );

  const moreThan1YearNode = useMemo(
    () => (
      <div className={sharedStyles.inputs}>
        <Textarea
          name="explain_circumstances"
          value={form.explain_circumstances}
          onChange={onChange}
          numberOfLines={3}
          label={
            <>
              Explain what those circumstances are:
              <span className="asterisk" />
              <InfoTooltip>
                {'For summonses charging violations of any laws or ' +
                  'regulations that the Taxi and Limousine Commission ' +
                  '(TLC) has the duty or authority to enforce, this request ' +
                  'must be filed within 2 years from the date of the default ' +
                  'decision. The request will only be granted in exceptional circumstances.'}
              </InfoTooltip>
            </>
          }
          required
          error={touched.explain_circumstances && errors.explain_circumstances}
        />
      </div>
    ),
    [
      onChange,
      form.explain_circumstances,
      touched.explain_circumstances,
      errors.explain_circumstances,
    ],
  );

  const reason = useMemo(() => {
    switch (form.reason_for_which_new_hearing_should_be_granted) {
      case ReasonForGranted.moreThan75Days: {
        return moreThan60DaysNode;
      }

      case ReasonForGranted.moreThanOneYear: {
        return moreThan1YearNode;
      }

      default: {
        return null;
      }
    }
  }, [
    form.reason_for_which_new_hearing_should_be_granted,
    moreThan60DaysNode,
    moreThan1YearNode,
  ]);

  return (
    <>
      <div className={sharedStyles.section}>
        <div className={sharedStyles['section-head']}>
          <label
            className={clsx(
              sharedStyles['section-title'],
              sharedStyles['section-title-small'],
            )}
          >
            On what date did Respondent first learn about this summons?
            <span className="asterisk" />
          </label>
        </div>
        <div
          className={clsx(
            sharedStyles.inputs,
            sharedStyles['two-columns'],
            styles['date-inputs'],
          )}
        >
          <CalendarInput
            onChange={onDateChange}
            name="date_respondent_learn_about_summons"
            value={form.date_respondent_learn_about_summons}
            label="Select date"
            options={{ dateFormat: 'Y-m-d' }}
            error={
              touched.date_respondent_learn_about_summons &&
              errors.date_respondent_learn_about_summons
            }
          />
        </div>
        <div className={clsx(sharedStyles.inputs)}>
          <Textarea
            label="How did the Respondent learn about the summons?"
            labelClassName={sharedStyles['bold-label']}
            labelAsterisk
            numberOfLines={3}
            name="how_did_respondent_learn_about_summons"
            value={form.how_did_respondent_learn_about_summons}
            onChange={onChange}
            error={
              touched.how_did_respondent_learn_about_summons &&
              errors.how_did_respondent_learn_about_summons
            }
          />
        </div>
      </div>
      <div className={sharedStyles.section}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            Reason for which a new hearing should be granted
            <span className="asterisk" />
          </h2>
        </div>
        <div
          className={clsx(
            sharedStyles.inputs,
            sharedStyles.radios,
            styles.radios,
          )}
        >
          {radiosNodes}
        </div>
      </div>
      <div className={sharedStyles.section}>{reason}</div>
    </>
  );
};

export default ReasonableExcuse;
