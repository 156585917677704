import clsx from 'clsx';
import React from 'react';
import { ReactSVG } from 'react-svg';

import SearchIcon from '@assets/search.svg';
import Header from '@common/Header';
import PagingFooter from '@common/PagingFooter';
import Input from '@components/Input';
import useBoolean from '@hooks/useBoolean';

import Filters, { BuildingsFilters } from './Filters';
import Accordion from './accordion';
import styles from './styles.module.scss';

interface Props {
  data: BuildingsApiLoadData | null;
  isLoading: boolean;
  onChangeFilters(newFilters: BuildingsFilters): void;
  onChangeParams(params: PagingParams): void;
  onSearch(event: React.ChangeEvent<HTMLInputElement>): void;
}

const BuildingsView: React.FC<Props> = ({
  data,
  isLoading,
  onChangeParams,
  onSearch,
  onChangeFilters,
}) => {
  const { value: isOpenFilter, setTrue, setFalse } = useBoolean();

  return (
    <div className={clsx('page', styles.buildings)}>
      <Header />

      <div className={clsx('page-content', styles['page-content'])}>
        <div className={clsx('heading', styles.heading)}>
          <h1>Buildings</h1>
          <button type="button" className="primary-button" onClick={setTrue}>
            Filter
          </button>
        </div>
        <div className={styles['search-wrapper']}>
          <ReactSVG
            src={SearchIcon}
            className={clsx('svg', styles['search-icon'])}
          />
          <Input
            type="text"
            name="search"
            onChange={onSearch}
            inputContainerClassName={styles.search}
            placeholder="search"
            containerClassName={styles['search-container']}
            disableError
          />
        </div>

        {data && data.records.length > 0 && (
          <>
            <Accordion buildings={data.records} />
            <PagingFooter
              availablePerPage={[20, 40, 60]}
              isLoading={isLoading}
              page={data.meta.currentPage || 0}
              perPage={data.meta.perPage || 0}
              totalPages={data.meta.lastPage || 0}
              onChange={onChangeParams}
            />
          </>
        )}
      </div>
      <Filters
        isOpen={isOpenFilter}
        close={setFalse}
        onChangeFilters={onChangeFilters}
        isLoading={isLoading}
      />
    </div>
  );
};

export default BuildingsView;
