import clsx from 'clsx';
import React, { useState } from 'react';

import Header from '@common/Header';

import ResponsesList from './QuestionsList';
import ResponseDetails from './ResponseDetails';
import styles from './styles.module.scss';

const ResponsesView: React.FC = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);

  return (
    <div className={clsx('page', styles.page, styles.responses)}>
      <Header />

      <div className={clsx('page-content', styles['page-content'])}>
        <ResponsesList
          selectedId={selectedId ?? undefined}
          onSelect={setSelectedId}
        />
        <ResponseDetails questionId={selectedId ?? undefined} />
      </div>
    </div>
  );
};

export default ResponsesView;
