import paths from '@routes/paths';

export const hash = (h = '') => `#${h}`;

export const home = () => paths.home;

export const violations = () => paths.violations;
export const hiredViolations = () => paths.hiredViolations;
export const closedViolations = () => paths.closedViolations;
export const attendedViolations = () => paths.attendedViolations;
export const unhiredViolations = () => paths.unhiredViolations;
export const violationDetails = (id: string | number) =>
  paths.violationDetails.replace(':id', String(id));

export const violationRequestReopen = (id: string | number) =>
  paths.violationRequestReopen.replace(':id', String(id));

export const violationHearingForm = (id: string | number) =>
  paths.violationHearingForm.replace(':id', String(id));

export const users = () => paths.users;
export const userDetails = (id: string | number) =>
  paths.userDetails.replace(':id', String(id));
export const analysis = () => paths.analysis;
export const payments = () => paths.payments;
export const defences = () => paths.defences;
export const defenceQuizzes = (id: string | number) =>
  paths.defenceQuizzes.replace(':id', String(id));

export const login = () => paths.login;
export const noAccess = () => paths.noAccess;
export const forgotPassword = () => paths.forgotPassword;
export const resetPassword = () => paths.resetPassword;

export const buildings = () => paths.buildings;
export const responses = () => paths.responses;

const links = {
  hash,

  home,

  violations,
  hiredViolations,
  closedViolations,
  attendedViolations,
  unhiredViolations,
  violationDetails,
  violationRequestReopen,
  violationHearingForm,

  users,
  userDetails,
  analysis,
  payments,
  defences,
  defenceQuizzes,
  login,
  noAccess,
  forgotPassword,
  resetPassword,

  buildings,
  responses,
};

export default links;
