import { useCallback, useRef } from 'react';

const DEFAULT_DEBOUNCE_WAIT_TIME = 300;

const useDebounce = <T extends unknown[]>(
  callback: (...args: T) => void,
  delay = DEFAULT_DEBOUNCE_WAIT_TIME,
) => {
  const timer = useRef<ReturnType<typeof setTimeout>>();

  return useCallback(
    (...args: T) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );
};

export default useDebounce;
