import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

export interface UseBooleanOutput {
  setValue: Dispatch<SetStateAction<boolean>>;
  value: boolean;
  setFalse(): void;
  setTrue(): void;
  toggle(): void;
}

function useBoolean(defaultValue?: boolean): UseBooleanOutput {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((x) => !x), []);

  return useMemo(() => ({ value, setValue, setTrue, setFalse, toggle }), [
    setFalse,
    setTrue,
    toggle,
    value,
  ]);
}

export default useBoolean;
