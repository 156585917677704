import React, { memo, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import useLoadBuildings from '@hooks/buildings';

import View from './view';

const HiredViolations: React.FC<RouteComponentProps> = () => {
  const {
    isLoading,
    data,
    onChangeParams,
    handleChangeSearch,
    onChangeFilters,
  } = useLoadBuildings();

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      handleChangeSearch(value);
    },
    [handleChangeSearch],
  );

  return (
    <View
      data={data}
      isLoading={isLoading}
      onChangeParams={onChangeParams}
      onSearch={handleChangeInput}
      onChangeFilters={onChangeFilters}
    />
  );
};

export default memo(HiredViolations);
