import { LinkProps } from 'react-router-dom';

import links from '@routes/links';

const defaultLinks: LinkProps[] = [
  {
    to: links.violations(),
    children: 'Violations',
  },
  {
    to: links.users(),
    children: 'Users',
  },
  {
    to: links.payments(),
    children: 'Payments',
  },
  {
    to: links.defences(),
    children: 'Defenses',
  },
  {
    to: links.buildings(),
    children: 'Buildings',
  },
  {
    to: links.responses(),
    children: 'Responses',
  },
];

export default defaultLinks;
