import { defaultPerPage } from '@config';
import { pick } from '@services/utils';
import { mergeViolations } from '@services/violation';
import { violationDetailsKeys } from '@typings/enums';

import ActionType, { Action } from './types';

export interface State {
  activity: ViolationActivity[];
  caseStatusKeys: CaseStatusTypeKey[];
  caseStatusKeysLoadedAt: number;
  error: ErrorType | null;
  hearingResults: HearingStatusType[];
  hearingResultsLoadedAt: number;
  infCodes: string[];
  infCodesParams: ViolationInfractionCodesParams;
  isLoading: boolean;
  isViolationsLoading: boolean;
  params: ViolationsParams;
  violation: ViolationDetails | null;
  violations: Violations;
}

const initialState: State = {
  isLoading: false,
  isViolationsLoading: false,
  violations: [],
  params: {
    page: 0,
    per_page: defaultPerPage,
    totalPages: 0,
  },

  infCodes: [],
  infCodesParams: {},

  hearingResults: [],
  hearingResultsLoadedAt: 0,
  caseStatusKeys: [],
  caseStatusKeysLoadedAt: 0,

  violation: null,
  activity: [],

  error: null,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ActionType.RESET: {
      return {
        ...initialState,
      };
    }

    case ActionType.LOAD_INF_CODES_BEGIN:
    case ActionType.LOAD_HEARING_RESULTS_BEGIN:
    case ActionType.LOAD_CASE_STATUSES_BEGIN:
    case ActionType.LOAD_ACTIVITY_BEGIN:
    case ActionType.UPDATE_BEGIN:
    case ActionType.SEND_REOPEN_REQUEST_BEGIN:
    case ActionType.SEND_ONLINE_HEARING_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionType.LOAD_BEGIN: {
      return {
        ...state,
        isViolationsLoading: true,
      };
    }

    case ActionType.LOAD_DETAILS_BEGIN: {
      return {
        ...state,
        isLoading: true,
        violation: null,
      };
    }

    case ActionType.LOAD_SUCCESS: {
      const { violations: newViolations, params } = action.payload;
      const violations = params.reload
        ? newViolations
        : mergeViolations(state.violations, newViolations);

      return {
        ...state,
        violations,
        params: {
          ...state.params,
          ...params,
        },
        isViolationsLoading: false,
      };
    }

    case ActionType.LOAD_INF_CODES_SUCCESS: {
      return {
        ...state,
        infCodes: action.payload.infCodes,
        infCodesParams: {
          ...state.params,
          ...action.payload.params,
        },
        isLoading: false,
      };
    }

    case ActionType.LOAD_HEARING_RESULTS_SUCCESS: {
      return {
        ...state,
        hearingResults: action.payload,
        hearingResultsLoadedAt: new Date().getTime(),
        isLoading: false,
      };
    }

    case ActionType.LOAD_CASE_STATUSES_SUCCESS: {
      return {
        ...state,
        caseStatusKeys: action.payload,
        caseStatusKeysLoadedAt: new Date().getTime(),
        isLoading: false,
      };
    }

    case ActionType.LOAD_DETAILS_SUCCESS: {
      return {
        ...state,
        violation: action.payload,
        isLoading: false,
      };
    }

    case ActionType.UPDATE_SUCCESS: {
      return {
        ...state,
        violation: {
          ...state.violation,
          ...pick(action.payload, violationDetailsKeys),
        },
        isLoading: false,
      };
    }

    case ActionType.LOAD_ACTIVITY_SUCCESS: {
      return {
        ...state,
        activity: action.payload,
        isLoading: false,
      };
    }

    case ActionType.SEND_ONLINE_HEARING_SUCCESS:
    case ActionType.SEND_REOPEN_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionType.ERROR:
    case ActionType.LOAD_ERROR:
    case ActionType.LOAD_DETAILS_ERROR:
    case ActionType.LOAD_INF_CODES_ERROR:
    case ActionType.LOAD_HEARING_RESULTS_ERROR:
    case ActionType.LOAD_CASE_STATUSES_ERROR:
    case ActionType.LOAD_ACTIVITY_ERROR:
    case ActionType.UPDATE_ERROR:
    case ActionType.SEND_REOPEN_REQUEST_ERROR:
    case ActionType.SEND_ONLINE_HEARING_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isViolationsLoading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
